import {
  MouseEvent,
  SyntheticEvent,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { AddRounded, Delete, DownloadRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogContentText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  IconButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SimpleTreeView } from '@mui/x-tree-view';
import { store } from 'contexts/PageContextStore';
import { convertToPaper, convertToPapers } from 'utils/document';
import classNames from 'classnames';
import Dialog from 'components/Dialog';
import ExportPaperSuccessfully from './components/ExportPaper/Success';
import CustomTreeItem from './components/CustomTreeItem';
import { useNavigate, useParams } from 'react-router';
import { getUserInfo } from 'services/user';
import { theme } from 'theme';
import {
  deleteDocument,
  exportDocument,
  getDocument,
  getDocuments
} from 'services/documents';
import ExportPaper from './components/ExportPaper';

const useStyles = makeStyles((theme) => ({
  papersWapper: {
    flex: '1 1 auto',
    height: '100%',
    marginBottom: 8,
    paddingBottom: 50,
    marginRight: -18,
    overflow: 'hidden',
    boxShadow: 'none'
  },
  title: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginLeft: 8
  },
  treeViewBox: {
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#BDBDBD #f8f8f8',
    scrollbarGutter: 'stable'
  },
  treeView: {
    marginRight: 7
  },
  inlineEditorMenu: {
    minWidth: 250,
    padding: '4px 0',
    borderRadius: 8,
    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.08)'
  },
  inlineEditorMenuItem: {
    padding: '8px 16px',
    '& svg': {
      marginRight: 16,
      fill: theme.palette.grey[500]
    },
    '&.disabled': {
      cursor: 'default',
      color: theme.palette.grey[400],
      '& svg': {
        fill: theme.palette.grey[400]
      }
    }
  },
  toolTip: {
    maxWidth: 500,
    padding: '5px 8px',
    textAlign: 'center',
    backgroundColor: '#616161',
    color: theme.palette.common.white,
    lineHeight: '137%',
    fontSize: 12,
    borderRadius: 8
  }
}));

export interface PapersRef {
  reset: (_options?: any) => void;
}

interface PapersProps {
  ref: PapersRef;
}

const Papers = forwardRef<PapersRef, PapersProps>((_props, ref) => {
  const classes = useStyles();
  const nav = useNavigate();
  const { id: viewedPaperId = '' } = useParams();
  const {
    storeState: {
      papers = [],
      userInfo: { plan = {}, is_internal = false } = {}
    } = {},
    setPageData
  } = useContext(store);

  const [isExportSuccessfullyDialogOpen, setIsExportSuccessfullyDialogOpen] =
    useState(false);
  const [isExportTypeSelectionDialogOpen, setIsExportTypeSelectionDialogOpen] =
    useState(false);
  const [activeItemId, setActiveItemId] = useState<string>('');
  const [activedSectionId, setActivedSectionId] = useState<string>('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isPapersLoading, setIsPapersLoading] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expandedItems, setExpandedItems] = useState<string[]>([activeItemId]);
  const open = Boolean(anchorEl);
  const [itemTarget, setItemTarget] = useState<any>({});
  const [itemTargetPosition, setItemTargetPostion] = useState<any>({});
  const [exportTypeValue, setExportTypeValue] = useState('docx');
  const { id: itemId } = itemTarget;
  const abortControllerRef = useRef<AbortController | null>(null);

  const handleResetPage = (options = {}) => {
    abortControllerRef.current?.abort();
    setActiveItemId('');
    setPageData({
      activedEditorPanelStep: 0,
      paper: {},
      textEvidences: [],
      bibliography: [],
      bibliographyList: [],
      isPaperLoading: false,
      isCreatePaperViewGenerating: false,
      ...options
    });
    nav('/templates');
  };

  useImperativeHandle(ref, () => ({
    reset(options?: any) {
      handleResetPage(options);
    }
  }));

  const handleUpdatePaper = async (papers, id, abortController?: any) => {
    setPageData({
      bibliography: [],
      bibliographyList: [],
      textEvidences: [],
      sectionStatusUpdated: true
    });
    const paper = await getDocument(id, abortController);
    if (!paper) return;
    const { bibliographies = [], stage = '' } = paper;
    const isSectionGenerated = stage === 'section_generated';
    const isSectionGenerating = stage === 'section_generating';
    const isPlanGenerating = stage === 'plan_generating';
    const isPlanGenerated = stage === 'plan_generated';
    const isOnPlanStage = isSectionGenerated || isPlanGenerating;
    const isOnCreatePaperViewStage = isOnPlanStage || isPlanGenerated;
    const isGeneratingPaper = isSectionGenerating || isOnCreatePaperViewStage;
    //TODO(code legacy):  need to clearify the page steps and view steps for the editor panel and map these ugly numbers
    const activedEditorPanelStep = isGeneratingPaper ? 1 : 2;
    const activedEditorViewStep = isGeneratingPaper ? 0 : 1;
    const createPaperViewStep = isOnPlanStage ? 2 : isPlanGenerated ? 3 : 0;

    setPageData({
      ...convertToPaper(paper, papers, bibliographies),
      bibliographyList: bibliographies,
      activedEditorPanelStep,
      activedEditorViewStep,
      ...(isOnCreatePaperViewStage ? { createPaperViewStep } : {}),
      isCreatePaperViewGenerating: isPlanGenerating,
      referenceLinkTarget: {},
      isPaperLoading: false,
      allSectionsPolished: false
    });
  };

  const handleItemClick = async (_event: SyntheticEvent | null, itemId) => {
    //set expanded items
    const rootItemIDs = papers.map((item) => item.id);
    const isRootItem = rootItemIDs.includes(itemId);
    const _expandedItems: string[] = [...expandedItems];

    if (expandedItems.includes(itemId)) {
      !isRootItem && _expandedItems.splice(expandedItems.indexOf(itemId), 1);
    } else {
      if (isRootItem) {
        _expandedItems.splice(0, _expandedItems.length);
      }
      _expandedItems.push(itemId);
    }
    setExpandedItems(_expandedItems);

    if (!isRootItem) return;
    setActivedSectionId(itemId);
    setActiveItemId(itemId);
  };

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>, target) => {
    event.stopPropagation();
    const MARGIN_TOP = 15;
    setItemTargetPostion({
      left: event.clientX,
      top: event.clientY - MARGIN_TOP
    });
    setAnchorEl(event.currentTarget);
    setItemTarget(target);
  };

  const handleExportPaper = async () => {
    setIsExportTypeSelectionDialogOpen(false);
    const response = await exportDocument({
      document_id: itemId,
      format: exportTypeValue
    });
    if (!response) return;
    const blob = new Blob([response], {
      type:
        exportTypeValue === 'pdf'
          ? 'application/pdf'
          : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    });
    const fileURL = window.URL.createObjectURL(blob);
    const fileName = papers.find((item) => item.id === itemId)?.title;
    const alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = `${fileName}.${exportTypeValue}`;
    alink.click();
    setIsExportSuccessfullyDialogOpen(true);
  };

  const menuItems = [
    {
      icon: <DownloadRounded />,
      text: 'Export',
      tooltipTitle: (
        <label>
          Export is not available because the <br />
          document contains unapplied changes.
        </label>
      ),

      isHidden: itemTarget.status !== 'completed',
      disabled: itemTarget.stage === 'polishing',
      handleClick: () => {
        setIsExportTypeSelectionDialogOpen(true);
        setExportTypeValue('docx');
      }
    },
    {
      icon: <Delete />,
      text: 'Delete',
      handleClick: async () => {
        setIsDeleteDialogOpen(true);
      }
    }
  ];

  useEffect(() => {
    if (!activeItemId || !papers.length) return;
    if (!papers.find((item) => item.id === activeItemId)) {
      // if the active item is not in the list, navigate to 404 page
      nav('/main/404');
      return;
    }
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const fetchData = async () => {
      nav(`/documents/${activeItemId}`);
      setPageData({ isPaperLoading: true });
      await handleUpdatePaper(papers, activeItemId, abortControllerRef.current);
    };
    fetchData();
    return () => {
      abortControllerRef.current?.abort();
    };
  }, [activeItemId, papers.length]);

  useEffect(() => {
    if (!papers.length || !viewedPaperId || viewedPaperId === activeItemId)
      return;
    handleItemClick(null, viewedPaperId);
  }, [viewedPaperId, papers.length]);

  useEffect(() => {
    const fetchData = async () => {
      const response = (await getDocuments()) || [];
      setIsPapersLoading(false);
      if (!response?.results?.length) return;
      setPageData({
        papers: convertToPapers(response.results)
      });
    };
    fetchData();
  }, []);

  const isLimited =
    !is_internal && plan?.type !== 'professional' && !plan?.remaining_documents;

  return (
    <>
      <Box className={classes.papersWapper}>
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ mb: 2, pr: '18px' }}
        >
          <Typography fontWeight={600} fontSize={18}>
            My documents
          </Typography>
          <Tooltip
            title={isLimited ? 'Your document limit has been reached.' : ''}
            classes={{ tooltip: classes.toolTip }}
          >
            <IconButton
              sx={{
                borderRadius: '10px',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                ml: 'auto',
                p: '6px 16px',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                  borderRadius: `10px !important`
                }
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleResetPage({
                  activedEditorPanelStep: 1,
                  createPaperViewStep: 0,
                  createPaperViewData: {}
                });
              }}
            >
              <AddRounded fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
        {!isPapersLoading && (
          <>
            {papers.length === 0 ? (
              <Box
                sx={{
                  display: 'inline-flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  textAlign: 'center',
                  paddingRight: '18px'
                }}
                fontSize={14}
              >
                <div style={{ fontSize: 24, height: 36, margin: '0 0 8px 0' }}>
                  📂
                </div>
                <Typography
                  color={theme.palette.text.secondary}
                  sx={{
                    maxWidth: 280
                  }}
                  fontSize={14}
                >
                  You have not created any documents yet. Get started by
                  creating your first document.
                </Typography>
              </Box>
            ) : (
              <Box className={classes.treeViewBox}>
                <SimpleTreeView
                  onSelectedItemsChange={handleItemClick}
                  classes={{ root: classes.treeView }}
                  expandedItems={expandedItems}
                >
                  {papers.map((paper) => {
                    const {
                      id,
                      status,
                      stage = '',
                      generation_flow = 'linear'
                    } = paper;
                    return (
                      <CustomTreeItem
                        key={id}
                        activeItemId={activeItemId}
                        handleOpenMenu={(e) => handleOpenMenu(e, paper)}
                        isPaperCompleted={
                          ['completed', 'failed'].includes(status) ||
                          [
                            'section_generated',
                            'plan_generating',
                            'plan_generated'
                          ].includes(stage)
                        }
                        {...paper}
                        status={status}
                        stage={stage}
                        isSinglePage={generation_flow === 'single_page'}
                        activedSectionId={activedSectionId}
                        setActivedSectionId={setActivedSectionId}
                      />
                    );
                  })}
                </SimpleTreeView>
              </Box>
            )}
          </>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        sx={{ ...itemTargetPosition }}
        classes={{ paper: classes.inlineEditorMenu }}
      >
        {menuItems.map((item, index) => {
          const {
            text,
            handleClick = () => {},
            icon,
            tooltipTitle = '',
            isHidden = false,
            disabled = false
          } = item;
          if (isHidden) return null;
          return (
            <div key={index}>
              <Tooltip
                title={disabled ? tooltipTitle : ''}
                placement="top"
                classes={{ tooltip: classes.toolTip }}
              >
                <span>
                  <MenuItem
                    onClick={() => {
                      handleClick();
                      setAnchorEl(null);
                    }}
                    classes={{
                      root: classNames(classes.inlineEditorMenuItem)
                    }}
                    disabled={disabled}
                  >
                    {icon}
                    <span>{text}</span>
                  </MenuItem>
                </span>
              </Tooltip>
            </div>
          );
        })}
      </Menu>
      <Dialog
        open={isDeleteDialogOpen}
        title="Delete document"
        Content={
          <>
            <h4 style={{ margin: '12px 0 10px' }}>
              Are you sure you want to delete this document?
            </h4>
            <DialogContentText>This action cannot be undone.</DialogContentText>
          </>
        }
        handleClose={() => setIsDeleteDialogOpen(false)}
        actionBtnTexts={{ confirm: 'Delete' }}
        actionBtnTypes={{ confirm: 'error', cancel: 'info' }}
        handleConfirm={async () => {
          const response = await deleteDocument(itemId);
          if (response) {
            const newPapers = papers.filter((item) => item.id !== itemId);
            const papersCount = newPapers.length;
            const userInfo = await getUserInfo();
            if (!papersCount) {
              setPageData({
                activedEditorPanelStep: 0,
                papers: [],
                userInfo
              });
              return;
            }
            setPageData({ papers: convertToPapers(newPapers), userInfo });
            if (itemId !== activeItemId) return;
            setActiveItemId(newPapers[0].id);
          }
        }}
      />
      <Dialog
        open={isExportTypeSelectionDialogOpen}
        title="Export paper"
        Content={<ExportPaper handleValueChange={setExportTypeValue} />}
        actionBtnTexts={{ cancel: 'Cancel', confirm: 'Export' }}
        handleClose={() => setIsExportTypeSelectionDialogOpen(false)}
        handleConfirm={handleExportPaper}
      />
      <Dialog
        open={isExportSuccessfullyDialogOpen}
        handleClose={() => setIsExportSuccessfullyDialogOpen(false)}
        title="Successfully exported!"
        Content={<ExportPaperSuccessfully />}
        Actions={
          <Button
            onClick={() => setIsExportSuccessfullyDialogOpen(false)}
            variant="contained"
            size="small"
          >
            ok
          </Button>
        }
      />
    </>
  );
});

export default Papers;
