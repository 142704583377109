import React from 'react';
import { Box, Typography, Button } from '@mui/material';

interface INoDataProps {
  handleGenerateReview: () => void;
  generateReviewButtonIsDisabled: boolean;
}

const NoData = ({
  handleGenerateReview,
  generateReviewButtonIsDisabled
}: INoDataProps) => {
  return (
    <Box textAlign="center" p={3}>
      <Typography fontWeight={500}>
        This document hasn’t been reviewed yet
      </Typography>
      <Typography
        variant="body2"
        color="#666666"
        sx={{ mt: 2 }}
        lineHeight="145%"
      >
        Apply AI Reviewer to evaluate Language, Structure, Argumentation, and
        Relevance.
      </Typography>
      <Button
        data-testid="generatingReviewInsightsButton"
        variant="outlined"
        disabled={generateReviewButtonIsDisabled}
        color="primary"
        size="small"
        onClick={handleGenerateReview}
        sx={{ mt: 4, textTransform: 'capitalize' }}
      >
        Generate Review
      </Button>
    </Box>
  );
};

export default NoData;
