import { useContext, useMemo } from 'react';
import { SubscriptionStatus, PlanStatus, UserInfo } from 'types/user';
import { store } from 'contexts/PageContextStore';

const usePlanStatus = (): PlanStatus => {
  const { storeState: { userInfo = {} } = {} } = useContext(store);
  const { is_internal: isUserInternal = false, plan } = userInfo as UserInfo;
  const {
    status = 'active',
    type = 'free',
    remaining_documents: remainingDocuments,
    cancel_at_period_end: cancelAtPeriodEnd = false
  } = plan || {};

  const isFree = type === 'free';
  const isAdvanced = type === 'advanced';
  const isPro = type === 'professional';
  const isPastDue = status === 'past_due';
  const isExpired = status === 'canceled' || isPastDue;
  const isPaidPlanExpired = !isFree && isExpired;
  const isCanceled = status === 'active' && cancelAtPeriodEnd;
  const hasNoRemainingDocuments = remainingDocuments === 0;
  const isReachedLimit = !isPro && hasNoRemainingDocuments;

  const subscriptionStatus: SubscriptionStatus = useMemo(() => {
    if (isCanceled) return 'canceled';
    if (isExpired) return 'expired';
    if (isFree) return 'free';
    return status;
  }, [isCanceled, isExpired, isFree, status]);

  return {
    isFree,
    isPro,
    isPastDue,
    isCanceled,
    isExpired,
    isPaidPlanExpired,
    isReachedLimit,
    isLimited: !isUserInternal && (isPastDue || isReachedLimit),
    isPlanReachedLimit: isAdvanced && hasNoRemainingDocuments,
    subscriptionStatus
  };
};

export default usePlanStatus;
