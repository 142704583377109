import React, { useState, memo } from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  LinearProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';

import { calculateSeriousnessCounts } from 'utils/reviewInsights';
import SortButtonsGroup from './components/SortButtonsGroup';
import SuggestionsList from './components/SuggestionsList';
import {
  IReviewCategory,
  ReviewSuggestionSeriousnessLevels,
  PredefinedCategoryIds,
  ILanguageMetrics,
  IReadabilityMetrics,
  IArgumentationMetrics
} from 'types/document';
import LanguageCategoryMetrics from './components/LanguageCategoryMetrics';
import ReadabilityCategoryMetrics from './components/ReadabilityCategoryMetrics';
import ArgumentationCategoryMetrics from './components/ArgumentationCategoryMetrics';

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    boxShadow: 'none',
    border: 'none',
    margin: '0 -16px 8px -16px',
    padding: 0,
    '&.Mui-expanded': {
      margin: '0 -16px 8px -16px',
      padding: 0
    },
    '&::before': {
      display: 'none'
    }
  },
  accordionSummaryRoot: {
    padding: '8px 16px',
    borderRadius: '12px',
    '&.Mui-expanded': {
      padding: '8px 16px',
      '& .MuiAccordionSummary-content': {
        margin: 0
      }
    },
    '&:hover': {
      backgroundColor: theme.palette?.grey[50],
      padding: '8px 16px'
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      position: 'absolute',
      right: 10
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)'
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: 0,
      padding: 0
    },
    '&.Mui-disabled': {
      opacity: 1
    }
  },
  suggestionsHeaderWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px 0 24px 0'
  },
  errorMessageWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 2,
    marginBottom: 8
  },
  errorIcon: {
    color: theme.palette?.error.dark,
    width: 16,
    height: 16
  }
}));

interface IReviewCategoryProps {
  category: IReviewCategory;
  maxScore: number;
}

const ReviewCategory = ({ category, maxScore }: IReviewCategoryProps) => {
  const classes = useStyles();
  const { suggestions, title, score, score_explanation } = category;

  const [activeFilter, setActiveFilter] =
    useState<ReviewSuggestionSeriousnessLevels | null>(null);

  const linearProgressValue = score * 10; // Multiply to 10, cuz 100 is the max LinearProgress value
  const seriousnessCounts = calculateSeriousnessCounts(suggestions);

  const handleFilterClick = (filter) => {
    setActiveFilter((prevFilter) => (prevFilter === filter ? null : filter));
  };

  const getPredefinedMetricsById = () => {
    switch (category.id) {
      case PredefinedCategoryIds.language:
        return (
          <LanguageCategoryMetrics
            title="Document Statistics:"
            metricsData={category.code_based_metrics as ILanguageMetrics}
          />
        );
      case PredefinedCategoryIds.readability:
        return (
          <ReadabilityCategoryMetrics
            title="Text Complexity:"
            metricsData={category.code_based_metrics as IReadabilityMetrics}
          />
        );
      case PredefinedCategoryIds.argumentation:
        return (
          <ArgumentationCategoryMetrics
            title="Cited Publications Overview:"
            metricsData={category.code_based_metrics as IArgumentationMetrics}
          />
        );
      default:
        return null;
    }
  };

  const filteredSuggestions =
    activeFilter === null
      ? suggestions
      : suggestions.filter(
          (suggestion) => suggestion.seriousness === activeFilter
        );

  return (
    <Accordion
      data-testid={`reviewCategory-wrapperWithId-${category.title}`}
      classes={{
        root: classes.accordionRoot
      }}
    >
      <AccordionSummary
        expandIcon={!!score ? <ExpandMoreIcon /> : null}
        disabled={!!!score}
        classes={{
          root: classes.accordionSummaryRoot
        }}
      >
        <Typography
          fontWeight={400}
          lineHeight={'145%'}
          fontSize={14}
          letterSpacing={'0.15px'}
          data-testid="reviewCategory-title"
        >
          {title}
        </Typography>
        {!!score ? (
          <Typography
            fontSize={18}
            fontWeight={700}
            lineHeight={'153%'}
            data-testid="reviewCategory-score"
          >
            {score}/{maxScore}
          </Typography>
        ) : (
          <div
            className={classes.errorMessageWrapper}
            //need to add test to this feature
            data-testid="reviewCategory-errorMessage"
          >
            <Typography fontSize={12} color="error.dark">
              AI review generation failed. Please try again later.
            </Typography>
            <ErrorOutlineRounded className={classes.errorIcon} />
          </div>
        )}
        <LinearProgress
          variant="determinate"
          value={linearProgressValue}
          aria-disabled={!!!score}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          fontSize={14}
          lineHeight={'145%'}
          letterSpacing={'0.15px'}
          data-testid="reviewCategory-scoreExplanation"
          sx={{ mb: 3 }}
        >
          {score_explanation}
        </Typography>
        {category?.code_based_metrics && getPredefinedMetricsById()}
        <div className={classes.suggestionsHeaderWrapper}>
          <Typography variant="body2" fontWeight={500} sx={{ mt: 1 }}>
            Suggestions:
          </Typography>
          <SortButtonsGroup
            activeFilter={activeFilter}
            handleFilterClick={handleFilterClick}
            seriousnessCounts={seriousnessCounts}
          />
        </div>
        <SuggestionsList
          filteredSuggestions={filteredSuggestions}
          totalSuggestionsLength={suggestions.length}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(ReviewCategory);
