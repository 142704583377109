import React, { FC, Suspense, lazy, useState } from 'react';
import { makeStyles } from '@mui/styles';

const LoginForm = lazy(() => import('./LoginForm'));
const CreateAccount = lazy(() => import('./CreateAccount'));
const NewPassword = lazy(() => import('./NewPassword'));
const ResetPassword = lazy(() => import('./ResetPassword'));
const CheckInfo = lazy(() => import('./CheckInfo'));
const ActiveAccount = lazy(() => import('./ActiveAccount'));
const TermsAndConditions = lazy(() => import('./TermsAndPrivacy'));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '33%',
    marginRight: 16,
    padding: '20px 40px',
    backgroundColor: theme.palette.common.white,
    borderRadius: 16,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 0,
      justifyContent: 'start',
      marginRight: 0
    }
  }
}));

interface MainContainerProps {
  step?: number;
  isResetPassword?: boolean;
}

const ActivedComponents = [
  LoginForm,
  CreateAccount,
  NewPassword,
  ResetPassword,
  CheckInfo,
  ActiveAccount,
  TermsAndConditions
];

const Login: FC<MainContainerProps> = (props) => {
  const classes = useStyles();
  const [step, setStep] = useState(props.step || 0);
  const [stepInfo, setStepInfo] = useState({});
  const ActivedComponent = ActivedComponents[step];

  return (
    <div className={classes.root}>
      <Suspense>
        <ActivedComponent
          setStep={setStep}
          {...props}
          setStepInfo={setStepInfo}
          stepInfo={stepInfo}
        />
      </Suspense>
    </div>
  );
};

export default Login;
