import { HttpService as axios } from './HttpService';
import { showErrorMessage } from './messages';
const postBibliographyFileName = async (
  name,
  currentFile,
  ok_cb,
  err_cb
): Promise<any> => {
  const reader = new FileReader();

  const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(file);
    });
  };

  try {
    const res = await axios.post('/bibliographies/custom_file/', { name });
    const uploadUrl = res.data.upload_url;
    const fileId = res.data.pk;

    const fileContents = await readFileAsArrayBuffer(currentFile);

    await axios.put(uploadUrl, fileContents, {
      headers: {
        'Content-Type': currentFile.type
      }
    });

    ok_cb(fileId);
    await axios.patch(`/bibliographies/custom_file/${fileId}/`, {
      status: 'uploaded'
    });
  } catch (error) {
    console.error('Error:', error);
    err_cb();
  }
};

const getBibliographyFiles = async (pageParams?: {
  pageSize?: number;
  cursor?: string;
}): Promise<any> => {
  const params = {};
  if (pageParams?.pageSize) {
    params['page_size'] = pageParams.pageSize;
  }
  if (pageParams?.cursor) {
    params['cursor'] = pageParams.cursor;
  }
  return await axios
    .get('/bibliographies/custom_file/', { params })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      showErrorMessage(err);
    });
};

const deleteBibliographyFile = async (id: string): Promise<any> => {
  return await axios
    .delete(`/bibliographies/custom_file/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      showErrorMessage(err);
    });
};

export {
  postBibliographyFileName,
  getBibliographyFiles,
  deleteBibliographyFile
};
