import { DOMSerializer } from 'prosemirror-model';

type SelectionMetadata = {
  BIB_ID: string;
  CHUNK_ID: string;
  text_chunk: string;
};

export function covertToDocument(data: any) {
  return {
    id: data.id,
    name: data.name,
    content: data.content,
    created: data.created,
    updated: data.updated
  };
}

export function findTargetSection(sections: any = [], parentId) {
  let targetSection: any = {};
  const findTargetSection = (sections: any = [], parentId) => {
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (section.id === parentId) {
        targetSection = section;
      }
      if (section.sub_sections) {
        findTargetSection(section.sub_sections, parentId);
      }
    }
  };
  findTargetSection(sections, parentId);
  return targetSection;
}

export function getSelectionInnerHTML(editorSelection?: any) {
  const selection = window.getSelection() || editorSelection;
  if (!selection?.rangeCount) {
    return '';
  }
  const range = selection?.getRangeAt(0);

  const tempContainer = document.createElement('div');
  tempContainer.appendChild(range?.cloneContents());
  const innerHTML = tempContainer.innerHTML;
  return innerHTML;
}

export function getSelectionMetadata(content: any, chunks: any) {
  const metadata: Array<SelectionMetadata> = [];
  const pattern = /BIB_ID:\s*([a-fA-F0-9-]+), CHUNK_ID:\s*([a-fA-F0-9-]+)/gi;
  const matches = content?.match(pattern);

  matches?.forEach((match) => {
    const [BIB_ID, CHUNK_ID] = match.split(',').map((item) => item.trim());
    const _ID = BIB_ID.split(':')[1];
    const _CHUNK_ID = CHUNK_ID.split(':')[1]?.trim();

    const targetChunk = chunks.find((chunk) =>
      [chunk?.id, chunk.metadata?.pubmed_id?.toString()].includes(_ID)
    );

    const _BIB_ID = targetChunk?.id || targetChunk?.metadata?.pubmed_id;
    const text_chunk = targetChunk?.chunks[_CHUNK_ID];

    _CHUNK_ID &&
      text_chunk &&
      metadata.push({
        BIB_ID: _BIB_ID,
        CHUNK_ID: _CHUNK_ID,
        text_chunk
      });
  });
  return metadata;
}

export function removeHtmlTags(str) {
  if (!str) return;
  else str = str?.toString();
  return str?.replace(/(<([^>]+)>)/gi, '');
}

export function removeMarkTags(str) {
  if (!str) return;
  else str = str.toString();
  return str.replace(/<mark[^>]*>|<\/mark>/gi, '');
}

export function getSelectionTextClientRect() {
  const selection = window.getSelection();
  if (!selection) return 0;
  if (selection.rangeCount === 0) {
    return 0;
  }
  const range = selection.getRangeAt(0);
  const rect = range.getBoundingClientRect();
  return rect;
}

export function getSelectionContext(content) {
  const selection = window.getSelection();
  if (!selection?.rangeCount) return;
  const range = selection.getRangeAt(0);
  if (!content) return;
  const selectedStart = range.startOffset;
  const selectedEnd = range.endOffset;
  const sentences = content
    .split('.')
    .map((sentence) => sentence.trim())
    .filter((sentence) => sentence.length > 0);
  let finalText = '';
  sentences.forEach((sentence) => {
    if (
      content.indexOf(sentence) <= selectedEnd &&
      content.indexOf(sentence) + sentence.length >= selectedStart
    ) {
      finalText += `${sentence}. `;
    }
  });
  if (!finalText) {
    sentences.forEach((sentence) => {
      if (
        content.indexOf(sentence) <= selectedStart &&
        content.indexOf(sentence) + sentence.length >= selectedEnd
      ) {
        finalText = sentence;
      }
    });
  }
  return finalText.trim();
}

export function formatHtmlToTextWithPlaceholder(htmlString, chunks) {
  let htmlStringWithoutMarkTags = removeMarkTags(htmlString);
  htmlStringWithoutMarkTags = htmlStringWithoutMarkTags.replace(/&nbsp;/g, '');
  const pattern =
    /<a\s+[^>]*id="([a-fA-F0-9-]+)"[^>]*data-chunk_id="([^"]+)"[^>]*>(.*?)<\/a>/gi;
  const pmidsAndChunkIdsMap = {};
  const convertedText = htmlStringWithoutMarkTags?.replace(
    pattern,
    (_, id, chunkIds, value) => {
      const targetChunk = chunks.find((chunk) =>
        [chunk?.id, chunk.metadata?.pubmed_id?.toString()].includes(id)
      );
      const BIB_ID = targetChunk?.id || targetChunk?.metadata?.pubmed_id;
      const hasLeftBracket = value.includes('(');
      const hasRightBracket = value.includes(')');
      const text = removeHtmlTags(value);
      const placeHolderText = text.replace(/[.\s]/gi, '_');
      const allChunkIds = chunkIds.split(',');
      const pmidsAndChunkIdText = allChunkIds
        .map((chunkId) => `BIB_ID:${BIB_ID || id}, CHUNK_ID:${chunkId}`)
        .join('; ');
      pmidsAndChunkIdsMap[text.replaceAll('&amp;', '&')] =
        `${hasLeftBracket ? '(' : ''}${pmidsAndChunkIdText}${hasRightBracket ? ')' : '; '}`;
      return placeHolderText;
    }
  );
  return { convertedText, pmidsAndChunkIdsMap };
}

export function findFullWord(content, queryText, queryPosition) {
  const query = queryText.trim();
  let wordStart = content.lastIndexOf(' ', queryPosition);
  wordStart = wordStart === -1 ? 0 : wordStart + 1;
  let wordEnd = content.indexOf(' ', queryPosition + query.length);
  wordEnd = wordEnd === -1 ? content.length : wordEnd + 1;
  let fullWord = content.slice(wordStart, wordEnd).trim();
  //remove special characters at the end of the word
  if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(fullWord)) {
    fullWord = fullWord.slice(0, -1);
  }
  return { fullWord, wordStart, wordEnd };
}

export function findTextContext(content, queryText, { startIndex, endIndex }) {
  const query = queryText.trim();
  const queryPosition = startIndex || content.indexOf(query);
  if (queryPosition === -1) return '';
  let sentenceStart = content.lastIndexOf('.', queryPosition);
  sentenceStart = sentenceStart === -1 ? 0 : sentenceStart + 1;
  let sentenceEnd = content.indexOf('.', endIndex);
  sentenceEnd = sentenceEnd === -1 ? content.length : sentenceEnd + 1;
  const fullSentence = content.slice(sentenceStart, sentenceEnd).trim();
  return fullSentence;
}

export function formatSentencesToPmidsAndChunkIds(editor, data, startPos) {
  const { pmidsAndChunkIdsMap = {} } = data;
  const { state } = editor || {};
  const { from = 0, to = 0 } = state?.selection || {};
  const documentSize = state?.doc?.content?.size;
  const endPos = to + 1000 > documentSize ? documentSize : to + 1000;
  let queryText = state?.doc.textBetween(from, to, ' ');
  let paragraphText = state?.doc.textBetween(startPos, endPos, ' ');
  Object.keys(pmidsAndChunkIdsMap).forEach((key) => {
    const _key = key.trim();
    const placeHolderKey = key.replace(/[.\s]/gi, '_');
    queryText = queryText.replaceAll(_key, placeHolderKey);
    paragraphText = paragraphText.replaceAll(_key, placeHolderKey);
  });
  let queryTextContext = findTextContext(paragraphText, queryText, {
    startIndex: from - startPos,
    endIndex: to - startPos
  });
  Object.keys(pmidsAndChunkIdsMap).forEach((key) => {
    const placeHolderKey = key.replace(/[.\s]/gi, '_');
    const _key = key.replaceAll('&amp;', '&');
    queryText = queryText.replaceAll(placeHolderKey, pmidsAndChunkIdsMap[_key]);
    queryTextContext = queryTextContext.replaceAll(
      placeHolderKey,
      pmidsAndChunkIdsMap[_key]
    );
  });
  return { queryText, queryTextContext };
}

export function getFullWordInParagraph(editor, targetNode, letter) {
  if (!letter || !letter.trim() || !targetNode) return {};
  const { state } = editor || {};
  const { from = 0 } = state?.selection || {};
  const startPos = editor?.view.posAtDOM(targetNode as HTMLElement, 0);
  const {
    fullWord,
    wordStart = 0,
    wordEnd = 0
  } = findFullWord(targetNode.innerText, letter, from - startPos);
  const isMutiLetter = letter.split(' ').length > 1;

  return {
    fullWord,
    wordStart: startPos + wordStart,
    wordEnd: startPos + wordEnd,
    isIncludingFullWord: letter.includes(fullWord) || isMutiLetter
  };
}

export function scrollToSection(e, id) {
  e?.stopPropagation();
  const element = document.getElementById(id);
  if (element) {
    document
      .getElementById('editorContentContainer')
      ?.scrollTo({ behavior: 'smooth', top: element.offsetTop - 20 });
  }
}

export function getAllSectionsByField(data = [], field, value?) {
  let sections: any = [];
  data.forEach((item: any) => {
    if ((!value && item[field]) || (value && item[field] === value)) {
      sections.push(item);
    }
    if (item.sub_sections) {
      sections = [
        ...sections,
        ...getAllSectionsByField(item.sub_sections, field, value)
      ];
    }
  });
  return sections;
}

export function getAllSectionsByFields(data = [], fields = {}) {
  let sections: any = [];
  data.forEach((item: any) => {
    const isMatched = Object.keys(fields).some(
      (key) =>
        (typeof fields[key] === 'boolean' && item[key]) ||
        item[key] === fields[key]
    );
    if (isMatched) {
      sections.push(item);
    }
    if (item.sub_sections) {
      sections = [
        ...sections,
        ...getAllSectionsByFields(item.sub_sections, fields)
      ];
    }
  });
  return sections;
}

export function updateSectionData(sections: any = [], id, attrs) {
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    if (section.id === id) {
      sections[i] = { ...section, ...attrs };
    } else {
      updateSectionData(section.sub_sections, id, attrs);
    }
  }
  return sections;
}

export function updatePapers(papers, id, sectionId, attrs, type) {
  const _papers = papers.map((item) => {
    if (item.id === id) {
      if (type === 'section') {
        return {
          ...item,
          sections: updateSectionData(item.sections, sectionId, attrs)
        };
      }
      return { ...item, ...attrs };
    }
    return item;
  });

  return _papers;
}

export function getTextContent(content) {
  const pattern =
    /(^|\s)\(<a\s+[^>]*id="([a-fA-F0-9-]+)"[^>]*data-chunk_id="([^"]+)"[^>]*>(.*?)<\/a>\)($|\s)/gi;
  let htmlString = content?.replace(pattern, () => {
    return '';
  });
  //remove all the tags
  htmlString = htmlString.replace(/<[^>]*>/g, '');
  return htmlString;
}

export function isTextContentsChanged(prevHtml, newHtml) {
  if (prevHtml && newHtml) {
    const textContent1 = getTextContent(prevHtml).trim();
    const textContent2 = getTextContent(newHtml).trim();

    return textContent1 !== textContent2;
  } else {
    return true;
  }
}

//TODO: handle nested content
export function getSectionContent(content, id) {
  const sectionContent = content.filter(
    (item) =>
      (item.attrs && item.attrs['data-parent'] === id) ||
      (item.content &&
        item.content[0] &&
        item.content[0].attrs &&
        item.content[0].attrs['data-parent'] === id) ||
      (item.content &&
        item.content[0] &&
        item.content[0].content &&
        item.content[0].content[0] &&
        item.content[0].content[0].attrs &&
        item.content[0].content[0].attrs['data-parent'] === id)
  );
  return sectionContent;
}

export function getAllAbstractChunkTexts(chunks: {}, targetChunkIds: string) {
  const chunkIds = targetChunkIds?.split(',');
  const abstractChunks = chunkIds?.map((id) => {
    return chunks[id]?.split('\n');
  });
  return abstractChunks?.filter((item) => item);
}

export function isSelectionIncludeElementBySelector(selector) {
  const selection = window.getSelection();
  // If there's no selection or it's empty, exit early
  if (!selection || selection.isCollapsed) {
    return;
  }

  let sectionTitleSelected = false;
  // Loop through the selected ranges
  for (let i = 0; i < selection.rangeCount; i++) {
    const range = selection.getRangeAt(i);
    // Get the common ancestor container of the range
    const commonAncestor = range.commonAncestorContainer as HTMLElement;
    // If the common ancestor itself is .sectionTitle, mark as true
    if (
      commonAncestor.nodeType === Node.ELEMENT_NODE &&
      commonAncestor.classList.contains(selector)
    ) {
      sectionTitleSelected = true;
      break;
    }
    // Otherwise, traverse all nodes within the range
    const nodes = document.createNodeIterator(
      commonAncestor,
      NodeFilter.SHOW_ELEMENT,
      {
        acceptNode: (node: any) => {
          // Check if the node is part of the selection range
          if (range.intersectsNode(node) && node.classList.contains(selector)) {
            return NodeFilter.FILTER_ACCEPT;
          }
          return NodeFilter.FILTER_REJECT;
        }
      }
    );

    let currentNode;
    while ((currentNode = nodes.nextNode())) {
      if (currentNode.classList.contains(selector)) {
        sectionTitleSelected = true;
        break;
      }
    }
  }
  return sectionTitleSelected;
}

export function getHtmlTagByType(type) {
  switch (type) {
    case 'h1':
      return 'h1';
    case 'h2':
      return 'h2';
    case 'h3':
      return 'h3';
    case 'h4':
      return 'h4';
    case 'h5':
      return 'h5';
    case 'h6':
      return 'h6';
    default:
      return 'div';
  }
}

// Function to get HTML of the current selection
export function getEditorSelectionHtml(editor) {
  const { state } = editor;
  const { selection } = state;
  // Check if a selection is made
  if (selection.empty) {
    return '';
  }
  // Extract the fragment from the document using the selection's from and to positions
  const fragment = state.doc.slice(selection.from, selection.to).content;
  // Create a ProseMirror DOMSerializer from the editor schema
  const serializer = DOMSerializer.fromSchema(editor.schema);
  // Create a temporary document fragment to which we'll serialize the selection
  const tempFragment = document.createDocumentFragment();
  // Serialize each node in the fragment to the temporary fragment
  fragment.forEach((node) => {
    tempFragment.appendChild(serializer.serializeNode(node));
  });
  // Create a temporary div to extract the HTML from the fragment
  const div = document.createElement('div');
  div.appendChild(tempFragment);
  // Return the HTML content as a string
  return div.innerHTML;
}

export function getHTMLOfSelection() {
  const selection = window.getSelection();
  if (!selection) return '';
  let range;
  if (selection?.rangeCount > 0) {
    range = selection.getRangeAt(0);
    const clonedSelection = range.cloneContents();
    const div = document.createElement('div');
    div.appendChild(clonedSelection);
    return div.innerHTML;
  }
  return '';
}

export function getSectionId(element) {
  if (!element) return;
  const parentNode = (element.parentNode ||
    element.closest('p') ||
    element.closest('div')?.querySelector('h2')) as HTMLElement;
  const parentId = parentNode?.getAttribute('data-parent');
  return parentId;
}

export function isLink(node) {
  const nodeName = node?.nodeName;
  if (nodeName !== 'A') {
    const parentNode = node.parentNode as HTMLElement;
    const isVaildLink = !!parentNode?.closest('a')?.getAttribute('href');
    return parentNode?.nodeName === 'A' || isVaildLink;
  }
  return true;
}
