import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { CircularProgress, Divider, Stack } from '@mui/material';
import BibliographyPublicationItem from '../components/BibliographyPublicationItem';
import { store } from 'contexts/PageContextStore';
import { useStore } from 'contexts/store';
import BibliographyNoData from 'assets/rightPanel/bibliography.svg';
import NoData from '../components/NoData';
import { removeDuplicates } from 'utils/utils';
import { getDocument } from 'services/documents';
import BibliographyCustomItem from '../components/BibliographyCustomItem';
import { useParams } from 'react-router-dom';

interface IBibliographyProps {}

const Bibliography: FC<IBibliographyProps> = () => {
  const {
    storeState: {
      paper = {},
      bibliography = [],
      isPaperLoading = false,
      isBibliographyChanged = false
    } = {},
    setPageData
  } = useContext(store);
  const { id: viewedPaperId = '' } = useParams();

  const reloadBibliography = useStore((state) => state.reloadBibliography);

  const [bibliographyList, setBibliographyList] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isPaperLoading || !paper.bibliographies.length) {
      return;
    }
    setBibliographyList(removeDuplicates(paper.bibliographies, 'id'));
  }, [isPaperLoading, paper.bibliographies.length]);

  useEffect(() => {
    if (!bibliography.length || paper.id !== viewedPaperId) return;
    setBibliographyList([...bibliography, ...bibliographyList]);
    setPageData({ isBibliographyChanged: false });
  }, [bibliography, paper.id]);

  useEffect(() => {
    if (reloadBibliography) {
      setLoading(true);
      return;
    }
    const abortController = new AbortController();
    const getBibliographyList = async (id, abortController) => {
      const response = await getDocument(id, abortController);
      setLoading(false);
      if (!response) return;
      setBibliographyList(response.bibliographies);
    };
    getBibliographyList(viewedPaperId, abortController);
    return () => {
      abortController?.abort();
    };
  }, [reloadBibliography]);

  useEffect(() => {
    if (!isBibliographyChanged) {
      return;
    }
    const abortController = new AbortController();
    const getBibliographyList = async (id, abortController) => {
      const response = await getDocument(id, abortController);
      if (!response) return;
      setBibliographyList(response.bibliographies);
      setPageData({ isBibliographyChanged: false });
    };
    getBibliographyList(viewedPaperId, abortController);
    return () => {
      abortController.abort();
    };
  }, [isBibliographyChanged]);

  if (isPaperLoading || isBibliographyChanged || loading) {
    return (
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={24} />
      </Stack>
    );
  }

  if (!bibliographyList?.length && !isPaperLoading) {
    return (
      <NoData
        imgSrc={BibliographyNoData}
        content={
          <p>
            Bibliography is not ready yet.
            <br />
            Don't worry, it will populate this space once the document is
            generated.
          </p>
        }
      />
    );
  }

  const fileItems = bibliographyList.filter(
    (item: any) => item.type === 'file'
  );
  const publicationItems = bibliographyList.filter(
    (item: any) => item.type === 'pubmed'
  );
  const webSearchItems = bibliographyList.filter(
    (item: any) => item.type === 'websearch'
  );

  return (
    <>
      {fileItems.map((item: any) => {
        const { metadata: { file_name = '' } = {}, id = '' } = item || {};
        return <BibliographyCustomItem key={id} fileName={file_name} />;
      })}
      {!!fileItems.length && !!publicationItems.length && (
        <Divider sx={{ mt: 2, mb: 2, borderColor: '#F2F2F2' }} />
      )}
      {publicationItems.map((item: any) => {
        const {
          metadata: {
            pubmed_id = '',
            title = '',
            citation_count = 2,
            journal_name = '',
            pub_type = [],
            text = '',
            pub_year = '',
            authors = []
          } = {},
          isNew = false,
          id = ''
        } = item || {};
        return (
          <Fragment key={id || pubmed_id}>
            <BibliographyPublicationItem
              key={pubmed_id}
              title={title}
              citationsCount={citation_count}
              journal={journal_name}
              pubType={pub_type || []}
              pmid={pubmed_id}
              abstractText={text}
              year={pub_year}
              authors={authors}
              isNew={isNew}
            />
          </Fragment>
        );
      })}
      {webSearchItems.map((item: any) => {
        const { metadata: { url = '' } = {}, id = '' } = item || {};
        return <BibliographyCustomItem key={id} url={url} />;
      })}
    </>
  );
};

export default Bibliography;
