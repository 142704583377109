import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import {
  ArticleRounded,
  AssignmentLateRounded,
  CheckCircleRounded,
  ErrorOutlineRounded,
  ErrorRounded,
  MoreHorizRounded
} from '@mui/icons-material';
import { Tooltip, CircularProgress, IconButton } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view';
import classNames from 'classnames';
import { scrollToSection } from 'utils/editor';
import { DocumentStage, DocumentStatus } from 'types/document';

const useStyles = makeStyles((theme) => ({
  moreActionButton: {
    position: 'absolute',
    right: 16,
    zIndex: 1,
    visibility: 'hidden',
    '&:hover': {
      visibility: 'visible',
      borderRadius: '50%'
    }
  },
  treeRootItemWapper: {
    '& .MuiTreeItem-iconContainer': {
      alignItems: 'center'
    },
    '& .MuiTreeItem-content': {
      '&:hover': {
        '& $moreActionButton': {
          visibility: 'visible'
        },
        '& .rootIcon': {
          right: 42
        }
      }
    }
  },
  treeChildItemWapper: {
    '&:hover': {
      '& $moreActionButton': {
        visibility: 'hidden'
      }
    }
  },
  treeItemWapper: {
    position: 'relative',
    '& .MuiTreeItem-root .MuiCollapse-root': {
      padding: 0
    },
    '& .MuiTreeItem-content': {
      height: 36,
      marginBottom: 4,
      padding: '8px 16px',
      borderRadius: 12,
      gap: 0,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.light} !important`
      }
    },
    '& .Mui-selected:hover': {
      background: `${theme.palette.grey[50]}`,
      borderRadius: 12
    },

    '& .MuiTreeItem-label': {
      maxWidth: '75%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('xl')]: {
        maxWidth: '80%'
      }
    }
  },

  treeItemsNotSelected: {
    backgroundColor: `transparent !important`,
    borderRadius: 12
  },
  treeItemsSelected: {
    background: `${theme.palette.grey[50]}!important`,
    borderRadius: 12
  },
  treeRootItem: {
    position: 'relative',
    height: '40px !important',
    '& .MuiTreeItem-label': {
      marginLeft: 12
    },
    '&.MuiTreeItem-content': {
      padding: '8px 16px'
    },
    '&:hover': {
      '& $moreActionButton': {
        visibility: 'visible'
      }
    }
  },
  treeItem: {
    '& .MuiTreeItem-iconContainer': {
      width: 0,
      '& svg': {
        fontSize: 22
      }
    },
    '&+.MuiCollapse-root': {
      paddingLeft: '8px !important'
    }
  },
  paperStatusIcon: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 16,
    zIndex: 1,
    '&:hover+li>.MuiTreeItem-content': {
      backgroundColor: `${theme.palette.primary.light} !important`,
      right: 38
    }
  },
  toolTip: {
    maxWidth: 500,
    padding: '5px 8px',
    maxHeight: 27,
    backgroundColor: '#616161',
    color: theme.palette.common.white,
    lineHeight: '137%',
    fontSize: 12,
    borderRadius: 8
  }
}));

const paperStatusIcon = {
  completed: () => <CheckCircleRounded htmlColor="#29A96D" />,
  limited: () => (
    <Tooltip title="You have reached your plan’s limit" placement="right" arrow>
      <AssignmentLateRounded htmlColor="#E8A728" sx={{ cursor: 'pointer' }} />
    </Tooltip>
  ),
  in_progress: () => <CircularProgress size={20} />,
  polishing: () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 22,
        height: 22
      }}
    >
      <span
        style={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: '#9FDBBB'
        }}
      ></span>
    </div>
  ),
  failed: (isPoblishing) => {
    return isPoblishing ? (
      <ErrorOutlineRounded htmlColor="#F44336" style={{ fontSize: 20 }} />
    ) : (
      <ErrorRounded htmlColor="#F44336" style={{ fontSize: 20 }} />
    );
  }
};

interface CustomTreeItemProps {
  id: string;
  activeItemId: string;
  activedSectionId: string;
  setActivedSectionId: (_id: string) => void;
  label: string;
  status: DocumentStatus;
  stage: DocumentStage;
  isPaperCompleted: boolean;
  isNotRoot: boolean;
  sections: any[];
  sub_sections: any[];
  isSinglePage?: boolean;
  handleOpenMenu: (_event: any, _data: any) => void;
}

const CustomTreeItem: FC<CustomTreeItemProps> = ({
  id,
  activeItemId,
  activedSectionId,
  label,
  stage,
  status,
  isPaperCompleted,
  isNotRoot,
  sections,
  sub_sections,
  isSinglePage,
  handleOpenMenu,
  setActivedSectionId = () => {}
}) => {
  const classes = useStyles();
  const PaperStatus = paperStatusIcon[status];
  const shouldShowStatus = !isPaperCompleted
    ? ['polishing'].includes(stage)
      ? ['in_progress', 'polishing'].includes(status)
      : ['in_progress', 'completed', 'failed'].includes(status)
    : ['failed', 'limited', 'polishing'].includes(status);
  const children = !isSinglePage ? sections || sub_sections : [];
  const isActive = activeItemId === id || activedSectionId === id;

  return (
    <>
      <div
        className={classNames(
          classes.treeItemWapper,
          !isNotRoot ? classes.treeRootItemWapper : classes.treeChildItemWapper
        )}
        onClick={(e) => {
          scrollToSection(e, id);
          setActivedSectionId(id);
        }}
      >
        <TreeItem
          itemId={id}
          label={label}
          classes={{
            content: classNames(
              !isNotRoot ? classes.treeRootItem : classes.treeItem,
              isActive
                ? classes.treeItemsSelected
                : classes.treeItemsNotSelected
            ),
            selected: isActive
              ? classes.treeItemsSelected
              : classes.treeItemsNotSelected
          }}
          slots={{
            icon: () => (
              <>
                <span
                  className={classNames(
                    classes.paperStatusIcon,
                    !isNotRoot && 'rootIcon'
                  )}
                >
                  {shouldShowStatus &&
                    PaperStatus &&
                    PaperStatus(stage === 'polishing')}
                </span>
                {isNotRoot ? null : (
                  <>
                    <ArticleRounded
                      htmlColor="#29A96D"
                      style={{ fontSize: 24 }}
                    />
                    <IconButton
                      classes={{ root: classes.moreActionButton }}
                      sx={{
                        width: 24,
                        height: 24,
                        p: '2px',
                        '&:hover': {
                          backgroundColor: '#C4E9D5'
                        }
                      }}
                      onClick={(e: any) =>
                        handleOpenMenu(e, { id, title: label, status })
                      }
                    >
                      <MoreHorizRounded
                        sx={{ cursor: 'pointer' }}
                        htmlColor="#757575"
                      />
                    </IconButton>
                  </>
                )}
              </>
            )
          }}
        >
          {children?.map((child) => {
            const isPolishing = !!child.refined_result;
            const status = isPolishing ? 'polishing' : child.status;
            return (
              <CustomTreeItem
                key={child.id}
                {...child}
                isPaperCompleted={isPaperCompleted}
                isPolishing={isPolishing}
                isNotRoot={true}
                status={status}
                stage={stage}
                activedSectionId={activedSectionId}
                setActivedSectionId={setActivedSectionId}
                isSinglePage={isSinglePage}
              />
            );
          })}
        </TreeItem>
      </div>
    </>
  );
};

export default CustomTreeItem;
