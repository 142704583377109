import React, { FC, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Divider,
  Typography
} from '@mui/material';
import { ArticleRounded, ExpandMoreRounded } from '@mui/icons-material';
import { theme } from 'theme';
import { store } from 'contexts/PageContextStore';
import { UserInfo } from 'types/user';
import moment from 'moment';
import { getPaymentsPortal } from 'services/payments';

const useStyles = makeStyles(() => ({
  userPlanAccordion: {
    boxShadow: 'none',
    paddingBottom: 8,
    '&.Mui-expanded': {
      margin: 0
    },
    '&::before': {
      display: 'none'
    }
  },
  userPlanAccordionFocus: {
    backgroundColor: 'transparent'
  },
  userPlanAccordionSummary: {
    padding: 0,
    maxHeight: 48,
    '&.Mui-expanded': {
      minHeight: 48
    }
  },
  userPlanAccordionSummaryContent: {
    alignItems: 'center',
    gap: 16,
    '&.Mui-expanded': {
      marginTop: 0,
      marginBottom: 0,
      minHeight: 48
    }
  },
  accordionDetails: {
    padding: 0
  },
  paymentDialogPaper: {
    maxWidth: '100%',
    minWidth: 900,
    borderRadius: 16
  }
}));

interface UserPlanProps {}

const UserPlan: FC<UserPlanProps> = () => {
  const classes = useStyles();
  const [isExpended, setIsExpended] = useState(true);
  const { storeState: { userInfo = {} } = {}, setPageData } = useContext(store);
  const { is_internal: isUserInternal = false, plan } = userInfo as UserInfo;
  const {
    status = '',
    type = 'free',
    end_date,
    cancel_at_period_end: cancelAtPeriodEnd = false
  } = plan || {};

  const statusInfo = {
    active: {
      text: 'Renews'
    },
    free: {
      text: 'Free plan ends'
    },
    expired: {
      color: theme.palette.error.dark,
      text: 'Expired'
    },
    canceled: {
      text: 'Expires on'
    }
  };

  const plans = {
    free: {
      tag: {
        text: 'Free',
        color: '#8A4908',
        bgColor: '#FFEBC2'
      },
      documents: 1,
      button: {
        text: 'Upgrade',
        onClick: () => {}
      }
    },
    advanced: {},
    professional: {
      remaining_documents: 'Unlimited',
      decription: '“Professional” plan allows unlimited document creation',
      button: {
        text: 'renew',
        onClick: () => {}
      }
    }
  };

  const isPaidPlan = type !== 'free';
  const isPastDue = status === 'past_due';
  const isExpired = status === 'canceled' || isPastDue;
  const isPaidPlanExpired = isPaidPlan && isExpired;
  const isCanceled = status === 'active' && cancelAtPeriodEnd;

  const subscriptionStatus = isCanceled
    ? 'canceled'
    : isExpired
      ? 'expired'
      : !isPaidPlan
        ? 'free'
        : status;

  const userPlan = {
    ...plan,
    ...plans[type],
    tag: isExpired
      ? {
          text: 'Expired',
          color: '#621B16',
          bgColor: '#FEECEB'
        }
      : plans[type].tag,
    isExpired,
    endDateInfo: statusInfo[subscriptionStatus],
    button: {
      text: isPaidPlanExpired ? 'Renew' : 'Upgrade',
      variant: isPaidPlanExpired ? 'contained' : 'outlined',
      onClick: () => {}
    }
  };

  const {
    tag,
    decription,
    remaining_documents: remainingDocuments,
    endDateInfo = {},
    button
  } = userPlan;

  if (isUserInternal) {
    return null;
  }
  return (
    <>
      <Divider sx={{ color: '#f2f2f2' }} />
      <Accordion
        expanded={isExpended}
        classes={{
          root: classes.userPlanAccordion
        }}
        onChange={() => {
          setIsExpended(!isExpended);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          focusVisibleClassName={classes.userPlanAccordionFocus}
          classes={{
            root: classes.userPlanAccordionSummary,
            content: classes.userPlanAccordionSummaryContent
          }}
        >
          <Typography
            color={theme.palette.text.secondary}
            fontSize={14}
            fontWeight={500}
          >
            Remaining in your plan
          </Typography>
          {!isExpended && (
            <Typography color="#666" fontSize={14} sx={{ minWidth: 50 }}>
              <ArticleRounded
                htmlColor="#30B878"
                sx={{ verticalAlign: 'middle' }}
              />
              {remainingDocuments}
            </Typography>
          )}
          {tag && (
            <Chip
              label={
                <Typography color={tag.color} fontSize={12}>
                  {tag.text}
                </Typography>
              }
              sx={{ backgroundColor: tag.bgColor }}
              size="small"
            />
          )}
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetails }}>
          <Typography
            color={theme.palette.text.secondary}
            fontSize={14}
            display="flex"
            alignItems="center"
          >
            <ArticleRounded
              htmlColor={!remainingDocuments ? '#BDBDBD' : '#29A96D'}
              sx={{
                fontSize: 20,
                mr: 0.5
              }}
            />
            <label>Documents: {remainingDocuments}</label>
          </Typography>
          {decription && (
            <Typography
              color={theme.palette.text.secondary}
              sx={{ mt: 1 }}
              fontSize={14}
            >
              {decription}
            </Typography>
          )}
          {end_date && (
            <Typography
              color={endDateInfo.color || '#666'}
              sx={{ mt: 1 }}
              fontSize={14}
            >
              {endDateInfo.text} {moment(end_date).format('DD MMM YYYY')}
            </Typography>
          )}
          {(isPaidPlanExpired || !isPaidPlan) && (
            <Button
              variant={button.variant}
              color="primary"
              sx={{ mt: 2, fontWeight: 700, zIndex: 1 }}
              onClick={async () => {
                if (isPastDue && isPaidPlan) {
                  const response = await getPaymentsPortal();
                  window.open(response?.portal_url, '_blank');

                  return;
                }
                setPageData({ showSubscriptionDialog: true });
                button.onClick();
              }}
              fullWidth
            >
              {button.text}
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default UserPlan;
