import { chunksAuthorFormatter } from './chunksAuthorFormatter';
import { removeDuplicates } from './utils';
import { Metadata } from 'types/document';
import MarkdownIt from 'markdown-it';
import { JSONContent } from '@tiptap/core';

interface MarkdownContent {
  type: string;
  attrs?: Record<string, any>;
  content: any[];
}

interface InlineItem extends Partial<MarkdownContent> {
  type: 'text';
  text?: string;
  marks?: any[];
}

const convertMarkdownToJson = (
  markdown: string,
  parentId: string = ''
): MarkdownContent => {
  const md = new MarkdownIt();
  const tokens = md.parse(markdown, {});

  const convertToMarkdownContent = (markdownTokens: any) => {
    let markdownContent: MarkdownContent = {
      type: 'paragraph',
      attrs: {
        'data-parent': parentId
      },
      content: []
    };

    let currentListItem: Partial<MarkdownContent> | null = null;
    //TODO: Refactor this to support nested  inline items
    let inlineItem: InlineItem | null = null;

    markdownTokens.forEach((token) => {
      switch (token.type) {
        case 'heading_open':
          markdownContent = {
            type: 'heading',
            attrs: {
              level: parseInt(token.tag[1]),
              'data-parent': parentId
            },
            content: []
          };
          break;
        case 'heading_close':
          break;

        case 'bullet_list_open':
          markdownContent.type = 'bulletList';
          break;
        case 'bullet_list_close':
          break;

        case 'ordered_list_open':
          markdownContent.type = 'orderedList';
          break;
        case 'ordered_list_close':
          break;

        case 'list_item_open':
          currentListItem = {
            type: 'listItem',
            attrs: {
              color: ''
            },
            content: [
              {
                type: 'paragraph',
                attrs: {
                  'data-parent': parentId,
                  class: 'paperContent'
                },
                content: []
              }
            ]
          };
          break;
        case 'list_item_close':
          markdownContent.content.push(currentListItem);
          currentListItem = null;
          break;

        case 'blockquote_open':
          markdownContent = {
            type: 'blockquote',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  'data-parent': parentId
                },
                content: []
              }
            ]
          };
          break;
        case 'blockquote_close':
          break;

        case 'inline':
          const inlineContent =
            currentListItem?.content?.[0]?.content ||
            markdownContent.content[0]?.content ||
            markdownContent.content;

          inlineContent.push({
            type: 'text',
            text: token.content,
            marks: inlineItem?.marks
          });

          break;

        case 'link_open':
          const link = {
            type: 'link',
            attrs: {
              href: token.href,
              target: '_blank'
            },
            content: []
          };
          markdownContent.content.push(link);
          break;
        case 'link_close':
          break;

        //bold
        case 'strong_open':
          inlineItem = {
            type: 'text',
            marks: [
              {
                type: 'bold'
              }
            ]
          };

          break;
        case 'strong_close':
          break;

        //italic
        case 'em_open':
          inlineItem = {
            type: 'text',
            marks: [
              {
                type: 'italic'
              }
            ]
          };
          break;
        case 'em_close':
          break;

        //underline
        case 'u_open':
          inlineItem = {
            type: 'text',
            marks: [
              {
                type: 'underline'
              }
            ]
          };
          break;
        case 'u_close':
          break;

        case 'text':
          if (currentListItem) {
            currentListItem.content?.[0].content.push({
              type: 'text',
              text: token.content
            });
          }
          break;
      }
    });

    return markdownContent;
  };

  return convertToMarkdownContent(tokens);
};

const convertToBibliographies = (
  chunks: any = [],
  bibliographyList: any[] = []
) => {
  chunks.forEach((item) => {
    chunks[item.pubmed_id] = {
      ...chunks[item.pubmed_id],
      [item.chunk_id]: item.fulltext_chunk || item.abstract_chunk
    };
  });

  const bibliographies: Metadata[] = [...bibliographyList]?.map((item) => {
    return { metadata: { ...item }, chunks: chunks[item.pubmed_id] || {} };
  });
  return bibliographies;
};

function convertToPapers(papers: any) {
  return [...papers].map((item) => ({
    ...item,
    label: item.title,
    isRoot: true
  }));
}

const convertToResultWithLink = (
  text: string,
  parentId: string,
  bibliographyList: any[] = [],
  index?: number
) => {
  const isJsonContent = typeof index === 'number';
  let resultDataContent: any = isJsonContent
    ? {
        type: 'paragraph',
        attrs: {
          ['data-parent']: parentId,
          id: `${parentId}_${index}`
        },
        content: []
      }
    : '';
  const regexPattern = /\((BIB_ID:.*?)\)/g;
  const pairsRegex = /BIB_ID:([a-fA-F0-9-]+),\sCHUNK_ID:([a-fA-F0-9-]+)/g;
  text.split(regexPattern).forEach((part: any) => {
    if (!part) return;
    if (part.match(pairsRegex)) {
      if (!bibliographyList.length) return;
      const allMatches = [...part.matchAll(pairsRegex)];
      if (!allMatches.length) return;
      const matches = [...removeDuplicates(allMatches, 1)];
      const vaildPIMDs = bibliographyList.map((item) => item?.id);
      let allVaildMatchs = matches.map((match) => {
        return vaildPIMDs.includes(match[1]) ? match : null;
      });
      allVaildMatchs = allVaildMatchs.filter((item) => item) || [];
      allVaildMatchs.forEach((match, index) => {
        const [ID] = match.slice(1);
        const { metadata = {}, type } = bibliographyList.find(
          (item) => item?.id === ID
        );
        const targetBilbiography = chunksAuthorFormatter(metadata, type);
        const findAllCHUNKIDsByPMID = () => {
          const CHUNKIDs: string[] =
            allMatches
              .filter((item) => item[1] === ID)
              .map((item) => item[2]) || [];
          return Array.from(new Set(CHUNKIDs));
        };
        const allCHUNKIDs = findAllCHUNKIDsByPMID()?.join(',');
        const isFirst = index === 0;
        const isLast = index === allVaildMatchs.length - 1;

        const isCustomLink = ['file', 'websearch'].includes(type);

        if (!isJsonContent) {
          const targetHref = isCustomLink
            ? '#'
            : `https://pubmed.ncbi.nlm.nih.gov/${metadata?.pubmed_id}`;
          const linkContent = `<a href="${targetHref}" id="${ID}" data-parent="${parentId}" data-chunk_id="${allCHUNKIDs}" class='reference ${!isLast ? 'withSpace' : ''}' target="_blank">${isFirst ? '(' : ''}${targetBilbiography}${!isLast ? '; ' : ''}${isLast ? ')' : ''}</a>`;
          resultDataContent = resultDataContent + linkContent;
          return;
        }

        resultDataContent.content.push({
          text: `${isFirst ? '(' : ''}${targetBilbiography}${!isLast ? '; ' : ''}${isLast ? ')' : ''}`,
          type: 'text',
          marks: [
            {
              type: 'link',
              attrs: {
                id: ID,
                href: '#',
                class: `references ${!isLast ? 'withSpace' : ''}`,
                'data-parent': parentId,
                'data-chunk_id': allCHUNKIDs,
                ...(isCustomLink
                  ? {
                      'data-type': type,
                      'data-custom_text': metadata?.file_name || metadata?.url
                    }
                  : {}),
                contenteditable: 'true'
              }
            },
            {
              type: 'textStyle',
              attrs: {
                color: ''
              }
            }
          ]
        });
      });
    } else {
      if (!isJsonContent) {
        resultDataContent = resultDataContent + part;
        return;
      }

      resultDataContent.content.push({
        text: part,
        type: 'text'
      });
    }
  });

  return resultDataContent;
};

const convertResultData = (
  resultData: string = '',
  parentId,
  _contenteditable,
  bibliographyList
) => {
  return (
    resultData
      .split('\n\n')
      ?.map((text: any, index) => {
        if (!text) return null;
        const linkList = text.match(/\(BIB_ID:.*?\)/g);
        if (linkList) {
          return convertToResultWithLink(
            text,
            parentId,
            bibliographyList,
            index
          );
        } else {
          return convertMarkdownToJson(text, parentId);
        }
      })
      ?.filter((item) => item) || []
  );
};

function convertToPaper(paper: any, papers, bibliographyList: any = []) {
  const generatingContent = [
    {
      type: 'paragraph',
      attrs: {
        class: 'textSkeleton',
        contenteditable: false
      }
    }
  ];

  // Hide title if generation_flow is single page and only one section
  const isHiddenTitle =
    paper.generation_flow === 'single_page' && paper.sections?.length === 1;

  const subContents = (item, level) => {
    return (
      [...(item?.sub_sections || [])]?.map((subItem) => {
        subItem.label = subItem.title;
        subItem.level = level;
        const sectionResult = subItem.refined_result || subItem.result;
        const isGenerating = subItem.status === 'in_progress';
        const isPolising = paper.stage === 'polishing';
        const resultData = isGenerating
          ? generatingContent
          : sectionResult?.data_format ||
            convertResultData(
              sectionResult?.data,
              subItem.id,
              paper.status === 'completed',
              bibliographyList
            );
        resultData.push({
          type: 'reactComponent',
          attrs: {
            type: 'actions',
            id: subItem.id,
            isRequired: true,
            enableFeedBack:
              subItem.status === 'completed' && subItem.like === null
          }
        });
        const isPolisingFailed = isPolising && subItem.status === 'failed';
        return [
          {
            ...subItem,
            id: subItem.id,
            name: subItem.title,
            label: subItem.title,
            type: 'reactComponent',
            attrs: {
              id: subItem.id,
              title: subItem.title,
              className: `sectionTitle title ${isHiddenTitle ? 'hidden' : ''}`,
              type: `h${level}`,
              contenteditable: subItem.status === 'completed',
              isRequired: true,
              isPolising: !!subItem.refined_result || isPolisingFailed,
              isPolisingFailed,
              errorMessage: 'Please enter the heading of the section'
            },
            content: [
              {
                type: 'text',
                text: subItem.title
              }
            ]
          },
          ...resultData,
          ...subContents(subItem, level + 1).flat()
        ];
      }) || []
    );
  };

  const content =
    { ...paper }?.sections?.map((item) => {
      const level = 2;
      item.label = item.title;
      const sectionResult = item.refined_result || item.result;
      const isGenerating = item.status === 'in_progress';
      const isPolising = paper.stage === 'polishing';
      const resultData = isGenerating
        ? generatingContent
        : sectionResult?.data_format ||
          convertResultData(
            sectionResult?.data,
            item.id,
            paper.status === 'completed',
            bibliographyList
          );
      resultData.push({
        type: 'reactComponent',
        attrs: {
          type: 'actions',
          id: item.id,
          isRequired: true,
          enableFeedBack: item.status === 'completed' && item.like === null
        }
      });
      const isPolisingFailed = isPolising && item.status === 'failed';

      return [
        {
          ...item,
          id: item.id,
          name: item.title,
          label: item.title,
          type: 'reactComponent',
          attrs: {
            id: item.id,
            title: item.title,
            className: `sectionTitle title ${isHiddenTitle ? 'hidden' : ''}`,
            level: 2,
            type: 'h2',
            contenteditable: item.status === 'completed',
            isRequired: true,
            isPolising: !!item.refined_result || isPolisingFailed,
            isPolisingFailed,
            errorMessage: 'Please enter the heading of the section'
          },
          content: [
            {
              type: 'text',
              text: item.title
            }
          ]
        },
        ...resultData,
        ...subContents(item, level + 1).flat()
      ];
    }) || [];

  const doc = {
    ...paper,
    name: paper.title,
    type: 'doc',
    content: [
      {
        type: 'reactComponent',
        attrs: {
          id: paper.id,
          level: 1,
          type: 'h1',
          className: 'paperTitle title',
          contenteditable: paper.status === 'completed',
          isRequired: true,
          errorMessage: 'Please enter the title of the document',
          title: paper.title
        },
        content: [
          {
            type: 'text',
            text: paper.title
          }
        ]
      },
      {
        type: 'reactComponent',
        attrs: {
          id: paper.id,
          enableDiagram: true,
          contenteditable: false
        }
      },
      ...content.flat()
    ].filter((item) => !!item)
  };

  const isNew = !papers.find((item) => item.id === doc.id);
  if (isNew) {
    papers = [doc, ...papers];
  } else {
    papers.forEach((item, i) => {
      if (item.id === doc.id) {
        papers[i] = { ...item, ...doc };
      }
    });
  }

  return {
    paper: doc,
    papers: convertToPapers(papers)
  };
}

const convertItemContentToDataString = (
  content: JSONContent[] = [],
  type?: string,
  itemIndex?: number
) => {
  return content.map((content: JSONContent, index: number) => {
    const { marks = [], text = '' } = content;
    if (marks[0]?.type === 'link' && !['(', ')', '; '].includes(text)) {
      const attrs = marks[0].attrs;
      const chunkIds = attrs?.['data-chunk_id']?.split(',') || [];
      const referenceContent = chunkIds.map((chunkId) => {
        return `${text.match('\\(')?.[0] || ''}BIB_ID:${attrs?.id}, CHUNK_ID:${chunkId}${text.match(';')?.[0] || ''}${text.match('\\)')?.[0] || ''}`;
      });
      return referenceContent.join('; ');
    }
    const inlineContent = content.content;
    if (!inlineContent || !inlineContent.length) {
      //Add support for ordered and bullet list
      if (type === 'orderedList') {
        return `${itemIndex}. ${text}\n`;
      }
      if (type === 'bulletList') {
        return `- ${text}\n`;
      }
      return text;
    }
    //Add support for nested inline items
    return inlineContent
      .map((inlineItem: JSONContent) => {
        const { content: itemContent = [] } = inlineItem;
        return convertItemContentToDataString(itemContent, type, ++index);
      })
      .join('');
  });
};

const convertToDataString = (data) => {
  return data
    .map((item: JSONContent) => {
      if (!item.content) return '';
      return convertItemContentToDataString(item.content, item.type).join('');
    })
    .join('\n');
};

const covertBiliographyList = (bibliographyList: any = []) => {
  return bibliographyList.map((item) => {
    const { metadata = {}, ...rest } = item;
    return {
      ...rest,
      ...metadata
    };
  });
};

export {
  convertToPapers,
  convertToPaper,
  convertToDataString,
  convertResultData,
  convertToResultWithLink,
  convertToBibliographies,
  covertBiliographyList,
  convertMarkdownToJson
};
