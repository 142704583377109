import React, { FC, useContext, useEffect, useState } from 'react';
import { store } from 'contexts/PageContextStore';
import { getPricingTableInfo } from 'services/payments';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

type IPricingTable = {
  pricingTableId?: string;
  publishableKey?: string;
};

interface PricingTableProps {}
const PricingTable: FC<PricingTableProps> = () => {
  const { storeState: { userInfo = {} } = {} } = useContext(store);
  const [pricingTable, setPricingTable] = useState<IPricingTable>({});

  useEffect(() => {
    const fetchPricingTable = async () => {
      const response = await getPricingTableInfo();
      setPricingTable({
        pricingTableId: response['pricing-table-id'],
        publishableKey: response['publishable-key']
      });
    };
    fetchPricingTable();
  }, []);

  const { pricingTableId, publishableKey } = pricingTable;

  if (!pricingTableId || !publishableKey) {
    return null;
  }

  return (
    <stripe-pricing-table
      pricing-table-id={pricingTableId}
      publishable-key={publishableKey}
      customer-email={userInfo.email}
    ></stripe-pricing-table>
  );
};

export default PricingTable;
