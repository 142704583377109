import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';
import classNames from 'classnames';
import { getLandingPageURL } from 'utils/router';

const useStyles = makeStyles(() => ({
  mobileReminder: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '167px 32px 0',
    textAlign: 'center'
  }
}));

interface MobileReminderProps {
  className?: string;
}

const MobileReminder: FC<MobileReminderProps> = ({ className }) => {
  const classes = useStyles();

  return (
    <Box className={classNames(classes.mobileReminder, className)}>
      <Typography fontWeight={700} fontSize={20} mb="12px" lineHeight="142%">
        Welcome to Science42: DORA!
      </Typography>
      <Typography fontSize={18} mb={8} lineHeight="142%">
        For the best experience, we recommend visiting us on a desktop.
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        sx={{ width: 181 }}
        onClick={() => window.open(getLandingPageURL())}
      >
        LEARN MORE
      </Button>
    </Box>
  );
};

export default MobileReminder;
