import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import InfoButton from 'components/InfoButton';
import SuggestedSource from './components/SuggestedSource';

const useStyles = makeStyles(() => ({
  websearchSettingsTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  }
}));

const webSearchSourceOptions = [
  {
    label: 'ArXiv'
  },
  {
    label: 'BioRxiv'
  },
  {
    label: 'ChemRxiv'
  },
  {
    label: 'Directory of Open Access Journals (DOAJ)'
  },
  {
    label: 'MedRxiv'
  },
  {
    label: 'ScienceDirect'
  },
  {
    label: 'Springer',
    subLabel: 'Nature, Journal of Cheminformatics'
  },
  {
    label: 'Researchgate'
  },
  {
    label: 'Wiley',
    subLabel: 'Journal of Molecular Information'
  }
];

interface WebsearchSettingsProps {
  title?: string;
  mode?: 'readonly' | 'normal';
}

const WebsearchSettings: FC<WebsearchSettingsProps> = ({
  title = 'Websearch settings',
  mode = 'readonly'
}) => {
  const classes = useStyles();
  const isReadOnly = mode === 'readonly';

  return (
    <Box
      gap={0.5}
      display="flex"
      flexDirection="column"
      p="8px 16px"
      maxWidth={392}
    >
      <Typography
        variant="h6"
        p="4px 0"
        fontSize={18}
        fontWeight={600}
        lineHeight="153%"
        classes={{ root: classes.websearchSettingsTitle }}
      >
        {title}
        <InfoButton
          sx={{ color: '#757575' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          popoverInfo={`Websearch conducts semantic searches across websites to efficiently retrieve relevant content from the internet.`}
        />
      </Typography>
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        p="6px 0"
      >
        <Stack p="4px 0">
          <Typography variant="subtitle1">Search web for content</Typography>
          <Typography variant="body2">
            When on, agents pull web info, expanding beyond DORA’s databases
          </Typography>
        </Stack>
        <Switch
          onChange={() => {}}
          checked={true}
          disabled={isReadOnly}
          color="default"
          sx={{
            '& .MuiSwitch-switchBase.Mui-disabled': {
              color: '#F5F5F5',
              '&+.MuiSwitch-track': {
                backgroundColor: '#E0E0E0',
                opacity: 1
              }
            }
          }}
        />
      </Stack>
      <Stack gap={1} color="#9E9E9E">
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2" lineHeight="145%">
            Source for web search
          </Typography>
          <Chip
            variant="outlined"
            label="Launching Soon 🚀"
            size="small"
            sx={{
              minWidth: 125,
              marginLeft: 'auto',
              fontSize: 12,
              borderColor: '#21965F',
              fontWeight: 400,
              letterSpacing: 0,
              lineHeight: '137%',
              '& .MuiChip-label': {
                px: 0.5
              }
            }}
          />
        </Stack>
        <FormGroup>
          {webSearchSourceOptions.map((option, index) => (
            <FormControlLabel
              key={index}
              control={<Checkbox sx={{ py: 0.25, pr: 0, mr: 2 }} />}
              label={
                <Stack py={0.5} maxWidth={264} color="#9E9E9E">
                  <Typography variant="body2">{option.label}</Typography>
                  {option.subLabel && (
                    <Typography variant="caption" lineHeight="137%">
                      {option.subLabel}
                    </Typography>
                  )}
                </Stack>
              }
              disabled={isReadOnly}
              sx={{ py: 0.25 }}
            />
          ))}
        </FormGroup>
      </Stack>
      <SuggestedSource />
    </Box>
  );
};

export default WebsearchSettings;
