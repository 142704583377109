export enum PageType {
  Login = 'Login Page',
  Signup = 'Sigup Page',
  Activate = 'Activate Page',
  Documents = 'Documents Page',
  Bibliography = 'Bibliography Page',
  DocumentDetails = 'Document Details Page'
}

export const RoutePatterns: { [key: string]: PageType } = {
  '^/login': PageType.Login,
  '^/signup': PageType.Signup,
  '^/accounts/register/activate': PageType.Activate,
  '^/documents/[a-f0-9-]+': PageType.DocumentDetails,
  '^/documents': PageType.Documents,
  '^/bibliography': PageType.Bibliography
};
