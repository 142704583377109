import React, { FC, useContext, memo, useEffect } from 'react';
import { store } from 'contexts/PageContextStore';
import { Stack } from '@mui/material';
import ReviewInsightsLoader from './components/ReviewInsightsLoader';
import OverallScore from './components/OverallScore';
import NoData from './components/NoData';
import ReviewCategory from './components/ReviewCategory';
import { generateDocumentReview, getDocumentReview } from 'services/documents';

import { IReviewCategory } from 'types/document';

const ReviewInsights: FC = () => {
  const {
    storeState: {
      paper,
      review_insights = {
        data: {},
        reviewInsightsIsLoading: true
      },
      isPaperLoading
    } = {},
    setPageData
  } = useContext(store);

  const isPolishing = paper.stage === 'polishing';
  const isDocumentInProgress = paper.status === 'in_progress';

  useEffect(() => {
    const fetchDocumentReview = async () => {
      setPageData({
        review_insights: {
          reviewInsightsIsLoading: true
        }
      });
      const response = await getDocumentReview(paper.id);
      setPageData({
        review_insights: {
          data: response,
          reviewInsightsIsLoading: false
        }
      });
    };

    fetchDocumentReview();

    return () => {
      setPageData({
        review_insights: {
          data: {},
          reviewInsightsIsLoading: false
        }
      });
    };
  }, [paper.id]);

  const {
    data: { overall_impression, categories } = {},
    reviewInsightsIsLoading = false
  } = review_insights;

  const maxScore = 10;

  const handleGenerateReview = async () => {
    setPageData({
      review_insights: {
        reviewInsightsIsLoading: true
      }
    });
    const reponse = await generateDocumentReview(paper.id);

    if (!reponse) {
      setPageData({
        review_insights: { reviewInsightsIsLoading: false }
      });
      return;
    }
  };

  const highchartsRadarData = () => {
    const xAxisData: string[] = [];
    const seriesData: number[] = [];
    categories?.forEach((category) => {
      xAxisData.push(category.title);
      seriesData.push(category.score);
    });
    xAxisData.push(overall_impression?.title);
    seriesData.push(overall_impression?.score);
    return {
      xAxisData,
      seriesData
    };
  };

  if (reviewInsightsIsLoading) {
    return <ReviewInsightsLoader />;
  }

  if (Object.keys(review_insights.data)?.length === 0) {
    return (
      <NoData
        generateReviewButtonIsDisabled={
          isPolishing || isDocumentInProgress || isPaperLoading
        }
        handleGenerateReview={handleGenerateReview}
      />
    );
  }

  return (
    <Stack sx={{ pb: 3 }}>
      <OverallScore
        score={overall_impression}
        maxScore={maxScore}
        highchartsRadarData={highchartsRadarData()}
      />
      {categories?.map((category: IReviewCategory) => (
        <ReviewCategory
          key={category.id}
          category={category}
          maxScore={maxScore}
        />
      ))}
    </Stack>
  );
};

export default memo(ReviewInsights);
