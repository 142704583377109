import Snackbar from 'utils/snackbar';

export function showErrorMessage(err) {
  Snackbar.error(
    err.response?.data?.detail || 'An error occurred. Please try again later.'
  );
}

export function showSuccessMessage(message, options?: any) {
  Snackbar.success(message || 'Success.', options);
}

export function showInfoMessage(message, options?: any) {
  Snackbar.info(message || 'Success.', options);
}
