import { HttpService as axios } from './HttpService';
import { showErrorMessage } from './messages';

const getTemplates = async () => {
  return await axios
    .get('/templates/')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return [];
    });
};

const generatePlan = async (id, params, abortController?: any) => {
  return await axios
    .post(`/templates/${id}/generate_plan/`, params, {
      signal: abortController?.signal
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err?.code === 'ERR_CANCELED') {
        return { canceled: true };
      }
      showErrorMessage(err);
      return false;
    });
};

const getAgents = async (id) => {
  return await axios
    .get(`/templates/${id}/agents/`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return false;
    });
};

const getOutputFormats = async () => {
  return await axios
    .get(`/templates/output_formats/`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return false;
    });
};

const createSuggestions = async (params) => {
  return await axios
    .post(`/templates/suggestions/`, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return {};
    });
};

export {
  getTemplates,
  generatePlan,
  getAgents,
  getOutputFormats,
  createSuggestions
};
