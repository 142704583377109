import { useEffect } from 'react';
import { getPageType } from 'utils/ga';

const useGoogleTagManager = () => {
  useEffect(() => {
    const existingScript = document.getElementById('google-tag-manager');
    if (!existingScript) {
      const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || '';
      // Enable debug mode for test, test2, and local environment
      const isEnableDebugMode = ['test', 'test2', 'local'].includes(
        process.env?.REACT_APP_ENVIRONMENT!
      );
      // Create the first script tag
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
      script.id = 'google-tag-manager';
      document.head.appendChild(script);

      // Get the page type from the pathname
      const pageType = getPageType();

      // Create the second script tag
      const scriptContent = document.createElement('script');
      scriptContent.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', '${GA_MEASUREMENT_ID}', { 'page_type': '${pageType}', 'debug_mode': ${isEnableDebugMode} });
      `;
      document.head.appendChild(scriptContent);
    }
  }, []);
};

export default useGoogleTagManager;
