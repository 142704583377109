import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InfoOutlined } from '@mui/icons-material';

import { IReadabilityMetrics } from 'types/document';
import MetricItem from '../MetricItem';

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  tooltipPopper: {
    width: 390
  }
}));

interface IReadabilityCategoryMetricsProps {
  metricsData: IReadabilityMetrics;
  title: string;
}

const ReadabilityCategoryMetrics = ({
  metricsData,
  title
}: IReadabilityCategoryMetricsProps) => {
  const classes = useStyles();

  const tooltipText =
    'The Flesch-Kincaid Grade Level indicates the education level needed to understand the text, based on sentence and word complexity. \n A score of 8 ensures general readability, while 12+ is suited for academic or professional audiences.';

  return (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="body2" fontWeight={500}>
          {title}
        </Typography>
        <Tooltip
          disableFocusListener={true}
          placement="bottom"
          title={tooltipText}
          classes={{ popper: classes.tooltipPopper }}
          PopperProps={{
            popperOptions: {
              modifiers: [
                {
                  name: 'offset',
                  options: { offset: [0, -8] }
                }
              ]
            }
          }}
        >
          <InfoOutlined sx={{ fontSize: 20 }} />
        </Tooltip>
      </div>
      <MetricItem
        label="Flesch Reading Ease"
        value={`${metricsData.flesch_kincaid_grade}`}
      />
    </>
  );
};

export default ReadabilityCategoryMetrics;
