import React, { FC, memo } from 'react';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  Typography
} from '@mui/material';
import withSettingsSectionHeader, {
  SettingsSectionHeaderProps
} from '../SettingSection';

const publicationDateOptions = [
  {
    value: 'all',
    label: 'All',
    subLabel: 'Since 2010'
  },
  {
    value: 'last_5_years',
    label: 'Last 5 years'
  },
  {
    value: 'last_10_years',
    label: 'Last 10 years'
  }
];

interface SettingsConfig {
  publication_date?: string;
  type?: string;
  article_types?: string[];
  sx?: any;
}

interface PublicationDateSectionProps extends SettingsSectionHeaderProps {
  settingsConfig: SettingsConfig;
  isReadOnly: boolean;
  handleUpdateSettings: (data: { publication_date: string }) => void;
}

const PublicationDateSection: FC<PublicationDateSectionProps> = (props) => {
  const { settingsConfig, handleUpdateSettings, isReadOnly } = props;
  return (
    <RadioGroup
      value={settingsConfig.publication_date}
      sx={{ width: '100%', ...settingsConfig?.sx }}
    >
      {publicationDateOptions.map((option, index) => (
        <FormControlLabel
          key={index}
          value={option.value}
          control={<Radio size="small" disabled={isReadOnly} />}
          label={
            <Stack p="5px 0">
              <Typography variant="body1">{option.label}</Typography>
              {option.subLabel && (
                <Typography color="text.secondary" variant="body2">
                  {option.subLabel}
                </Typography>
              )}
            </Stack>
          }
          onChange={() => {
            handleUpdateSettings({
              publication_date: option.value
            });
          }}
          sx={{ p: '5px 0', mr: 0 }}
        />
      ))}
    </RadioGroup>
  );
};

export default memo(withSettingsSectionHeader(PublicationDateSection));
