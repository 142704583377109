import { create } from 'zustand';

interface State {
  reloadBibliography: boolean;
  setReloadBibliography: (status: boolean) => void;
}

const useStore = create<State>()((set) => ({
  reloadBibliography: false,
  setReloadBibliography: (status) => set(() => ({ reloadBibliography: status }))
}));

export { useStore };
