import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';

import { ReactComponent as JournalArticleIcon } from 'assets/rightPanel/JournalArticle.svg';
import { ReactComponent as SystematicReviewIcon } from 'assets/rightPanel/SystematicReview.svg';
import withSettingsSectionHeader, {
  SettingsSectionHeaderProps
} from '../SettingSection';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  toggleButtonGroup: {
    display: 'flex',
    gap: 8
  },
  toggleButton: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '16px 10px',
    borderRadius: 8,
    border: '1px solid #E0E0E0',
    maxWidth: '50%',
    textTransform: 'none',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '145%',
    cursor: 'pointer',
    gap: 8,
    '&:hover': {
      borderColor: '#C4C4C4',
      backgroundColor: theme.palette.grey[50]
    },
    '&.Mui-selected': {
      color: theme.palette.text.primary,
      borderColor: '#9FDBBB',
      backgroundColor: '#E7F6EE',
      fontWeight: 500
    },
    '&.MuiToggleButtonGroup-firstButton': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8
    },
    '&.MuiToggleButtonGroup-lastButton': {
      marginLeft: 0,
      borderLeft: '1px solid #E0E0E0',
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      '&:hover': {
        borderColor: '#C4C4C4'
      },
      '&.MuiButtonBase-root.Mui-selected.MuiToggleButtonGroup-grouped': {
        borderLeft: '1px solid #9FDBBB',
        '&.Mui-disabled': {
          borderColor: '#C4C4C4'
        }
      },
      '&.Mui-disabled': {
        borderColor: '#E0E0E0'
      }
    },
    '&.Mui-selected.Mui-disabled': {
      borderColor: '#C4C4C4',
      backgroundColor: '#F2F2F2',
      color: theme.palette.text.secondary,
      '& svg': {
        fill: '#666666'
      }
    },
    '&.Mui-disabled': {
      '& svg': {
        height: 17.5
      }
    }
  }
}));

//Article types
export const articleTypes = [
  {
    icon: <JournalArticleIcon />,
    text: 'Research article and Journal article',
    value: 'Journal Article'
  },
  {
    icon: <SystematicReviewIcon />,
    text: 'Review and Systematic review',
    value: 'Review, Systematic Review'
  }
];

interface ArticleTypeSectionProps extends SettingsSectionHeaderProps {
  settingsConfig: any;
  settings: any;
  handleUpdateSettings: (data: { article_types: string[] }) => void;
}

const ArticleTypeSection: React.FC<ArticleTypeSectionProps> = (props) => {
  const classes = useStyles();
  const { isReadOnly, settingsConfig, settings, handleUpdateSettings } = props;

  const renderArticleType = (type) => {
    if (type === 'checkbox') {
      return (
        <FormGroup sx={{ gap: 1, ml: 1 }}>
          {articleTypes.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  size="small"
                  sx={{ p: 1 }}
                  checked={settingsConfig.article_types?.includes(option.value)}
                  onChange={() => {
                    const articleTypes = settingsConfig.article_types || [];
                    const selectedTypes = articleTypes.includes(option.value)
                      ? articleTypes.filter((type) => type !== option.value)
                      : [...articleTypes, option.value];
                    handleUpdateSettings({
                      article_types: selectedTypes
                    });
                  }}
                />
              }
              label={<Typography variant="body2">{option.text}</Typography>}
              disabled={isReadOnly}
            />
          ))}
        </FormGroup>
      );
    }

    return (
      <ToggleButtonGroup
        color="primary"
        value={settingsConfig.article_types}
        classes={{ root: classes.toggleButtonGroup }}
        onChange={(_e, value) => {
          handleUpdateSettings({
            article_types: value.filter(Boolean)
          });
        }}
        disabled={isReadOnly}
      >
        {articleTypes.map((type, index) => (
          <ToggleButton
            disableRipple
            key={index}
            value={type.value}
            classes={{ root: classes.toggleButton }}
          >
            {type.icon}
            {type.text}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  };
  return (
    <>
      {renderArticleType(settingsConfig.type)}
      {!settingsConfig.article_types?.length && (
        <Alert severity="info" color="info">
          {settings
            ? 'All types (over 50) used since no filter was set'
            : `If nothing is selected in this filter, all existing types (over 50)
        will be used.`}
        </Alert>
      )}
    </>
  );
};

export default memo(withSettingsSectionHeader(ArticleTypeSection));
