import { HttpService as axios } from './HttpService';
import type { AxiosRequestConfig } from 'axios';
import { showErrorMessage } from './messages';

const getDocuments = async (
  cursor: string = '',
  pageSize: number = 1000
): Promise<any> => {
  return await axios
    .get(
      `/documents/?${cursor ? `cursor=${cursor}&` : ''}page_size=${pageSize}`
    )
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return [];
    });
};

const getDocument = async (id, abortController?: any) => {
  return await axios
    .get(`/documents/${id}/`, { signal: abortController?.signal })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err?.code === 'ERR_CANCELED') {
        return null;
      }
      return null;
    });
};

const updateDocumentTitle = async (id, params) => {
  return await axios
    .patch(`/documents/${id}/`, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return {};
    });
};

const deleteDocument = async (id) => {
  return await axios
    .delete(`/documents/${id}`)
    .then(() => {
      return true;
    })
    .catch((err) => {
      showErrorMessage(err);
      return false;
    });
};

const generateDocuments = async (params) => {
  return await axios
    .post(`/documents/`, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return null;
    });
};

const generateDocument = async (id): Promise<any> => {
  return await axios
    .post(`/documents/${id}/generate/`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return {};
    });
};

const generateDocumentByAgent = async (params): Promise<any> => {
  return await axios
    .post(`/documents/generate/`, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return {};
    });
};

const createSubSection = async (params) => {
  return await axios
    .post(`/documents/sections/`, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return {};
    });
};

const exportDocument = async (params) => {
  return await axios
    .post(`/documents/export/`, params, { responseType: 'blob' })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return false;
    });
};

const generateSection = async (id) => {
  return await axios
    .post(`/documents/sections/${id}/generate/`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return {};
    });
};

const updateSection = async (id, params, config?: AxiosRequestConfig) => {
  return await axios
    .patch(`/documents/sections/${id}/`, params, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err?.code === 'ERR_CANCELED') {
        return 'abort';
      }
      showErrorMessage(err);
      return {};
    });
};

const getDocumentMetaData = async (id, abortController?: any) => {
  return await axios
    .get(`/documents/${id}/metadata/`, { signal: abortController?.signal })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err?.code === 'ERR_CANCELED') {
        return null;
      }
      return {};
    });
};

const findReferences = async (id, query, abortController?: any) => {
  return await axios
    .post(
      `documents/${id}/find_references/`,
      { query },
      { signal: abortController?.signal }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err?.code === 'ERR_CANCELED') {
        return;
      }
      showErrorMessage(err);
      return [];
    });
};

const findCitations = async (id, query, abortController?: any) => {
  return await axios
    .post(
      `documents/${id}/find_citations/`,
      { query },
      { signal: abortController?.signal }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err?.code === 'ERR_CANCELED') {
        return;
      }
      showErrorMessage(err);
      return [];
    });
};

const aiActions = async (query, abortController?: any) => {
  return await axios
    .post(
      `/documents/ai_actions/`,
      { ...query },
      { signal: abortController?.signal }
    )
    .then((res) => {
      return res.data?.result;
    })
    .catch((err) => {
      if (err?.code === 'ERR_CANCELED') {
        return;
      }
      showErrorMessage(err);
      return '';
    });
};

const polish = async (id, abortController?: any) => {
  return await axios
    .post(`/documents/${id}/polish/`, {
      signal: abortController?.signal
    })
    .then(() => {
      return true;
    })
    .catch((err) => {
      if (err?.code === 'ERR_CANCELED') {
        return;
      }
      showErrorMessage(err);
      return false;
    });
};

const applyRefined = async (id, params) => {
  return await axios
    .post(`/documents/sections/${id}/apply_refinement/`, params)
    .then(() => {
      return true;
    })
    .catch((err) => {
      if (err?.code === 'ERR_CANCELED') {
        return;
      }
      showErrorMessage(err);
      return false;
    });
};

const feedback = async (params) => {
  return await axios
    .post(`documents/feedback/`, params)
    .then(() => {
      return true;
    })
    .catch((err) => {
      showErrorMessage(err);
      return false;
    });
};

const updateBibliographies = async (params) => {
  return await axios
    .post(`/bibliographies/pubmed/`, params)
    .then((res) => {
      return res.data?.bibliographies;
    })
    .catch((err) => {
      showErrorMessage(err);
      return false;
    });
};

const generateMermaidDiagram = async (id, params) => {
  return await axios
    .post(`/documents/${id}/generate_mermaid_diagram/`, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return {};
    });
};

const generateDocumentReview = async (documentId) => {
  return await axios
    .post('ai_review/generate_document_review/', { document_id: documentId })
    .then(() => {
      return true;
    })
    .catch((err) => {
      showErrorMessage(err);
      return false;
    });
};

const removeDiagramFromDocument = async (id) => {
  return await axios
    .post(`/documents/${id}/unlink_mermaid_diagram/`)
    .then(() => {
      return true;
    })
    .catch((err) => {
      showErrorMessage(err);
      return false;
    });
};

const getDocumentReview = async (documentId) => {
  return await axios
    .get(`ai_review/document_review/?document_id=${documentId}`)
    .then((res) => {
      return res.data?.predefined_review;
    })
    .catch(() => {
      return {};
    });
};

export {
  getDocuments,
  generateDocuments,
  getDocument,
  generateDocument,
  generateDocumentByAgent,
  generateSection,
  createSubSection,
  updateSection,
  getDocumentMetaData,
  findReferences,
  findCitations,
  deleteDocument,
  updateDocumentTitle,
  aiActions,
  exportDocument,
  polish,
  feedback,
  applyRefined,
  updateBibliographies,
  generateMermaidDiagram,
  generateDocumentReview,
  getDocumentReview,
  removeDiagramFromDocument
};
