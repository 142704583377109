import React from 'react';
import { Typography } from '@mui/material';

const MetricItem = ({
  label,
  value,
  unit = ''
}: {
  label: string;
  value: number | string;
  unit?: string;
}) => (
  <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
    {label}:
    <Typography variant="body2" fontWeight={700} sx={{ ml: 0.5 }}>
      {value}
    </Typography>
    {unit && (
      <Typography variant="body2" fontWeight={700} sx={{ ml: 0.5 }}>
        {unit}
      </Typography>
    )}
  </Typography>
);

export default MetricItem;
