import axios, { AxiosInstance } from 'axios';
import { getCookie } from 'utils/cookie';

const HttpService: AxiosInstance = axios.create();

const CancelToken = axios.CancelToken;

const methodsNotRequiringCSRF = ['get', 'head', 'options', 'trace'];

export const requestFunc = (config: any) => {
  const csrfToken = getCookie('csrftoken');

  if (!methodsNotRequiringCSRF.includes(config.method!)) {
    config.headers!['X-CSRFToken'] = csrfToken!;
  }

  const { REACT_APP_API_URL, REACT_APP_API_VERSION } = process.env;
  config.baseURL = `${REACT_APP_API_URL}${REACT_APP_API_VERSION}`;

  return config;
};

//Request interceptor
HttpService.interceptors.request.use(
  (config) => requestFunc(config),
  (error) => {
    return Promise.reject(error);
  }
);

//Response interceptor
export const onRejected = async (error: any) => {
  // const errorStatus = error?.response?.status;
  // // const originalRequest = error.config;
  // switch (errorStatus) {
  //   case 401:
  //     redirect("/login");
  //     break;
  //   case 404:
  //     redirect("/404");
  //     break;
  //   default:
  //     break;
  // }
  return Promise.reject(error);
};

HttpService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => await onRejected(error)
);

// Export set up axios instance
export { HttpService, CancelToken };
