import React, { FC } from 'react';
import UserForm from './components/Form';
import InfoPanel from './components/InfoPanel';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'column',
    height: '100%',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.common.white,
      padding: '0 20px'
    }
  }
}));

interface UserProps {
  step?: number;
  isResetPassword?: boolean;
}

const User: FC<UserProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <UserForm {...props} />
      <InfoPanel />
    </div>
  );
};

export default User;
