import React, { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import InfoButton from 'components/InfoButton';

export interface SettingsSectionHeaderProps {
  title: string;
  className?: string;
  isReadOnly: boolean;
  popoverInfo: string | ReactNode;
}

const withSettingsSectionHeader = <P extends SettingsSectionHeaderProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: P) => {
    const { title, isReadOnly, popoverInfo } = props;
    return (
      <Stack gap={1}>
        <Typography
          variant="subtitle2"
          display="flex"
          gap={1}
          alignItems="center"
        >
          {title}
          <InfoButton isDisabled={isReadOnly} popoverInfo={popoverInfo} />
        </Typography>
        <WrappedComponent {...props} />
      </Stack>
    );
  };
};
export default withSettingsSectionHeader;
