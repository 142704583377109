import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Collapse,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Alert from '@mui/lab/Alert';
import { Skeleton } from '@mui/material';
import Tag from './Tag';
import HighlightedText from 'components/HighlightedText';
import { store } from 'contexts/PageContextStore';

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: 760,
    maxWidth: 760,
    height: 858,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  dialogTitle: {
    margin: 0,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '20px',
    color: theme.palette.text.primary,
    fontWeight: 500,
    letterSpacing: '0.15px'
  },
  dialogAction: {
    display: 'flex',
    padding: 24
  },
  dialogContent: {
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    color: theme.palette.text.primary,
    padding: 0
  },
  publicationContent: {
    padding: '0 24px'
  },
  publicationTitle: {
    padding: '16px 0',
    fontSize: 24,
    lineHeight: '33px',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '0.15px'
  },
  publicationDetail: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.02em'
  },
  publicationTab: {
    padding: '16px 0'
  },
  blue: {
    wordBreak: 'keep-all',
    padding: '2px 0',
    backgroundColor: '#BDD3EE'
  },
  green: {
    wordBreak: 'keep-all',
    padding: '2px 0',
    backgroundColor: theme.palette.charts?.positiveLight
  },
  dialogButton: {
    fontStyle: 'normal',
    color: '#01CE87',
    flex: 'none',
    fontSize: 14,
    order: 0,
    flexGrow: 0,
    padding: 8,
    fontWeight: 700,
    lineHeight: '145%',
    textTransform: 'uppercase',

    '& span.MuiButton-endIcon': {
      marginLeft: 7
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
    margin: '9px 0'
  },
  alertRoot: {
    padding: 0,
    height: 80,
    borderRadius: 0,
    fontSize: 16
  },
  alertMessage: {
    padding: '16px 0 0 24px'
  },
  standardSuccess: {
    backgroundColor: '#F2F2F2'
  },
  entered: {
    overflow: 'hidden'
  }
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle {...other}>
      <Typography className={classes.dialogTitle}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};
const DialogSkeleton = () => {
  return (
    <div style={{ padding: '4px 8px' }}>
      <Skeleton animation="wave" style={{ margin: 8, width: 728 }} />
      <Skeleton animation="wave" style={{ margin: 8, width: 608 }} />
      <Skeleton animation="wave" style={{ margin: 8, width: 296 }} />
      <Skeleton animation="wave" style={{ margin: 8, width: 232 }} />
    </div>
  );
};
const PublicationCriteria = () => {
  const styles = useStyles();
  return (
    <Collapse in={true} classes={{ entered: styles.entered }}>
      <Alert
        icon={false}
        variant="standard"
        classes={{
          root: styles.alertRoot,
          standardSuccess: styles.standardSuccess,
          message: styles.alertMessage
        }}
      >
        <Typography>
          <span className={styles.green}>Green highlight</span> is used for all
          primary entities (Gene and/or Disease).
        </Typography>
        <Typography>
          <span className={styles.blue}>Blue highlight</span> is used for the
          other entities.
        </Typography>
      </Alert>
    </Collapse>
  );
};
const PublicationTab = (props) => {
  const styles = useStyles();
  const { storeState: { paper: { publication_settings = {} } } = {} } =
    useContext(store);
  const {
    citations_total,
    pub_type,
    jrnl_title,
    authors,
    year,
    enableTypeFilter = false
  } = props;

  const pubTypes =
    enableTypeFilter && publication_settings.article_types.length
      ? pub_type.filter((type) =>
          publication_settings.article_types.includes(type)
        )
      : pub_type;

  return (
    <div className={styles.publicationTab}>
      <Tag name="Journal" content={jrnl_title} />
      <Tag name="Authors" content={authors} />
      <Tag name="Type" content={pubTypes} />
      <Tag
        name="Citations"
        content={String(1 * citations_total).replace(
          /(\d)(?=(?:\d{3})+$)/g,
          '$1 '
        )}
      />
      <Tag name="Year" content={year} />
    </div>
  );
};

const PublicationContent = (props) => {
  const styles = useStyles();
  const { title, abstract_text, highlight_entities = {} } = props;
  const titleParam = { text: title, highlight_entities, type: 'title' };
  const abstract = {
    text: abstract_text,
    highlight_entities,
    type: 'abstract'
  };

  const isHighlight = Object.keys(highlight_entities).length > 0;

  return (
    <div>
      {isHighlight && <PublicationCriteria />}
      <div className={styles.publicationContent}>
        {title ? (
          <Typography className={styles.publicationTitle} component="div">
            <HighlightedText {...titleParam} />
          </Typography>
        ) : null}
        {abstract_text ? (
          <Typography className={styles.publicationDetail} component="div">
            <HighlightedText {...abstract} />
          </Typography>
        ) : null}
        <PublicationTab {...props} />
      </div>
    </div>
  );
};

export default function PublicationDialog({
  openDialog = false,
  applId = null,
  enableTypeFilter = false,
  setOpenDialog = () => {},
  data
}) {
  const styles = useStyles();
  const [publicationData, setPublicationData] = useState(null);
  useEffect(() => {
    if (data !== undefined && openDialog) {
      setPublicationData(data);
    }
    return () => {
      setPublicationData(null);
    };
  }, [applId, openDialog, data]);
  const handleClose = () => {
    setOpenDialog(false);
  };
  const openUrl = (web_address) => {
    window.open(web_address, '_blank');
  };
  return (
    <Dialog
      fullWidth={true}
      open={openDialog}
      onClose={handleClose}
      classes={{ paperScrollPaper: styles.dialogWrapper }}
    >
      <DialogTitle onClose={handleClose}>Publication preview</DialogTitle>
      <DialogContent
        dividers
        className={styles.dialogContent}
        style={{ height: publicationData ? 745 : 423 }}
      >
        {publicationData ? (
          <PublicationContent
            {...publicationData}
            enableTypeFilter={enableTypeFilter}
          />
        ) : (
          <DialogSkeleton />
        )}
      </DialogContent>
      <DialogActions
        className={styles.dialogAction}
        style={{
          justifyContent: publicationData?.web_address
            ? 'space-between'
            : 'flex-end'
        }}
      >
        {publicationData?.web_address && (
          <Button
            onClick={() => openUrl(publicationData.web_address)}
            className={styles.dialogButton}
            endIcon={<OpenInNewIcon style={{ fontSize: 18 }} />}
          >
            PUBMED
          </Button>
        )}
        <Button onClick={handleClose} className={styles.dialogButton}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
