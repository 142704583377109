export const jiraServiceDesk = (callback) => {
  const jhdScript = document.createElement('script');
  jhdScript.type = 'text/javascript';
  jhdScript.setAttribute('data-jsd-embedded', '');
  jhdScript.setAttribute('data-key', 'f97f96c5-2231-496f-bb78-fbd8638f6b62');
  jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
  jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js';

  jhdScript.onload = () => {
    callback();
  };
  document.getElementsByTagName('head')[0].appendChild(jhdScript);
};

const initializeDOMContentLoaded = () => {
  const DOMContentLoadedEvent = new Event('DOMContentLoaded', {
    bubbles: true,
    cancelable: true
  });
  window.document.dispatchEvent(DOMContentLoadedEvent);
};

export const initialJiraServiceDesk = () => {
  jiraServiceDesk(() => {
    initializeDOMContentLoaded();
  });
};
