import React from 'react';
import { PageProvider } from './contexts/PageContextStore';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  styled
} from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import MainContainer from './components/MainContainer';
import { theme } from './theme';
import { SnackbarUtilsConfigurator } from 'utils/snackbar';
import { AssignmentLateRounded } from '@mui/icons-material';
import useGoogleTagManager from 'hooks/useGoogleTagManager';
import { useGATrackEvents } from 'hooks/useGATrackEvents';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-info': {
    maxHeight: 48,
    backgroundColor: '#616161',
    borderRadius: 8,
    padding: '6px 16px',
    boxShadow: 'none',
    '& #notistack-snackbar': {
      padding: 0
    }
  },
  '&.notistack-MuiContent-warning': {
    width: '50%',
    backgroundColor: '#FFEBC2',
    borderRadius: 8,
    padding: '8px 16px',
    boxShadow: 'none'
  }
}));

const useStyles = makeStyles(() => ({
  snackbarContainer: {
    width: '100%',
    borderRadius: 12,
    zIndex: 0,
    '& > div': {
      width: '100%'
    },
    '& .notistack-MuiContent': {
      position: 'relative',
      maxWidth: 903,
      minWidth: 255
    }
  },
  snackbar: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    top: -18,

    '& #notistack-snackbar': {
      width: '100%'
    }
  }
}));

const App = (props) => {
  const classes = useStyles();

  //Enable Google Analytics
  useGoogleTagManager();
  useGATrackEvents();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <PageProvider>
          <BrowserRouter>
            <SnackbarProvider
              Components={{
                info: StyledMaterialDesignContent,
                warning: StyledMaterialDesignContent
              }}
              iconVariant={{
                warning: <AssignmentLateRounded htmlColor="#E8A728" />
              }}
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              autoHideDuration={2000}
              classes={{
                containerRoot: classes.snackbarContainer,
                root: classes.snackbar
              }}
            >
              <SnackbarUtilsConfigurator />
              <MainContainer {...props} />
            </SnackbarProvider>
          </BrowserRouter>
        </PageProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
