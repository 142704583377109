import React from 'react';
import { Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const ReviewInsightsLoader = () => {
  return (
    <Stack
      display="flex"
      justifyContent="flex-start"
      alignItems="flex-start"
      height="100%"
      sx={{ mt: 2 }}
    >
      <Skeleton variant="rounded" height={12} width={120} sx={{ mb: 1.5 }} />
      <Skeleton variant="rounded" height={12} width={200} sx={{ mb: 1.5 }} />
      <Skeleton variant="rounded" height={12} width={200} />
    </Stack>
  );
};

export default ReviewInsightsLoader;
