import React, { FC, HTMLAttributes, ReactNode, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Popover, Stack, SxProps, Theme, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  infoButtonPopover: {
    maxWidth: 330,
    padding: '16px 24px',
    borderRadius: 8,
    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.08)'
  }
}));

interface InfoButtonProps extends HTMLAttributes<HTMLButtonElement> {
  sx?: SxProps<Theme>;
  buttonSize?: number;
  buttonText?: string | ReactNode;
  anchorOrigin?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  transformOrigin?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  popoverInfo?: string | ReactNode;
  isDisabled?: boolean;
}

const InfoButton: FC<InfoButtonProps> = ({
  sx,
  buttonSize = 16,
  buttonText = '',
  anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
  transformOrigin = { vertical: 'top', horizontal: 'center' },
  popoverInfo = null,
  isDisabled = false
}) => {
  const classes = useStyles();
  const [popoverEl, setPopoverEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event) => {
    setPopoverEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverEl(null);
  };

  const renderPopoverInfo = () =>
    typeof popoverInfo === 'string' ? (
      <Typography fontSize={14} lineHeight="145%" letterSpacing={0.15}>
        {popoverInfo}
      </Typography>
    ) : (
      popoverInfo
    );

  const isPopoverOpen = Boolean(popoverEl);
  return (
    <>
      <Stack
        gap={1.25}
        display="flex"
        flexDirection="row"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          color: 'text.secondary',
          ':hover': {
            color: 'common.black'
          },
          pointerEvents: isDisabled ? 'none' : 'all',
          ...sx
        }}
      >
        <InfoOutlined sx={{ fontSize: buttonSize }} />
        {buttonText}
      </Stack>
      {popoverInfo && (
        <Popover
          sx={{ pointerEvents: 'none' }}
          classes={{ paper: classes.infoButtonPopover }}
          open={isPopoverOpen}
          anchorEl={popoverEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {renderPopoverInfo()}
        </Popover>
      )}
    </>
  );
};

export default InfoButton;
