import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  tag: {
    padding: '2px 0'
  },
  tagName: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '19px',
    letterSpacing: '0.02em',
    paddingRight: 0
  },
  tagText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '19px',
    letterSpacing: '0.02em'
  }
}));

const Tag = ({ name, content }) => {
  let text = content;

  if (content && Array.isArray(content)) {
    //remove unvalid elements (empty, null or undefined)
    text = text.filter((item) => item || item === 0);
    text = text.join(', ');
  }

  const classes = useStyles();

  return (
    <div className={classes.tag}>
      <span data-testid="tag-tagName" className={classes.tagName}>
        {`${name}: `}
      </span>
      <span className={classes.tagText}>{text !== '' ? text : '—'}</span>
    </div>
  );
};

Tag.propTypes = {
  name: PropTypes.string
};

export default Tag;
