import React, { memo, MouseEvent, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Stack,
  CardHeader,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { templateIcons } from 'utils/templates';
import {
  AddRounded,
  AssignmentLateRounded,
  CheckCircleRounded,
  ErrorOutlineRounded
} from '@mui/icons-material';
import Menu from './components/Menu';
import { DocumentItem } from 'types/document';
import { convertToKey, formatDateWithNewRule } from 'utils/utils';
import { useNavigate } from 'react-router-dom';

const documentStatusIcon = {
  completed: () => <CheckCircleRounded htmlColor="#29A96D" />,
  limited: () => (
    <Tooltip title="You have reached your plan’s limit" placement="right" arrow>
      <AssignmentLateRounded htmlColor="#E8A728" sx={{ cursor: 'pointer' }} />
    </Tooltip>
  ),
  in_progress: () => <CircularProgress size={16} />,
  polishing: () => (
    <Stack alignItems="center" justifyContent="center" width={22} height={22}>
      <span
        style={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: '#9FDBBB'
        }}
      ></span>
    </Stack>
  ),
  failed: () => (
    <ErrorOutlineRounded htmlColor="#F44336" style={{ fontSize: 16 }} />
  )
};

interface UserDocumentProps extends Partial<DocumentItem> {}

const UserDocument = ({
  id = '',
  template_type = '',
  title = '',
  stage = 'section_generated',
  status = 'in_progress',
  created_at = '',
  isNew = false
}: UserDocumentProps) => {
  const nav = useNavigate();
  const [isActivated, setIsActivated] = useState(false);
  const isPolishing = stage === 'polishing';
  const isContentGenerating = stage === 'content_generating';
  const isCompleted = status === 'completed';
  const isFailed = status === 'failed';
  const PaperStatus =
    isPolishing && isCompleted
      ? documentStatusIcon[stage]
      : documentStatusIcon[status];

  const shouldShowStatus = isPolishing || isContentGenerating || isFailed;

  return (
    <>
      <Grid item sm={6} md={4} xl={3}>
        {isNew ? (
          <Stack
            onClick={() => nav('/templates')}
            direction="row"
            minWidth={160}
            height={168}
            justifyContent="center"
            bgcolor="#E5F6ED"
            borderRadius={6}
            boxShadow="none"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#C4E9D5'
              }
            }}
          >
            <Typography
              variant="body2"
              color="primary.main"
              fontWeight={500}
              display="flex"
              alignItems="center"
              gap={0.5}
            >
              <AddRounded fontSize="small" /> New Document
            </Typography>
          </Stack>
        ) : (
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              padding: '20px 24px',
              boxShadow: 'none',
              borderRadius: 6,
              cursor: 'pointer',
              height: 168,
              border: '1px solid',
              ...(isActivated
                ? {
                    backgroundColor: '#F2F2F2',
                    borderColor: '#F2F2F2'
                  }
                : {
                    backgroundColor: 'common.white',
                    borderColor: 'grey.50'
                  }),
              '& .documentIcon': {
                display: 'block',
                width: 24,
                height: 24,
                lineHeight: '24px',
                textAlign: 'center',
                borderRadius: '7px',
                fontSize: 14,
                backgroundColor: 'grey.50'
              },
              '& .menu': {
                zIndex: -1
              },
              '&:hover': {
                backgroundColor: '#F2F2F2',
                borderColor: '#F2F2F2',
                '& .menu': {
                  zIndex: 10
                }
              }
            }}
            onClick={(e) => {
              e.preventDefault();
              nav(`/documents/${id}`);
            }}
          >
            <CardHeader
              sx={{ p: 0 }}
              action={
                <div onClick={(e: MouseEvent) => e.stopPropagation()}>
                  <Menu
                    className="menu"
                    target={{ id, status, stage, title }}
                    hanleMenuClick={(activated) => setIsActivated(activated)}
                  />
                </div>
              }
              title={
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <span className="documentIcon">
                    {templateIcons[convertToKey(template_type)]?.icon}
                  </span>
                  <Typography
                    color="text.secondary"
                    variant="caption"
                    letterSpacing={0}
                  >
                    {template_type}
                  </Typography>
                </Stack>
              }
            />
            <CardContent sx={{ p: 0, mt: 1 }}>
              <Typography
                variant="body2"
                color="#000"
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                  overflow: 'hidden'
                }}
              >
                {title}
              </Typography>
            </CardContent>
            <Stack mt="auto" justifyContent="space-between" flexDirection="row">
              <Typography color="text.secondary" variant="caption">
                {formatDateWithNewRule(created_at)}
              </Typography>
              <span>{shouldShowStatus && PaperStatus && PaperStatus()}</span>
            </Stack>
          </Card>
        )}
      </Grid>
    </>
  );
};

export default memo(UserDocument);
