import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import BackgroundImg from 'assets/login/background.png';
import { getLandingPageURL } from 'utils/router';

const useStyles = makeStyles((theme) => ({
  pageInfoWrapper: {
    position: 'relative',
    width: '66.5%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${BackgroundImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    display: 'none',
    borderRadius: 16,

    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  pageInfoContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: 560,
    maxHeight: '432px',
    padding: '72px 40px',
    textAlign: 'center',
    borderRadius: 16,
    color: theme.palette.primary.main,
    border: '2px solid rgba(255, 255, 255, 0.50)',
    background: 'rgba(255, 255, 255, 0.65)',
    boxShadow: '0px 0px 32px 0px rgba(0, 0, 0, 0.04)',
    backdropFilter: 'blur(19px);'
  },
  pageInfoContentTitle: {
    padding: 0,
    margin: '0 auto',
    maxWidth: 440,
    fontWeight: 900,
    fontSize: 38,
    letterSpacing: '1.52px',
    lineHeight: '48px'
  },
  pageInfoContentText: {
    fontSize: 18,
    maxWidth: 385,
    margin: '12px  auto 0',
    lineHeight: '153%',
    letterSpacing: '0.15px'
  },
  pageInfoButton: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    fontSize: 16,
    width: 280,
    height: 56,
    textTransform: 'uppercase',
    marginTop: 40,
    borderRadius: 8,
    letterSpacing: '0.15px'
  },
  pageInfoCyberPanda: {
    display: 'none',
    position: 'absolute',
    top: 230,
    right: 50,
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  }
}));

interface InfoPanelProps {}

const InfoPanel: FC<InfoPanelProps> = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.pageInfoWrapper}>
        <div className={classes.pageInfoContentWrapper}>
          <h2 className={classes.pageInfoContentTitle}>
            Unlock the future of scientific writing
          </h2>
          <span className={classes.pageInfoContentText}>
            DORA is an advanced AI-driven tool designed to streamline the
            process of drafting academic papers and other related documents
          </span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={getLandingPageURL()}
            style={{ textDecoration: 'none' }}
          >
            <Button
              type="submit"
              variant="outlined"
              classes={{ root: classes.pageInfoButton }}
            >
              Learn more
            </Button>
          </a>
        </div>
      </div>
    </>
  );
};

export default InfoPanel;
