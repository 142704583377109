import React, { memo } from 'react';
import { Typography, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts';
import { useTheme } from '@mui/material/styles';

// Do we need to buy highcharts-react-official?
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);

const useStyles = makeStyles((theme) => ({
  overallScoreWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 24,
    marginBottom: 8,
    //@ts-ignore
    borderBottom: `1px solid ${theme.components.MuiAlert.styleOverrides.standardInfo.backgroundColor}`
  },
  overallScoreHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  scoreCountWrapper: {
    display: 'flex',
    padding: '4px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 48,
    //@ts-ignore
    border: `1px solid ${theme.components.MuiAlert.styleOverrides.standardInfo.backgroundColor}`
  }
}));

interface OverallScoreProps {
  score: {
    score: number;
    score_explanation: string;
  } | null;
  maxScore: number;
  highchartsRadarData: {
    xAxisData: string[];
    seriesData: number[];
  };
}

const OverallScore: React.FC<OverallScoreProps> = ({
  score,
  maxScore,
  highchartsRadarData
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const options = {
    chart: {
      polar: true,
      type: 'area',
      height: 260,
      spacingRight: 30,
      spacingLeft: 30
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    pane: {
      size: '80%'
    },
    xAxis: {
      categories: highchartsRadarData.xAxisData,
      tickmarkPlacement: 'on',
      lineWidth: 0
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      labels: {
        enabled: false // Hides the threshold numbers
      }
    },
    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return `<b>${this.x}</b>: ${this.y}`;
      }
    },
    series: [
      {
        data: highchartsRadarData.seriesData,
        pointPlacement: 'on',
        color: theme.palette.primary.main,
        fillColor: 'rgba(196, 233, 213, 0.5)',
        animation: {
          duration: 1500,
          easing: 'easeInOutQuad'
        }
      }
    ]
  };

  return (
    <div
      className={classes.overallScoreWrapper}
      data-testid="overallScore-wrapper"
    >
      <div className={classes.overallScoreHeaderWrapper}>
        <Typography variant="body2" fontSize={20}>
          Overall Score
        </Typography>
        <div
          className={classes.scoreCountWrapper}
          data-testid="overallScore-score"
        >
          <Typography
            fontWeight={700}
            fontSize={20}
            lineHeight={'142%'}
            color={
              score?.score
                ? theme.palette.text.primary
                : theme.palette.grey[500]
            }
          >
            {score?.score ? `${score.score}/${maxScore}` : '—'}
          </Typography>
        </div>
      </div>
      {score?.score ? (
        <>
          <Typography
            variant="body2"
            lineHeight={'145%'}
            sx={{ mt: 1 }}
            data-testid="overallScore-scoreExplanation"
          >
            {score?.score_explanation}
          </Typography>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            data-testid="overallScore-radarPlot"
          />
        </>
      ) : (
        <Alert
          severity="error"
          sx={{ mt: 2 }}
          data-testid="overallScore-errorAlert"
        >
          AI review generation failed. Please try again later.
        </Alert>
      )}
    </div>
  );
};

export default memo(OverallScore);
