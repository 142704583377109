import React, { FC, memo, ReactNode, useState } from 'react';
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  SxProps,
  Theme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RestartAltRounded } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: 577,
    borderRadius: 16
  },
  dialogTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '142%'
  },
  dialogContent: {
    overflow: 'unset',
    paddingBottom: 0
  },
  textField: {
    width: '100%'
  },
  inputTitle: {
    lineHeight: '150%'
  },
  dialogAction: {
    padding: 24,
    '& button': {
      maxHeight: 36,
      fontSize: 16,
      padding: '6px 20px',
      textTransform: 'unset'
    }
  }
}));

interface DialogProps {
  open: boolean;
  title?: string;
  Content?: ReactNode;
  Actions?: ReactNode;
  enableLeftBtn?: boolean;
  leftBtnText?: string;
  inputTagLabel?: string;
  classNames?: any;
  sx?: SxProps<Theme>;
  disableConfirmButton?: boolean;
  actionBtnTexts?: {
    confirm?: string;
    cancel?: string;
  };
  actionBtnTypes?: any;
  leftBtnAction?: () => void;
  handleClose: () => void;
  handleConfirm?: (_inputValue: string) => void;
  children?: ReactNode;
}

const Dialog: FC<DialogProps> = ({
  sx,
  classNames,
  open,
  title,
  Content,
  Actions,
  enableLeftBtn = false,
  leftBtnText,
  inputTagLabel,
  actionBtnTexts = { cancel: 'Cancel', confirm: 'Save' },
  actionBtnTypes = {},
  disableConfirmButton = false,
  handleClose = () => {},
  leftBtnAction = () => {},
  handleConfirm = () => {}
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<string>('');

  return (
    <MUIDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.paper, ...classNames }}
      sx={sx}
    >
      <DialogTitle className={classes.dialogTitle}>
        {title || 'PRMT5 and adenoid cystic carcinoma'}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        {Content || (
          <TextField
            autoFocus
            label={inputTagLabel || 'URL'}
            value={inputValue}
            InputProps={{
              classes: {
                root: classes.inputTitle
              }
            }}
            classes={{ root: classes.textField }}
            onChange={(e) => setInputValue(e.target?.value)}
            placeholder="Enter your URL..."
          />
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogAction }}>
        {enableLeftBtn && (
          <Button
            onClick={() => {
              leftBtnAction();
              handleClose();
            }}
            startIcon={<RestartAltRounded />}
            style={{ marginRight: 'auto' }}
          >
            {leftBtnText || 'regenerate evidences'}
          </Button>
        )}
        {Actions || (
          <>
            <Button
              onClick={handleClose}
              color={actionBtnTypes?.cancel || 'primary'}
            >
              {actionBtnTexts.cancel || 'Cancel'}
            </Button>
            {!disableConfirmButton && (
              <Button
                color={actionBtnTypes?.confirm || 'primary'}
                onClick={() => {
                  handleConfirm(inputValue);
                  handleClose();
                }}
                variant="contained"
                data-testid="dialog-confirm-button"
              >
                {actionBtnTexts.confirm}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </MUIDialog>
  );
};

export default memo(Dialog);
