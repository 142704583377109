import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  pageSkeleton: {
    height: '85%;',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '48px 15%'
  }
}));

const PageSkeleton = () => {
  const classes = useStyles();
  return (
    <Stack spacing={2} className={classes.pageSkeleton}>
      <Skeleton variant="text" height={'6%'} />
      <Skeleton variant="circular" width={80} height={80} />
      <Skeleton variant="rectangular" height={'13%'} />
      <Skeleton variant="rounded" height={'67%'} />
    </Stack>
  );
};

export default PageSkeleton;
