import React, { useContext } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import User from '../pages/User';
import Home from '../pages/Home/index';
import { store } from 'contexts/PageContextStore';
import { getLandingPageURL } from 'utils/router';

const ProtectedRoute = ({}) => {
  const location = useLocation();
  const { storeState: { userInfo = {} } = {} } = useContext(store);

  const isLogin = !!Object.keys(userInfo).length;
  const isTermsAndPrivacyAccepted = userInfo?.terms_and_privacy_accepted;
  const isAuthorized = isLogin && isTermsAndPrivacyAccepted;
  const rootPath = location.pathname.split('/')[1];
  const redirectedPath = !isAuthorized ? '/login' : '/main';
  const isMainPagePath = [
    'main',
    'documents',
    'templates',
    'bibliography'
  ].includes(rootPath);
  const pathname = location.pathname;
  // Redirect to login page if user is not authorized
  if (!isAuthorized) {
    if (isMainPagePath) {
      window.location.href = getLandingPageURL();
    }
    return <Navigate to={pathname} replace />;
  }
  // Redirect to main page if user is authorized
  return <Navigate to={isMainPagePath ? pathname : redirectedPath} replace />;
};

const Router = () => {
  return (
    <>
      <ProtectedRoute />
      <Routes>
        <Route path="/login" element={<User step={0} />} />
        <Route path="/signup" element={<User step={1} />} />
        <Route
          path="/terms-and-privacy"
          element={<Navigate to="/" replace />}
        />
        <Route
          path="/accounts/register/activate/:uid/:token"
          element={<User step={5} />}
        />
        <Route path="/accounts/register" element={<User step={2} />} />
        <Route path="/reset-password" element={<User step={3} />} />
        <Route
          path="/password-recovery/:uid/:token"
          element={<User step={5} isResetPassword={true} />}
        />
        <Route path="/main" element={<Home />}></Route>
        <Route path="/main/404" element={<Home isNotFound={true} />} />
        <Route path="/documents" element={<Home />}></Route>
        <Route path="/bibliography" element={<Home />}></Route>
        <Route
          path="/templates/"
          element={<Home loadTemplates={true} />}
        ></Route>
        <Route
          path="/documents/:id"
          element={<Home loadTemplates={true} loadingDoc={true} />}
        />
        <Route path="/" element={<Navigate to="/main" replace />}></Route>
        <Route path="/*" element={<Navigate to="/main" replace />} />
      </Routes>
    </>
  );
};
export default Router;
