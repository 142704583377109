import React, { ChangeEvent, FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';

const useStyles = makeStyles(() => ({
  exportPaper: {},

  exportPaperTitle: {
    margin: '0 0 16px'
  },
  radioGroup: {
    border: '1px solid #E0E0E0',
    borderRadius: 8
  },
  radioGroupItem: {
    padding: '2px 16px'
  }
}));

interface ExportPaperProps {
  handleValueChange?: (_value: string) => void;
}

const ExportPaper: FC<ExportPaperProps> = ({
  handleValueChange = () => {}
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('docx');

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
    handleValueChange(event.target.value);
  };

  return (
    <div className={classes.exportPaper}>
      <FormControl fullWidth>
        <p className={classes.exportPaperTitle}>Choose file format:</p>
        <RadioGroup
          classes={{ root: classes.radioGroup }}
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            classes={{ root: classes.radioGroupItem }}
            value="docx"
            control={<Radio />}
            label="DOCX"
          />
          <Divider />
          <FormControlLabel
            classes={{ root: classes.radioGroupItem }}
            value="pdf"
            control={<Radio />}
            label="PDF"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default ExportPaper;
