export function getCookie(name) {
  let cookieValue = '';
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function setCookie(cookieName, cookieValue, expiredDate) {
  document.cookie = `${cookieName}=${encodeURI(cookieValue)}; expires=${expiredDate.toGMTString()};`;
}

export const deleteCookiesByName = (deleteCookieName) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    if (deleteCookieName.trim() === name.trim()) {
      document.cookie =
        deleteCookieName + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;';
      document.cookie =
        deleteCookieName + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  }
};
