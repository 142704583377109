import React, { FC, useContext, useEffect, useState } from 'react';
import TextEvidencesChunkItem from '../components/TextEvidencesChunkItem';
import NoData from '../components/NoData';
import { store } from 'contexts/PageContextStore';
import { Checkbox, CircularProgress, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Metadata } from 'types/document';
import { getAllAbstractChunkTexts } from 'utils/editor';
import { removeDuplicatesByPmid } from 'utils/utils';

const useStyles = makeStyles(() => ({
  checkbox: {
    paddingLeft: 0
  }
}));

interface ITextEvidenceProps {
  showWithLink?: boolean;
  enableCheckbox?: boolean;
  handleAction?: (_target) => void;
}

const TextEvidences: FC<ITextEvidenceProps> = ({
  showWithLink = false,
  enableCheckbox = false,
  handleAction = () => {}
}) => {
  const classes = useStyles();
  const {
    storeState: {
      paper: { bibliographies = [] } = {},
      textEvidences = [],
      referenceLinkTarget = {},
      isPaperLoading = false
    } = {},
    setPageData
  } = useContext(store);
  const [loading, setLoading] = useState(false);
  const [checkedList, setCheckedList] = useState<any[]>([]);

  const handleCheck = (reference) => {
    let references = [...checkedList];
    const target = references.find(
      (item) => item.metadata?.pubmed_id === reference.metadata?.pubmed_id
    );
    if (target) {
      references = references.filter(
        (item) => item.metadata?.pubmed_id !== reference.metadata?.pubmed_id
      );
    } else {
      references.push({ ...reference, type: 'pubmed', isNew: true });
    }
    setCheckedList(references);
  };

  const textEvidenceList = showWithLink
    ? referenceLinkTarget.pmid
      ? removeDuplicatesByPmid(
          [...textEvidences, ...bibliographies].filter((item) =>
            [item.id, item.metadata?.pubmed_id?.toString()].includes(
              referenceLinkTarget?.pmid?.toString()
            )
          )
        )
      : []
    : textEvidences?.filter((item) => !item.id);

  useEffect(() => {
    handleAction && handleAction(checkedList);
    setLoading(false);
  }, [checkedList]);

  useEffect(() => {
    if (!isPaperLoading) return;
    setPageData({ referenceLinkTarget: {} });
  }, [isPaperLoading]);

  if (loading) {
    return (
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={24} />
      </Stack>
    );
  }

  if (!textEvidenceList?.length) {
    return <NoData />;
  }

  return (
    <>
      {textEvidenceList.map((item: Metadata) => {
        const {
          type = 'pubmed',
          metadata: {
            pubmed_id = '',
            title = '',
            citation_count = 2,
            journal_name = '',
            pub_type = [],
            text = '',
            pub_year = 2022,
            authors = [],
            file_name = '',
            url = ''
          } = {},
          chunks = {}
        } = item || {};

        return (
          <Stack
            key={pubmed_id}
            display="flex"
            flexDirection={type === 'pubmed' ? 'row' : 'column'}
            alignItems="flex-start"
          >
            {enableCheckbox && (
              <Checkbox
                size="small"
                classes={{ root: classes.checkbox }}
                tabIndex={-1}
                disableRipple
                checked={checkedList
                  ?.map((item) => item.metadata?.pubmed_id)
                  .includes(pubmed_id)}
                onChange={() => handleCheck(item)}
              />
            )}

            <TextEvidencesChunkItem
              type={type}
              isCustomLink={['file', 'websearch'].includes(type)}
              fileName={file_name}
              url={url}
              title={title}
              chunks={
                showWithLink
                  ? getAllAbstractChunkTexts(
                      chunks,
                      referenceLinkTarget?.chunkid
                    )
                  : Object.values(chunks)
              }
              citationsCount={citation_count}
              journal={journal_name}
              pubType={pub_type || []}
              pmid={pubmed_id}
              abstractText={text}
              year={pub_year}
              authors={authors}
              showWithLink={showWithLink}
            />
          </Stack>
        );
      })}
    </>
  );
};

export default TextEvidences;
