import React, { FC, createRef, useContext, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CitationSettings from './CitationSettings';
import Bibliography from './Bibliography';
import TextEvidences from './TextEvidences';
import ReviewInsights from './ReviewInsights';
import { ExpandMoreRounded } from '@mui/icons-material';
import { store } from 'contexts/PageContextStore';
import classNames from 'classnames';
import { CircularProgress, Box } from '@mui/material';
import InfoButton from 'components/InfoButton';
import WebsearchSettings from './WebsearchSettings';

const useStyles = makeStyles((theme) => ({
  rightPanel: {
    display: 'flex',
    flexDirection: 'column',
    width: '24%',
    height: '100%',
    maxHeight: '100%',
    padding: 8,
    backgroundColor: theme.palette.common.white,
    borderRadius: 16,
    maxWidth: 442,
    gap: 8,
    overflow: 'hidden'
  },
  citationSettingsWapper: {
    width: '22%',
    maxWidth: 408,
    minWidth: 408,
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'none'
  },
  disabledAccordion: {
    backgroundColor: 'transparent !important'
  },
  rightPanelAccordion: {
    maxHeight: '100%',
    boxShadow: 'none',

    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&.Mui-expanded': {
      display: 'flex',
      flex: '1 0 auto',
      flexDirection: 'column',
      margin: 0,
      maxHeight: 'calc(100% - 96px)',
      '& .MuiCollapse-entered, & .MuiCollapse-wrapper, & .MuiAccordion-region':
        {
          height: '100%!important',
          overflowX: 'hidden',
          overflowY: 'auto',
          scrollbarWidth: 'none'
        }
    },
    '&::before': {
      display: 'none'
    }
  },
  rightPanelAccordionWithHiddenTextEvidence: {
    maxHeight: '100%',
    boxShadow: 'none',

    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&.Mui-expanded': {
      display: 'flex',
      flex: '1 0 auto',
      flexDirection: 'column',
      margin: 0,
      maxHeight: 'calc(100% - 48px)',
      '& .MuiCollapse-entered, & .MuiCollapse-wrapper, & .MuiAccordion-region':
        {
          height: '100%!important',
          overflowX: 'hidden',
          overflowY: 'auto',
          scrollbarWidth: 'none'
        }
    },
    '&::before': {
      display: 'none'
    }
  },
  rightPanelAccordionSummary: {
    padding: '0 16px',
    flexDirection: 'row-reverse',
    borderRadius: '12px',
    maxHeight: '48px!important',
    minHeight: '48px!important',
    '&.Mui-expanded': {
      backgroundColor: theme.palette.grey[50],
      padding: '8px 16px'
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      padding: '8px 16px'
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)'
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1)
    }
  },
  rightPanelAccordionDetails: {
    height: '100%',
    padding: theme.spacing(2)
  },
  displayNone: {
    display: 'none'
  }
}));

interface RightPanelProps {}

const RightPanel: FC<RightPanelProps> = () => {
  const classes = useStyles();
  const accordionDetailsRef = createRef<any>();
  const {
    storeState: {
      createPaperViewData: { id: templateId = '' } = {},
      paper = {},
      referenceLinkTarget = {},
      rightPanel: { activedComponentId = 'bibliography' } = {},
      isPaperLoading = true
    } = {},
    setPageData
  } = useContext(store);

  const textEvidenceIsHidden = Object.keys(referenceLinkTarget).length === 0;

  const accordions = [
    {
      title: 'Bibliography',
      Content: <Bibliography />
    },
    {
      title: 'Text Evidence',
      Content: <TextEvidences showWithLink={true} />,
      isHidden: textEvidenceIsHidden
    },
    {
      title: 'Review Insights',
      Content: <ReviewInsights />
    }
  ];

  const handleChange = (activedId: string) => {
    setPageData({
      rightPanel: {
        activedComponentId: activedComponentId === activedId ? '' : activedId
      }
    });
  };

  useEffect(() => {
    if (!paper.id) return;
    const rightPanelAccordions = document.getElementById(
      'rightPanelAccordions'
    );
    const handleSwitchSelectableElements = (isEnable = false) => {
      if (!rightPanelAccordions) return;
      rightPanelAccordions?.setAttribute(
        'style',
        `user-select: ${!isEnable ? 'none' : 'text'}`
      );
    };
    rightPanelAccordions?.addEventListener('mousedown', () =>
      handleSwitchSelectableElements(true)
    );
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
        event.preventDefault();
        handleSwitchSelectableElements();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      rightPanelAccordions?.removeEventListener('mousedown', () =>
        handleSwitchSelectableElements(true)
      );
      document.removeEventListener('keydown', handleKeyDown);
      setPageData({ rightPanel: { activedComponentId: 'bibliography' } });
    };
  }, [paper.id]);

  const isEnableCitionSettings =
    ['section_generated', 'plan_generating', 'plan_generated'].includes(
      paper.stage
    ) || !paper.id;

  if (isPaperLoading && templateId) {
    return (
      <Box
        className={classNames(
          classes.rightPanel,
          classes.citationSettingsWapper
        )}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={24} />
      </Box>
    );
  }

  if (!templateId && !paper.id) {
    return <Box></Box>;
  }

  if (isEnableCitionSettings) {
    return (
      <Box
        className={classNames(
          classes.rightPanel,
          classes.citationSettingsWapper
        )}
      >
        <CitationSettings />
        <WebsearchSettings />
      </Box>
    );
  }

  return (
    <Box className={classNames(classes.rightPanel)}>
      <Box
        id="rightPanelAccordions"
        sx={{ height: 'calc(100% - 44px)' }}
        display="flex"
        flexDirection="column"
        overflow="hidden"
      >
        {accordions.map((accordion: any, index) => {
          const { Content = null, title, isHidden = false } = accordion;
          const id = title?.replaceAll(' ', '').toLowerCase();
          return (
            <Accordion
              key={index}
              expanded={activedComponentId === id}
              onChange={() => handleChange(id)}
              className={classNames({
                [classes.displayNone]: isHidden
              })}
              classes={{
                root: textEvidenceIsHidden
                  ? classes.rightPanelAccordionWithHiddenTextEvidence
                  : classes.rightPanelAccordion,
                disabled: classes.disabledAccordion
              }}
            >
              <AccordionSummary
                classes={{
                  root: classes.rightPanelAccordionSummary
                }}
                expandIcon={<ExpandMoreRounded />}
              >
                <Typography>{title}</Typography>
              </AccordionSummary>
              {Content && (
                <AccordionDetails
                  ref={accordionDetailsRef}
                  classes={{ root: classes.rightPanelAccordionDetails }}
                  key={paper.id}
                >
                  {Content}
                </AccordionDetails>
              )}
            </Accordion>
          );
        })}
      </Box>
      <InfoButton
        buttonText="Citation Settings"
        buttonSize={20}
        sx={{ marginTop: 'auto', padding: '12px 16px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        popoverInfo={
          <CitationSettings
            title="Settings applied for generation"
            mode="readonly"
            settings={paper.publication_settings}
          />
        }
      />
    </Box>
  );
};

export default RightPanel;
