import React from 'react';
import { makeStyles } from '@mui/styles';
import ExportSuccessfully from 'assets/editor/export_successfully.svg';

const useStyles = makeStyles(() => ({
  exportPaper: {
    marginTop: 2,
    '& p': {
      margin: 0
    }
  },
  img: {
    display: 'block',
    margin: '34px auto 18px'
  }
}));

const ExportPaperSuccessfully = () => {
  const classes = useStyles();

  return (
    <div className={classes.exportPaper}>
      <p>Your paper has been exported successfully.</p>
      <img src={ExportSuccessfully} className={classes.img} />
    </div>
  );
};

export default ExportPaperSuccessfully;
