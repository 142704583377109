import { create } from 'zustand';

interface CompletedDocument {
  id: string;
  stage: string;
  status: string;
  title: string;
}

// Document state
interface DocumentState {
  completedDocument: CompletedDocument | null;
  setCompletedDocument: (document: CompletedDocument) => void;
  clearCompletedDocument: () => void;
  updateDocumentStage: (stage: string) => void;
  updateDocumentStatus: (status: string) => void;
}

// Deleted document state
interface DeletedDocumentState {
  deletedDocumentId: string;
  setDeletedDocumentId: (id: string) => void;
  clearDeletedDocumentId: () => void;
}

// Create Document state store
const useDocumentStore = create<DocumentState>((set) => ({
  completedDocument: null,
  setCompletedDocument: (document: CompletedDocument) =>
    set(() => ({ completedDocument: document })),
  clearCompletedDocument: () => set(() => ({ completedDocument: null })),
  updateDocumentStage: (stage: string) =>
    set((state) => ({
      completedDocument: state.completedDocument
        ? { ...state.completedDocument, stage }
        : null
    })),
  updateDocumentStatus: (status: string) =>
    set((state) => ({
      completedDocument: state.completedDocument
        ? { ...state.completedDocument, status }
        : null
    }))
}));

// DeletedDocument state store
const useDeletedDocumentStore = create<DeletedDocumentState>((set) => ({
  deletedDocumentId: '',
  setDeletedDocumentId: (id: string) => set(() => ({ deletedDocumentId: id })),
  clearDeletedDocumentId: () => set(() => ({ deletedDocumentId: '' }))
}));

export { useDocumentStore, useDeletedDocumentStore };
