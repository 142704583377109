import React, { FC, createRef, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { HelpOutlineRounded } from '@mui/icons-material';
import Papers, { PapersRef } from './Papers';
import { store } from 'contexts/PageContextStore';
import { Stack, Typography } from '@mui/material';
import classNames from 'classnames';
import UserPlan from './components/UserPlan';
import { theme } from 'theme';

const useStyles = makeStyles((theme) => ({
  leftPanelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '24%',
    height: '100%',
    padding: 24,
    backgroundColor: theme.palette.common.white,
    borderRadius: 16,
    maxWidth: 442,
    zIndex: 1
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 400,
    height: 40,
    color: theme.palette.text.primary,
    lineHeight: '150%',
    letterSpacing: '0.15px',
    padding: '10px 16px',
    marginBottom: 8,
    borderRadius: 12,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    '&.active': {
      background: '#F5F5F5',
      '& p, & svg': {
        color: theme.palette.text.primary
      }
    }
  },
  titleText: {
    marginLeft: 8
  },
  characterCount: {}
}));

interface LeftPanelProps {}

const LeftPanel: FC<LeftPanelProps> = () => {
  const classes = useStyles();
  const {
    storeState: { activedEditorPanelStep = 1, isPaperLoading = false } = {}
  } = useContext(store);

  const papersRef = createRef<PapersRef>();

  return (
    <div className={classes.leftPanelWrapper}>
      <Papers ref={papersRef} />
      <Stack
        display="flex"
        alignItems="center"
        flexDirection="row"
        p="10px 16px"
        className={classNames(
          classes.title,
          !activedEditorPanelStep && !isPaperLoading && 'active'
        )}
        onClick={() => {
          papersRef?.current?.reset({ createPaperViewData: {} });
        }}
      >
        <HelpOutlineRounded htmlColor="#757575" style={{ fontSize: 18 }} />
        <Typography
          color={theme.palette.text.secondary}
          sx={{ ml: 1 }}
          fontSize={14}
        >
          How to create a document?
        </Typography>
      </Stack>
      <UserPlan />
    </div>
  );
};

export default LeftPanel;
