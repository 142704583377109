import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import PublicationDialog from '../PublicationDialog';

const useStyles = makeStyles((theme) => ({
  citations: {
    color: theme.palette.text.secondary,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '145%',
    letterSpacing: '0.15px'
  },
  citations_pmid: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '145%',
    letterSpacing: '0.15px',
    color: '#006F35',
    cursor: 'pointer'
  }
}));

interface ICitationsProps {
  title: string;
  citations_total: number;
  pub_type: string | string[];
  authors: string[];
  jrnl_title: string;
  pm_id: string;
  abstract_text: string;
  web_address: string;
  year: number;
  enableTypeFilter?: boolean;
}

const Citations = ({
  title,
  citations_total,
  pub_type,
  authors,
  jrnl_title,
  pm_id,
  abstract_text,
  web_address,
  year,
  enableTypeFilter = false
}: ICitationsProps) => {
  const classes = useStyles();

  const [dialogData, setDialogData] = useState<any | undefined>(undefined);
  const [openDialog, setOpenDialog] = useState<true | false>(false);

  const handleDialog = async (id?: string) => {
    if (!id) {
      setDialogData('');
      setOpenDialog(false);
      setDialogData(undefined);
    } else {
      setOpenDialog(true);
      setDialogData({
        title,
        citations_total,
        pub_type,
        authors,
        jrnl_title,
        pm_id,
        abstract_text,
        web_address,
        year
      });
    }
  };

  return (
    <>
      <span className={classes.citations} data-testid="citations">
        Citations: {citations_total ?? 'no information'} | PMID:{' '}
        <span
          data-ga-tracking
          data-ga-event-type="Publication preview"
          data-ga-event-location="modal"
          onClick={() => {
            handleDialog(pm_id);
          }}
          className={classes.citations_pmid}
        >
          {pm_id}
        </span>
      </span>

      <PublicationDialog
        openDialog={openDialog}
        setOpenDialog={handleDialog}
        data={dialogData}
        enableTypeFilter={enableTypeFilter}
      />
    </>
  );
};

export default Citations;
