import React, { useState, MouseEvent, memo, useEffect } from 'react';
import { Box, Menu as MUIMenu, Typography } from '@mui/material';
import { FC } from 'react';
import {
  Button,
  DialogContentText,
  IconButton,
  MenuItem,
  Tooltip
} from '@mui/material';
import {
  MoreHorizRounded,
  SaveAltRounded,
  DeleteForeverRounded
} from '@mui/icons-material';
import ExportPaperSuccessfully from 'pages/Home/components/LeftPanel/components/ExportPaper/Success';
import { deleteDocument, exportDocument } from 'services/documents';
import Dialog from 'components/Dialog';
import Snackbar from 'utils/snackbar';
import { debounce } from 'lodash';
import { useDeletedDocumentStore } from 'contexts/documentsStore';

interface MenuProps {
  target: any;
  className?: string;
  hanleMenuClick?: (isOpen: boolean) => void;
}

const Menu: FC<MenuProps> = ({
  target: { id: itemId, status = '', stage = '', title = '' } = {},
  className = '',
  hanleMenuClick = () => {}
}) => {
  const setDeletedDocumentId = useDeletedDocumentStore(
    (state) => state.setDeletedDocumentId
  );
  const [isExportSuccessfullyDialogOpen, setIsExportSuccessfullyDialogOpen] =
    useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleExportPaper = async (exportTypeValue = 'docx') => {
    const response = await exportDocument({
      document_id: itemId,
      format: exportTypeValue
    });
    if (!response) return;
    const blob = new Blob([response], {
      type:
        exportTypeValue === 'pdf'
          ? 'application/pdf'
          : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    });
    const fileURL = window.URL.createObjectURL(blob);
    const alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = `${title}.${exportTypeValue}`;
    alink.click();
    setIsExportSuccessfullyDialogOpen(true);
  };

  const handleDeleteDocument = async () => {
    const response = await deleteDocument(itemId);
    if (!response) return;
    setDeletedDocumentId(itemId);
    Snackbar.info(
      <Typography
        variant="body2"
        lineHeight="145%"
        p="8px 0"
        letterSpacing={0.15}
      >
        Document deleted permanently
      </Typography>
    );
  };

  const menuItems = [
    {
      icon: <SaveAltRounded fontSize="small" />,
      text: 'Export PDF',
      tooltipTitle: (
        <label>
          Export is not available because the <br />
          document contains unapplied changes.
        </label>
      ),
      isHidden: status !== 'completed',
      disabled: stage === 'polishing',
      handleClick: debounce(() => handleExportPaper('pdf'), 1000)
    },
    {
      icon: <SaveAltRounded fontSize="small" />,
      text: 'Export DOCX',
      tooltipTitle: (
        <label>
          Export is not available because the <br />
          document contains unapplied changes.
        </label>
      ),

      isHidden: status !== 'completed',
      disabled: stage === 'polishing',
      handleClick: debounce(handleExportPaper, 1000)
    },
    {
      icon: <DeleteForeverRounded fontSize="small" className="deleteIcon" />,
      text: 'Delete',
      handleClick: async () => setIsDeleteDialogOpen(true)
    }
  ];

  useEffect(() => {
    if (!open) return;
    hanleMenuClick(open);
  }, [open]);

  return (
    <>
      <IconButton
        onClick={handleOpenMenu}
        className={className}
        style={{
          padding: 3,
          ...(open && { zIndex: 1, color: '#21965F' })
        }}
      >
        <MoreHorizRounded />
      </IconButton>
      <MUIMenu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={() => {
          setAnchorEl(null);
          hanleMenuClick(false);
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 184,
            padding: '4px 0',
            borderRadius: 1,
            boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.08)'
          }
        }}
      >
        {menuItems.map((item, index) => {
          const {
            text,
            handleClick = () => {},
            icon,
            tooltipTitle = '',
            isHidden = false,
            disabled = false
          } = item;
          if (isHidden) return null;
          return (
            <div key={index}>
              <Tooltip title={disabled ? tooltipTitle : ''} placement="top">
                <span>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClick();
                      setAnchorEl(null);
                      hanleMenuClick(false);
                    }}
                    disabled={disabled}
                    sx={{
                      display: 'flex',
                      padding: '6px 16px',
                      '& .MuiSvgIcon-root': {
                        marginLeft: 'auto',
                        color: 'text.secondary'
                      },
                      '& .Mui-disabled': {
                        cursor: 'default',
                        color: 'grey.400',
                        '& .MuiSvgIcon-root': {
                          fill: 'grey.400'
                        }
                      },
                      '&:hover': {
                        '& .deleteIcon': {
                          color: '#E31B0C'
                        }
                      }
                    }}
                  >
                    <Typography variant="body2" p="4px 0">
                      {text}
                    </Typography>
                    {icon}
                  </MenuItem>
                </span>
              </Tooltip>
            </div>
          );
        })}
      </MUIMenu>
      <Dialog
        open={isDeleteDialogOpen}
        title="Delete Document?"
        Content={
          <Box maxWidth={529}>
            <Typography mb={2}>
              <span style={{ fontWeight: 500 }}>{title}</span> will be
              permanently deleted.
            </Typography>
            <DialogContentText color="text.primary">
              This action cannot be undone.
            </DialogContentText>
          </Box>
        }
        handleClose={() => setIsDeleteDialogOpen(false)}
        actionBtnTexts={{ confirm: 'Delete' }}
        handleConfirm={handleDeleteDocument}
      />

      <Dialog
        open={isExportSuccessfullyDialogOpen}
        handleClose={() => setIsExportSuccessfullyDialogOpen(false)}
        title="Successfully exported!"
        Content={<ExportPaperSuccessfully />}
        Actions={
          <Button
            onClick={() => setIsExportSuccessfullyDialogOpen(false)}
            variant="contained"
            size="small"
          >
            ok
          </Button>
        }
      />
    </>
  );
};
export default memo(Menu);
