import React, { FC, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import LeftPanel from './components/LeftPanel';
import EditorPanel from './components/EditorPanel';
import RightPanel from './components/RightPanel';
import { getDocument, getDocuments } from 'services/documents';
import { store } from 'contexts/PageContextStore';
import { convertToPaper } from 'utils/document';
import { initialJiraServiceDesk } from 'utils/initialJiraServiceDesk';
import Snackbar, { getSnackbarInstance } from 'utils/snackbar';
import { Button, IconButton, Stack } from '@mui/material';
import { closeSnackbar } from 'notistack';
import { CloseRounded } from '@mui/icons-material';
import NotFound from 'components/404';
import { useNavigate } from 'react-router-dom';
import HomePage from './home';
import { useDocumentStore } from 'contexts/documentsStore';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    height: 'calc(100vh - 96px)',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

interface HomeProps {
  loadingDoc?: boolean;
  loadTemplates?: boolean;
  isNotFound?: boolean;
}

const Home: FC<HomeProps> = ({
  loadingDoc = false,
  loadTemplates = false,
  isNotFound = false
}) => {
  const classes = useStyles();
  const nav = useNavigate();
  const [client, setClient] = useState<W3CWebSocket | null>(null);
  const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const { setPageData } = useContext(store);

  const setCompletedDocument = useDocumentStore(
    (state) => state.setCompletedDocument
  );

  useEffect(() => {
    initialJiraServiceDesk();
  }, []);

  useEffect(() => {
    if (loadingDoc) return;
    setPageData({ isPaperLoading: false });
  }, [loadingDoc]);

  useEffect(() => {
    if (!client) {
      const wsPort = process.env.REACT_APP_WS_PORT;
      const newClient =
        process.env.NODE_ENV === 'production'
          ? new W3CWebSocket(
              `${protocol}://${document.location.hostname}/ws/notifications/`
            )
          : new W3CWebSocket(
              `${protocol}://${document.location.hostname}:${wsPort}/ws/notifications/`
            );
      setClient(newClient);
      return;
    }
    client.onopen = () => {};
    client.onmessage = (message: any) => {
      const response = JSON.parse(message.data);
      const responseData = response?.data || {};
      const { id, type = '', message: resMessage = '', data } = responseData;
      const currentDocumentId = location.pathname.split('/').pop();

      //check if the document is actived
      const isDocumentActived = currentDocumentId === id;

      //handle custom bibligraphy file processing
      if (type === 'bibliography_file_processing') {
        setPageData({
          uploadedFileUpdatesWS: responseData.file_data
        });
        return;
      }
      //handle transparency logs
      if (resMessage === 'transparency') {
        isDocumentActived && setPageData({ logsData: data });
        return;
      }

      // handle document review generated
      if (type === 'document_ai_review_generated') {
        isDocumentActived &&
          setPageData({
            review_insights: {
              data: data?.predefined_review,
              reviewInsightsIsLoading: false
            }
          });
        return;
      }

      //handle document updates for both polishing and paper generation
      const updateDocumentData = async () => {
        //TODO: need to optimize document generation and polishing process
        //updating only the section that has been polished or generated
        //not the whole document (currently the whole document will be updated)
        isDocumentActived && setPageData({ sectionStatusUpdated: false });
        const paperResponse = await getDocument(id);
        const { stage = '', status = '', title = '' } = paperResponse;
        const activedEditorPanelStep = stage === 'plan_generated' ? 1 : 2;
        const activedEditorViewStep = stage?.includes('content_') ? 1 : 0;
        const isPaperGenerated = status === 'completed';
        const isPolishing = stage === 'polishing';

        if (isPaperGenerated) {
          setCompletedDocument({ id, stage, status, title });
        }

        if (isDocumentActived) {
          let newDocumnets = await getDocuments();
          newDocumnets = newDocumnets?.results || [];
          const bibliographyList = paperResponse?.bibliographies || [];
          const sectionStatusUpdated = type === 'section_status_updated';
          if (isPolishing) {
            setPageData({
              ...convertToPaper(paperResponse, newDocumnets, bibliographyList),
              sectionStatusUpdated,
              allSectionsPolished: false
            });
            return;
          }
          setPageData({
            ...convertToPaper(paperResponse, newDocumnets, bibliographyList),
            activedEditorPanelStep,
            activedEditorViewStep,
            createPaperViewStep: 3,
            isCreatePaperViewGenerating: false,
            isPaperGenerated,
            sectionStatusUpdated
          });
          return;
        }

        //show snackbar notification for document polishing when the document is not actived
        isPolishing &&
          isPaperGenerated &&
          !getSnackbarInstance() &&
          Snackbar.info(
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <label style={{ minWidth: 413 }}>
                <span
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'bottom',
                    maxWidth: 309,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {title}
                </span>
                &nbsp;has been unified
              </label>
              <div style={{ color: '#fff' }}>
                <Button
                  sx={{
                    color: '#fff',
                    fontWeight: 700,
                    letterSpacing: 0.4
                  }}
                  onClick={() => {
                    nav(`/documents/${id}`);
                    closeSnackbar();
                  }}
                >
                  View
                </Button>
                <IconButton onClick={() => closeSnackbar()}>
                  <CloseRounded sx={{ fontSize: '20px', color: '#fff' }} />
                </IconButton>
              </div>
            </Stack>,
            {
              autoHideDuration: 18000
            }
          );
      };
      updateDocumentData();
    };
    client.onerror = () => {};
    client.onclose = () => {};
  }, [client]);

  return (
    <>
      {loadTemplates ? (
        <div className={classes.wrapper}>
          <LeftPanel />
          {isNotFound ? <NotFound /> : <EditorPanel />}
          <RightPanel />
        </div>
      ) : (
        <HomePage />
      )}
    </>
  );
};

export default Home;
