import React, { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { MenuItem, Menu, IconButton } from '@mui/material';
import inClinico from 'assets/header/inClinicoLogo.svg';
import Chemistry42 from 'assets/header/Chemistry42.svg';
import PandaOmics from 'assets/header/PandaOmics_Panda.svg';
import { AppsRounded } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  accountCircleIconWrapper: {
    display: 'flex',
    padding: 5,
    borderRadius: '50px',
    marginRight: -5,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F2F2F2'
    }
  },
  accountCircleIcon: {
    color: theme.palette.text.secondary,
    fontSize: 36,

    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  headerLink: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 'normal',
    textAlign: 'left'
  },
  userInfoWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  userName: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.text.primary
  },
  userEmail: {
    fontWeight: 'normal',
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  menu: {
    zIndex: 1401,

    '&> .MuiMenu-paper': {
      marginTop: 41,

      [theme.breakpoints.up('sm')]: {
        marginTop: 25
      }
    }
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: 0.15,
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none'
  },
  title: {
    fontStyle: 'normal',
    padding: 16,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    marginBottom: 4
  },
  menuItem: {
    width: 300,
    paddingTop: 12,
    paddingBottom: 12
  },
  menuAbout: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    paddingTop: 12,
    paddingBottom: 4
  },
  icon: {
    marginRight: 8
  },
  iconButtonWrapper: {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      right: 0,
      top: 8
    }
  }
}));

interface PharmaMenuProps {
  handleLink: (_link: string) => void;
  pandaOmicsLink: string;
}

const PharmaMenu: FC<PharmaMenuProps> = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const updateAnchorEl = (event) => setAnchorEl(event.target);
  const handleClose = () => setAnchorEl(null);

  const handleLink = (link) => {
    props.handleLink(link);
    handleClose();
  };

  const links = [
    {
      name: 'PandaOmics',
      link: props.pandaOmicsLink,
      img: PandaOmics,
      testId: 'pandaOmicsTest'
    },
    {
      name: 'Chemistry42',
      link: 'https://chemistry42.com/',
      img: Chemistry42,
      testId: 'chemistry42Test'
    },
    {
      name: 'inClinico',
      link: 'https://inclinico.com/',
      img: inClinico,
      testId: 'inClinicoTest'
    }
  ];

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        sx={{ mr: 1 }}
        onClick={updateAnchorEl}
        className={classes.iconButtonWrapper}
      >
        <AppsRounded htmlColor="#666666" />
      </IconButton>
      <Menu
        disableScrollLock={true}
        className={classes.menu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        data-testid="headerUserMenu-openMenuButton"
        onClose={handleClose}
      >
        <div className={classes.title}>PHARMA.AI SUITE</div>

        {links?.map((item, index) => {
          if (!item.link) {
            return null;
          }

          return (
            <MenuItem
              key={index}
              className={classes.menuItem}
              onClick={() => {
                handleLink(item.link);
              }}
              data-testid={item.testId}
            >
              {item?.img && (
                <img src={item.img} alt={item.name} className={classes.icon} />
              )}
              {item.name && <span>{item.name}</span>}
            </MenuItem>
          );
        })}

        <MenuItem
          className={classes.menuAbout}
          onClick={() => {
            handleLink('https://insilico.com/platform');
          }}
          data-testid={'linkAboutTest'}
        >
          <span>About</span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default PharmaMenu;
