import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InfoOutlined } from '@mui/icons-material';

import { ILanguageMetrics } from 'types/document';
import MetricItem from '../MetricItem';

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8
  }
}));

interface ILanguageCategoryMetricsProps {
  metricsData: ILanguageMetrics;
  title: string;
}

const LanguageCategoryMetrics = ({
  metricsData,
  title
}: ILanguageCategoryMetricsProps) => {
  const classes = useStyles();

  const tooltipText = Object.keys(
    metricsData.section_word_character_counts
  ).map((section) => {
    return `${section}: ${metricsData.section_word_character_counts[section]?.[1]} words, ${metricsData.section_word_character_counts[section]?.[0]} characters. \n`;
  });

  const showInfoIcon =
    Object.keys(metricsData.section_word_character_counts).length > 1;

  return (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="body2" fontWeight={500}>
          {title}
        </Typography>
        {showInfoIcon && (
          <Tooltip
            disableFocusListener={true}
            placement="bottom"
            title={tooltipText}
            PopperProps={{
              popperOptions: {
                modifiers: [
                  {
                    name: 'offset',
                    options: { offset: [0, -8] }
                  }
                ]
              }
            }}
          >
            <InfoOutlined sx={{ fontSize: 20 }} />
          </Tooltip>
        )}
      </div>
      <MetricItem
        label="Total word count"
        value={metricsData.total_word_count}
      />
      <MetricItem
        label="Total character count"
        value={metricsData.total_character_count}
      />
      <MetricItem
        label="Reading time"
        value={Math.round(metricsData.reading_time_minutes)}
        unit="minutes"
      />
    </>
  );
};

export default LanguageCategoryMetrics;
