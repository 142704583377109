import { useState, useEffect, useRef, useContext, FC, memo } from 'react';
import { useInfiniteScroll } from 'ahooks';
import classNames from 'classnames';
import {
  getBibliographyFiles,
  deleteBibliographyFile,
  postBibliographyFileName
} from 'services/files';
import {
  Button,
  Popover,
  CircularProgress,
  Box,
  Typography,
  DialogContentText,
  Stack,
  List,
  ListItem
} from '@mui/material';
import {
  FileUploadOutlined,
  DeleteForever,
  InfoOutlined,
  CloudUploadRounded
} from '@mui/icons-material';
import Dialog from 'components/Dialog';
import { useDropzone } from 'react-dropzone';
import { formatDateWithNewRule } from 'utils/utils';
import NoData from '../Documents/components/NoData';
import { store } from 'contexts/PageContextStore';
import Menu from './components/Menu';
import useStyles from './useStyles';

type FileUploadStatusType = 'uploaded' | 'uploading' | 'processed' | 'failed';
type UploadedFileItemType = {
  pk: string;
  name: string;
  status: FileUploadStatusType;
  created_at: string;
  updated_at: string;
};
type BibliographyFilesResType = {
  next: string | null;
  previous: string | null;
  results: UploadedFileItemType[];
};
const PAGE_SIZE = 20;

const common_upload_file_config = {
  accept: {
    'application/pdf': ['.pdf']
  },
  maxFiles: 20,
  maxSize: 20000000
};

const Bibliography: FC<{ visible: boolean }> = ({ visible }) => {
  const classes = useStyles();
  const { storeState: { uploadedFileUpdatesWS = [] } = {} } = useContext(store);
  const scrollElemRef = useRef<HTMLUListElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [currentFileId, setCurrentFileId] = useState<string>('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    ...common_upload_file_config,
    noDrag: true
  });

  const {
    acceptedFiles: dragAcceptedFiles,
    getRootProps: dragGetRootProps,
    getInputProps: dragGetInputProps,
    isDragAccept
  } = useDropzone({
    ...common_upload_file_config,
    noClick: true
  });

  const loadFilesData = async (nextCursor?: string | null) => {
    if (nextCursor === null) return { list: [], next: null };
    const res = await getBibliographyFiles({
      pageSize: PAGE_SIZE,
      cursor: nextCursor
    });
    const resData: BibliographyFilesResType = res.data;
    const { next, results } = resData;
    return { list: results, next };
  };

  const { data, loading, loadingMore, mutate, reload } = useInfiniteScroll<{
    list: UploadedFileItemType[];
    next: string | null;
  }>(
    (data) => {
      return loadFilesData(data?.next);
    },
    {
      target: scrollElemRef,
      isNoMore: (data) => data?.next === null || !visible,
      manual: true
    }
  );

  useEffect(() => {
    // Switch to the current tab for the first time, manually trigger load data.
    if (visible && !data?.list?.length) {
      reload();
    }
  }, [visible]);

  const isEmpty = !loading && data?.list?.length === 0;

  const onClosePopover = () => {
    setPopoverOpen(false);
    setAnchorEl(null);
    setCurrentFileId('');
  };

  const handleDeleteFile = async (id: string) => {
    if (!id) {
      return;
    }
    const res = await deleteBibliographyFile(id);
    if (res && data) {
      mutate({
        ...data,
        list: data.list?.filter((item) => item.pk !== id)
      });
      return res;
    }
  };

  useEffect(() => {
    if (isDragAccept) {
      return;
    }
    if (acceptedFiles?.length || dragAcceptedFiles?.length) {
      [...(acceptedFiles || []), ...(dragAcceptedFiles || [])].forEach(
        (file) => {
          postBibliographyFileName(
            file.name,
            file,
            () => {
              reload();
            },
            () => {}
          );
        }
      );
    }
  }, [acceptedFiles, dragAcceptedFiles, isDragAccept]);

  useEffect(() => {
    if (uploadedFileUpdatesWS?.pk && data) {
      mutate({
        ...data,
        list: data.list?.map((item) => {
          if (item.pk === uploadedFileUpdatesWS.pk) {
            return {
              ...item,
              status: uploadedFileUpdatesWS.status
            };
          }
          return item;
        })
      });
    }
  }, [uploadedFileUpdatesWS?.pk]);

  const getUploadButton = (isContained?: boolean) => {
    let sxStyle = {
      fontSize: 14,
      padding: '0 16px',
      lineHeight: '30px'
    };
    if (isContained) {
      sxStyle = {
        fontSize: 16,
        padding: '0 20px',
        lineHeight: '36px'
      };
    }
    const iconSize = isContained ? 20 : 16;
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button
          variant={isContained ? 'contained' : 'text'}
          sx={{ textTransform: 'none', ...sxStyle }}
          size="medium"
          startIcon={
            <FileUploadOutlined
              style={{ fontSize: iconSize, marginRight: -3 }}
            />
          }
        >
          Upload File
        </Button>
      </div>
    );
  };

  return (
    <Stack className={classes.wrapper}>
      <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
        <div className={classes.blockTitle}>Uploaded Custom Bibliography</div>
        {getUploadButton()}
      </Stack>

      {/* {!isEmpty && (
        <Stack direction={'row'} className={classes.filterBar}>
          {getUploadButton()}
        </Stack>
      )} */}

      <div
        className={classNames(
          classes.content,
          isDragAccept && classes.contentDragAccept
        )}
        {...dragGetRootProps()}
      >
        <input {...dragGetInputProps()} />
        <Stack className={classes.listWrapper}>
          {!isEmpty && (
            <Stack
              direction="row"
              sx={{
                padding: '14px 16px',
                borderBottom: '1px solid #f2f2f2',
                boxSizing: 'border-box',
                fontSize: 14,
                fontWeight: 500,
                color: ''
              }}
            >
              <div className={classes.colName}>Name</div>
              <div style={{ width: 160, marginRight: 40 }}>Uploaded</div>
              <div className={classes.colActions} />
            </Stack>
          )}

          {isEmpty ? (
            <NoData
              title="No files uploaded yet"
              description="Drag and drop files here, or select files from your computer"
              style={{
                background: isDragAccept ? '#e7f6ee' : '#f8f8f8',
                border: '1px solid #f2f2f2',
                flex: 1,
                minHeight: 'unset',
                marginTop: 0
              }}
              Actions={getUploadButton(true)}
            />
          ) : (
            <List
              ref={scrollElemRef}
              sx={{
                scrollbarWidth: 'none',
                flex: 1,
                overflow: 'auto',
                paddingTop: 0
              }}
            >
              {(data?.list || []).map(({ name, pk, status, created_at }) => {
                return (
                  <ListItem
                    className={classNames(
                      classes.listItem,
                      status !== 'processed' && classes.listItemGrey
                    )}
                    key={pk}
                  >
                    <Stack className={classes.colName} direction={'row'}>
                      <div className={classes.name}>{name}</div>
                      {status === 'failed' && (
                        <div
                          style={{
                            color: '#AB2F26',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          Failed
                          <InfoOutlined
                            fontSize={'small'}
                            style={{ marginLeft: '8px' }}
                          />
                        </div>
                      )}
                    </Stack>
                    <div className={classes.colUploaded}>
                      {status === 'processed' || status === 'failed' ? (
                        formatDateWithNewRule(created_at)
                      ) : (
                        <CircularProgress size={14} />
                      )}
                    </div>
                    <div className={classes.colActions}>
                      <Menu
                        target={{ id: pk, title: name }}
                        handleDeleteFile={handleDeleteFile}
                      />
                    </div>
                  </ListItem>
                );
              })}
              {(loadingMore || loading) && (
                <div
                  style={{
                    height: '100px',
                    textAlign: 'center',
                    padding: '14px 0'
                  }}
                >
                  <CircularProgress size={24} />
                </div>
              )}
            </List>
          )}
        </Stack>
      </div>
      <Popover
        open={popoverOpen}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ zIndex: 1200 }}
        anchorEl={anchorEl}
      >
        <div className={classes.moreActionsWrapper}>
          <div
            className={classes.moreActionItem}
            onClick={() => {
              setPopoverOpen(false);
              setIsDeleteDialogOpen(true);
            }}
          >
            <span>Delete</span>
            <DeleteForever fontSize={'small'} />
          </div>
        </div>
      </Popover>
      <Dialog
        open={isDeleteDialogOpen}
        title="Delete file?"
        Content={
          <Box maxWidth={529}>
            <Typography mb={2}>
              <span style={{ fontWeight: 500, marginRight: '8px' }}>
                {data?.list.find((file) => file.pk === currentFileId)?.name}
              </span>
              will be permanently deleted.
            </Typography>
            <DialogContentText color="#212121">
              This action cannot be undone.
            </DialogContentText>
          </Box>
        }
        handleClose={() => setIsDeleteDialogOpen(false)}
        actionBtnTexts={{ confirm: 'Delete' }}
        handleConfirm={() => handleDeleteFile(currentFileId)}
      />
      {isDragAccept && (
        <div className={classes.dragInfo}>
          <CloudUploadRounded style={{ color: '#FFFFFF' }} />
          <p>Drop PDF files here (up to 20MB each)</p>
          <p>You can upload up to 20 files at once</p>
        </div>
      )}
    </Stack>
  );
};

export default memo(Bibliography);
