import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Typography, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InfoOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import MetricItem from '../MetricItem';
import { IArgumentationMetrics } from 'types/document';

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  tooltipPopper: {
    width: 420
  }
}));

interface IArgumentationCategoryMetricsProps {
  metricsData: IArgumentationMetrics;
  title: string;
}

const ArgumentationCategoryMetrics = ({
  metricsData,
  title
}: IArgumentationCategoryMetricsProps) => {
  const theme = useTheme();
  const classes = useStyles();

  const aXisData = Object.keys(metricsData.publication_year_distribution);
  const seriesData = Object.values(metricsData.publication_year_distribution);
  const options = {
    chart: {
      type: 'column',
      height: 260,
      marginLeft: -8
    },
    title: {
      text: 'References by year',
      style: {
        fontSize: 14,
        fontWeight: 500,
        marginLeft: -8
      }
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: aXisData,
      lineWidth: 0
    },
    yAxis: {
      lineWidth: 0,
      title: {
        text: null
      },
      labels: {
        enabled: false
      }
    },
    series: [
      {
        data: seriesData,
        color: theme.palette.primary.main
      }
    ],
    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return `<b>${this.x}</b>: ${this.y}`;
      }
    }
  };

  const conditionToShowGraph = metricsData.unique_references > 3;
  const tooltipText =
    'Counts only the cited publications in the Bibliography that have associated article metadata, such as title, authors, and publication year';

  return (
    <>
      <div className={classes.headerWrapper}>
        <Typography variant="body2" fontWeight={500}>
          {title}
        </Typography>
        <Tooltip
          disableFocusListener={true}
          placement="bottom"
          title={tooltipText}
          classes={{ popper: classes.tooltipPopper }}
          PopperProps={{
            popperOptions: {
              modifiers: [
                {
                  name: 'offset',
                  options: { offset: [0, -8] }
                }
              ]
            }
          }}
        >
          <InfoOutlined sx={{ fontSize: 20 }} />
        </Tooltip>
      </div>
      <MetricItem
        label="Total references"
        value={metricsData.total_references}
      />
      <MetricItem
        label="Unique references"
        value={metricsData.unique_references}
      />
      {conditionToShowGraph && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </>
  );
};

export default ArgumentationCategoryMetrics;
