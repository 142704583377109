import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';

import { seriousnessLevels } from 'utils/reviewInsights';
import SortButton from '../SortButton';
import { ReviewSuggestionSeriousnessLevels } from 'types/document';

const useStyles = makeStyles(() => ({
  sortButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '8px 0 0 auto',
    '& > div:first-of-type': {
      marginLeft: 0
    }
  }
}));

interface ISortButtonsGroupProps {
  activeFilter: ReviewSuggestionSeriousnessLevels | null;
  handleFilterClick: (filter: ReviewSuggestionSeriousnessLevels) => void;
  seriousnessCounts: Record<ReviewSuggestionSeriousnessLevels, number> | {};
}

const SortButtonsGroup = ({
  activeFilter,
  handleFilterClick,
  seriousnessCounts
}: ISortButtonsGroupProps) => {
  const classes = useStyles();

  return (
    <div className={classes.sortButtonsWrapper}>
      {seriousnessLevels().map(({ level, color, borderColor, activeColor }) => {
        return !!seriousnessCounts[level] ? (
          <SortButton
            key={level}
            level={level}
            color={color}
            borderColor={borderColor}
            activeColor={activeColor}
            count={seriousnessCounts[level]}
            activeFilter={activeFilter}
            handleFilterClick={handleFilterClick}
          />
        ) : null;
      })}
    </div>
  );
};

export default memo(SortButtonsGroup);
