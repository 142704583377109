/**
 * copy from Documents/components/Document/components/Menu/index.tsx
 */
import React, { useState, MouseEvent, memo } from 'react';
import { Box, Menu as MUIMenu, Typography } from '@mui/material';
import { FC } from 'react';
import { DialogContentText, IconButton, MenuItem } from '@mui/material';
import { MoreVert, DeleteForeverRounded } from '@mui/icons-material';
import Dialog from 'components/Dialog';
import Snackbar from 'utils/snackbar';

interface MenuProps {
  target: any;
  handleDeleteFile: (id: string) => Promise<any>;
}

const Menu: FC<MenuProps> = ({
  target: { id, title } = {},
  handleDeleteFile
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = async () => {
    const response = await handleDeleteFile(id);
    if (!response) return;
    Snackbar.info(
      <Typography
        variant="body2"
        lineHeight="145%"
        p="8px 0"
        letterSpacing={0.15}
      >
        File deleted permanently
      </Typography>
    );
  };

  const menuItems = [
    {
      icon: <DeleteForeverRounded fontSize="small" className="deleteIcon" />,
      text: 'Delete',
      handleClick: async () => setIsDeleteDialogOpen(true)
    }
  ];

  return (
    <>
      <IconButton
        onClick={handleOpenMenu}
        sx={{
          p: '3px',
          ...(open && { zIndex: 1, color: 'primary.main' })
        }}
      >
        <MoreVert fontSize={'small'} />
      </IconButton>
      <MUIMenu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 184,
            padding: '4px 0',
            borderRadius: 1,
            boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.08)'
          }
        }}
      >
        {menuItems.map((item, index) => {
          const { text, handleClick = () => {}, icon } = item;
          return (
            <div key={index}>
              <span>
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClick();
                    setAnchorEl(null);
                  }}
                  sx={{
                    display: 'flex',
                    padding: '6px 16px',
                    '& .MuiSvgIcon-root': {
                      marginLeft: 'auto',
                      color: 'text.secondary'
                    },
                    '& .Mui-disabled': {
                      cursor: 'default',
                      color: 'grey.400',
                      '& .MuiSvgIcon-root': {
                        fill: 'grey.400'
                      }
                    },
                    '&:hover': {
                      '& .deleteIcon': {
                        color: '#E31B0C'
                      }
                    }
                  }}
                >
                  <Typography variant="body2" p="4px 0">
                    {text}
                  </Typography>
                  {icon}
                </MenuItem>
              </span>
            </div>
          );
        })}
      </MUIMenu>
      <Dialog
        open={isDeleteDialogOpen}
        title="Delete File?"
        Content={
          <Box maxWidth={529}>
            <Typography mb={2}>
              <span style={{ fontWeight: 500 }}>{title}</span> will be
              permanently deleted.
            </Typography>
            <DialogContentText color="text.primary">
              This action cannot be undone.
            </DialogContentText>
          </Box>
        }
        handleClose={() => setIsDeleteDialogOpen(false)}
        actionBtnTexts={{ confirm: 'Delete' }}
        handleConfirm={handleDelete}
      />
    </>
  );
};
export default memo(Menu);
