import React, { FC, ReactNode, SyntheticEvent, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled, SxProps } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import Documents from './components/Documents';
import Bibliography from './components/Bibliography';
import { ReactComponent as DocumentsIconActive } from 'assets/icons/documents_active.svg';
import { ReactComponent as DocumentsIconInActive } from 'assets/icons/documents_inactive.svg';
import { ReactComponent as BibliographyIconActive } from 'assets/icons/bibliography_active.svg';
import { ReactComponent as BibliographyIconInActive } from 'assets/icons/bibliography_inactive.svg';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  sx?: SxProps;
}

const CustomTab = styled(Tab)<TabProps>(({ theme }) => ({
  padding: '2px 4px',
  textTransform: 'none',
  marginRight: theme.spacing(2),
  minWidth: 'auto',
  minHeight: 32,
  letterSpacing: 0,
  '& svg': {
    width: 20,
    height: 20
  }
}));

const CustomTabPanel: FC<TabPanelProps> = ({ children, value, index, sx }) => {
  return (
    <Box
      sx={{
        p: '0 24px',
        height: '100%',
        ...sx
      }}
      role="tabpanel"
      hidden={value !== index}
    >
      {children}
    </Box>
  );
};

const HomePage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activedTab, setActivedTab] = useState(
    location.pathname === '/bibliography' ? 1 : 0
  );

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    navigate(newValue === 1 ? '/bibliography' : '/documents');
  };

  React.useEffect(() => {
    setActivedTab(location.pathname === '/bibliography' ? 1 : 0);
  }, [location.pathname]);

  const DocumentsIcon = Boolean(activedTab)
    ? DocumentsIconInActive
    : DocumentsIconActive;

  const BibliographyIcon = Boolean(activedTab)
    ? BibliographyIconActive
    : BibliographyIconInActive;

  return (
    <Box
      sx={{ width: '100%', height: 'calc(100vh - 70px)' }}
      display="flex"
      flexDirection="column"
    >
      <Tabs
        value={activedTab}
        onChange={handleChange}
        sx={{
          marginTop: 1,
          padding: '0 24px',
          minHeight: 32
        }}
      >
        <CustomTab
          icon={<DocumentsIcon />}
          iconPosition="start"
          label="Documents"
        />
        <CustomTab
          icon={<BibliographyIcon />}
          iconPosition="start"
          label="Bibliography"
        />
      </Tabs>
      <CustomTabPanel value={activedTab} index={0}>
        <Documents />
      </CustomTabPanel>
      <CustomTabPanel value={activedTab} index={1}>
        <Bibliography visible={activedTab === 1} />
      </CustomTabPanel>
    </Box>
  );
};
export default HomePage;
