import { UserInfo } from 'types/user';
import { HttpService as axios } from './HttpService';
import { showErrorMessage } from './messages';

const getCsrfToken = async () => {
  return await axios.get('/users/csrf_token/').catch((error) => {
    return error.response;
  });
};

const login = async (formData) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return await axios.post('/users/login/', formData, config).catch((error) => {
    return error.response?.data;
  });
};

const logout = async () => {
  return await axios.post('/users/logout/').catch((error) => {
    return error.response?.data;
  });
};

const getUserInfo = async (): Promise<UserInfo> => {
  return await axios
    .get('/users/info/')
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return {};
    });
};

const getAITokens = async () => {
  return await axios
    .get('/users/tokens/')
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};

const register = async (formData) => {
  return await axios
    .post('/users/register/', formData)
    .then(() => {
      return true;
    })
    .catch((error) => {
      showErrorMessage(error);
      return {
        error: true,
        ...formData
      };
    });
};

const createNewPassword = async (formData) => {
  return await axios.post('/users/new_password/', formData).catch((error) => {
    return error.response;
  });
};

const validatePassword = async (formData) => {
  return await axios
    .post('/users/validate_password/', formData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      showErrorMessage(error);
      return error.response;
    });
};

const setPassword = async (formData) => {
  return await axios
    .post('/users/set_password/', formData)
    .then(() => {
      return true;
    })
    .catch((error) => {
      showErrorMessage(error);
      return null;
    });
};

const resetPassword = async (formData) => {
  return await axios
    .post('/users/reset_password/', formData)
    .then(() => {
      return true;
    })
    .catch((error) => {
      showErrorMessage(error);
      return {
        error: true,
        ...formData
      };
    });
};

const validateToken = async (formData) => {
  return await axios
    .post('/users/validate_token/', formData)
    .then(() => {
      return true;
    })
    .catch((error) => {
      const isInvalidToken = error.response?.data?.detail === 'Invalid token';
      isInvalidToken && showErrorMessage(error);
      return {
        error: true,
        isInvalidToken
      };
    });
};

const acceptTermsAndPrivacy = async () => {
  return await axios
    .post('/users/terms_and_privacy/')
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

const getCitationSettings = async () => {
  return await axios
    .get('/users/publication_settings/')
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return {};
    });
};

const updateCitationSettings = async (params) => {
  return await axios
    .post('/users/publication_settings/', params)
    .then(() => {
      return true;
    })
    .catch((error) => {
      showErrorMessage(error);
      return false;
    });
};

export {
  getCsrfToken,
  login,
  logout,
  getUserInfo,
  getAITokens,
  register,
  createNewPassword,
  validatePassword,
  setPassword,
  resetPassword,
  validateToken,
  acceptTermsAndPrivacy,
  getCitationSettings,
  updateCitationSettings
};
