import { HttpService as axios } from './HttpService';
import { showErrorMessage } from './messages';

const getPricingTableInfo = async (): Promise<any> => {
  return await axios
    .get('/payments/pricing_table/')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return [];
    });
};

const getPaymentsPortal = async (): Promise<any> => {
  return await axios
    .post('/payments/customer_portal/')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showErrorMessage(err);
      return {};
    });
};

export { getPricingTableInfo, getPaymentsPortal };
