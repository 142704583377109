import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Router from '../../router';
import Header from '../Header';
import { getUserInfo } from 'services/user';
import { store } from 'contexts/PageContextStore';
import MobileReminder from './mobileReminder';
import { useLocation } from 'react-router-dom';
import { checkMobileReminderVisibilityByPathname } from 'utils/utils';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    height: '100%',
    maxWidth: 1920,
    padding: '0 24px',
    margin: '0 auto',
    backgroundColor: '#FAFAFA',
    [theme.breakpoints?.down('md')]: {
      backgroundColor: theme.palette?.common.white,
      padding: 0
    }
  },
  mobileReminder: {
    display: 'flex'
  },
  mainContainerContent: {
    borderRadius: 16,
    height: '100%',
    overflow: 'hidden'
  }
}));

interface MainContainerProps {
  children?: ReactNode;
}

const MainContainer: FC<MainContainerProps> = ({ children }) => {
  const classes = useStyles();
  const [pageInfoLoading, setPageInfoLoading] = useState(true);

  const theme = useTheme();
  const mobileVersion = useMediaQuery(theme.breakpoints.down('md'));
  const [
    pathNameIsAllowedForMobileVersion,
    setPathNameIsAllowedForMobileVersion
  ] = useState(false);

  const { setPageData } = useContext(store);
  const location = useLocation();

  useEffect(() => {
    setPageInfoLoading(true);
    const fetchData = async () => {
      const userInfo = await getUserInfo();
      setPageData({ userInfo });
      setPageInfoLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const rootPath = location.pathname.split('/')[1];
    setPathNameIsAllowedForMobileVersion(
      checkMobileReminderVisibilityByPathname(rootPath)
    );
  }, [location.pathname]);

  const mobileReminderVisibilityCondition =
    mobileVersion && pathNameIsAllowedForMobileVersion;

  if (pageInfoLoading) {
    return null;
  }

  return (
    <div className={classes.mainContainer}>
      <Header />
      {mobileReminderVisibilityCondition ? (
        <MobileReminder className={classes.mobileReminder} />
      ) : (
        <div className={classes.mainContainerContent}>
          <Router />
          {children}
        </div>
      )}
    </div>
  );
};

export default MainContainer;
