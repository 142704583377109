import { GenerationFlow } from './template';

export type DocumentStage =
  | 'section_generating'
  | 'section_generated'
  | 'plan_generating'
  | 'plan_generated'
  | 'content_generating'
  | 'content_generated'
  | 'polishing';

export type DocumentStatus =
  | 'in_progress'
  | 'failed'
  | 'limited'
  | 'polishing'
  | 'completed';

export interface DocumentItem {
  id: string;
  title: string;
  stage: DocumentStage;
  status: DocumentStatus;
  template_id?: string;
  template_type?: string;
  created_at?: string;
  updated_at?: string;
  generation_flow: GenerationFlow;
  isNew?: boolean;
}

export type Metadata = {
  id?: string;
  type?: string;
  chunks?: {
    [key: string]: {
      [key: string]: {
        pubmed_id: number;
        chunk_id: string;
        abstract_chunk: string;
      };
    };
  };
  mermaid_diagram?: IMermaidDiagramData | null;
  metadata?: {
    title?: string;
    authors?: string[];
    pub_year: number;
    pub_type?: string[];
    journal_name?: string;
    citation_text?: string;
    citation_count?: number;
    pubmed_id?: string;
    text?: string;
    file_name?: string;
    url?: string;
  };
};

export interface IMermaidDiagramData {
  diagram_type: string;
  mermaid_editor_link: string;
  svg_diagram: string;
}

export enum ReviewSuggestionSeriousnessLevels {
  low = 'low',
  medium = 'medium',
  high = 'high'
}

export interface ILanguageMetrics {
  reading_time_minutes: number;
  section_word_character_counts: { [key: string]: number[] };
  total_character_count: number;
  total_word_count: number;
}

export interface IReadabilityMetrics {
  flesch_kincaid_grade: number;
}

export interface IArgumentationMetrics {
  publication_year_distribution: { [key: string]: number };
  total_references: number;
  unique_references: number;
}

export enum PredefinedCategoryIds {
  language = 'language_and_style',
  readability = 'readability_and_structure',
  argumentation = 'argumentation_and_evidence',
  content = 'content_and_relevance'
}

export interface IReviewCategory {
  id: string;
  title: string;
  score: number;
  score_explanation: string;
  suggestions: IReviewCategorySuggestion[];
  code_based_metrics:
    | ILanguageMetrics
    | IReadabilityMetrics
    | IArgumentationMetrics
    | null;
}

export interface IReviewCategorySuggestion {
  seriousness: string;
  text: string;
}
