import React, { FC, ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import NoDataImg from 'assets/rightPanel/NoData.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: 8,
    textAlign: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    userSelect: 'none',
    '& p': {
      color: theme.palette.text.secondary,
      lineHeight: '150%',
      letterSpacing: '0.15px'
    }
  }
}));

interface NoDataProps {
  imgSrc?: string;
  content?: ReactNode;
}

const NoData: FC<NoDataProps> = ({ imgSrc, content }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <img src={imgSrc || NoDataImg} />
      {content || (
        <p>
          Evidence for the information can be found here. Click any link in the
          text to view it.
        </p>
      )}
    </div>
  );
};

export default NoData;
