import React, { FC, useContext, useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography } from '@mui/material';
import { PersonOutlineRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import DORA from '../../assets/icons/Science42_ DORA.svg';
import { store } from 'contexts/PageContextStore';
import PharmaMenu from './PharmaMenu';
import useGAUserDimensions from 'hooks/useGAUserDimensions';
import UserMenu from './UserMenu';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    flex: '0 1 auto',
    '& .MuiButtonBase-root': {
      borderRadius: 8
    },
    zIndex: 0,
    [theme.breakpoints.down('md')]: {
      padding: '4px 0'
    }
  },
  iconText: {
    fontSize: 14,
    marginLeft: 8,
    color: theme.palette.text.primary
  },
  pharmaMenuAndLoginWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'center',
      width: '100%'
    }
  },
  toolbarRoot: {
    padding: 0
  },
  iconButton: {
    padding: 5.3
  }
}));

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { storeState: { userInfo = {} } = {} } = useContext(store);
  const isTermsAndPrivacyAccepted = userInfo?.terms_and_privacy_accepted;

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  useGAUserDimensions();

  return (
    <>
      <AppBar classes={{ root: classes.appBar }}>
        <Toolbar className={classes.toolbarRoot}>
          <div className={classes.pharmaMenuAndLoginWrapper}>
            <PharmaMenu
              handleLink={openInNewTab}
              pandaOmicsLink="https://pandaomics.com/access"
            />
            <img
              src={DORA}
              alt="DORA"
              width={207}
              height={27}
              style={{ marginTop: -3, cursor: 'pointer' }}
              onClick={() => (window.location.href = '/main')}
            />
          </div>
          <Box sx={{ flexGrow: 1 }} />
          {!Object.keys(userInfo).length || !isTermsAndPrivacyAccepted ? (
            <></>
          ) : (
            <Box
              gap="6px"
              sx={{ display: { xs: 'none', md: 'flex' } }}
              alignItems="center"
            >
              <IconButton
                size="small"
                className={classes.iconButton}
                onClick={() =>
                  openInNewTab('https://insilico.com/blog/science42-dora')
                }
              >
                <Typography
                  color="text.secondary"
                  variant="caption"
                  fontWeight={500}
                  lineHeight="normal"
                >
                  Blog
                </Typography>
              </IconButton>
              <IconButton
                size="small"
                className={classes.iconButton}
                onClick={() =>
                  openInNewTab('https://insilico.com/science42/dora/help')
                }
              >
                <Typography
                  color="text.secondary"
                  variant="caption"
                  fontWeight={500}
                  lineHeight="normal"
                >
                  Tutorial
                </Typography>
              </IconButton>
              <IconButton
                size="small"
                className={classes.iconButton}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <PersonOutlineRounded
                  htmlColor="#757575"
                  sx={{ fontSize: 21 }}
                />
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <UserMenu
        anchorEl={anchorEl}
        isMenuOpen={isMenuOpen}
        handleMenuClose={handleMenuClose}
      />
    </>
  );
};

export default Header;
