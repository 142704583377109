import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReviewSuggestionSeriousnessLevels } from 'types/document';

const useStyles = makeStyles(() => ({
  sortButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 16,
    paddingLeft: 8,
    borderRadius: 16,
    cursor: 'pointer'
  },
  sortButtonCountWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1px 6px',
    borderRadius: 16
  }
}));

interface ISortButtonProps {
  level: ReviewSuggestionSeriousnessLevels;
  color: string;
  borderColor: string;
  activeColor: string;
  count: number;
  activeFilter: ReviewSuggestionSeriousnessLevels | null;
  handleFilterClick: (filter: ReviewSuggestionSeriousnessLevels) => void;
}

const SortButton = ({
  level,
  color,
  borderColor,
  activeColor,
  count,
  activeFilter,
  handleFilterClick
}: ISortButtonProps) => {
  const classes = useStyles();

  return (
    <div
      data-testid="sortButton-wrapper"
      className={classes.sortButtonWrapper}
      onClick={() => handleFilterClick(level)}
      style={{
        backgroundColor: activeFilter === level ? activeColor : undefined,
        border: `1px solid ${borderColor}`,
        color: color
      }}
    >
      <Typography
        data-testid="sortButton-level"
        fontSize={10}
        fontWeight={600}
        color={color}
        sx={{ margin: '0 6px 0 2px' }}
        lineHeight={'156%'}
      >
        {level.charAt(0).toUpperCase() + level.slice(1)}
      </Typography>
      <div
        className={classes.sortButtonCountWrapper}
        style={{
          backgroundColor: borderColor
        }}
      >
        <Typography
          data-testid="sortButton-count"
          fontSize={12}
          fontWeight={700}
          color={color}
          lineHeight={'137%'}
        >
          {count}
        </Typography>
      </div>
    </div>
  );
};

export default memo(SortButton);
