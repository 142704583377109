import React, { useState, memo } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { IReviewCategorySuggestion } from 'types/document';
import { getBorderRightColorBySeriousness } from 'utils/reviewInsights';

const useStyles = makeStyles(() => ({
  suggestionWrapper: {
    marginBottom: 24,
    paddingRight: 16
  }
}));

interface ISuggestionsListProps {
  filteredSuggestions: IReviewCategorySuggestion[];
  totalSuggestionsLength: number;
}

const SuggestionsList = ({
  filteredSuggestions,
  totalSuggestionsLength
}: ISuggestionsListProps) => {
  const classes = useStyles();
  const [showAllList, setShowAllList] = useState(false);

  const defaultVisibleSuggestionsCount = 2;
  const visibleSuggestionsCount = showAllList
    ? totalSuggestionsLength
    : defaultVisibleSuggestionsCount;

  const toggleShowAll = () => {
    setShowAllList(!showAllList);
  };

  return (
    <>
      {filteredSuggestions
        .slice(0, visibleSuggestionsCount)
        .map((suggestion, index) => (
          <div
            key={index}
            data-testid={`suggestionsList-suggestionsWithId-${index}`}
            className={classes.suggestionWrapper}
            style={{
              borderRight: `2px solid ${getBorderRightColorBySeriousness(suggestion.seriousness)}`
            }}
          >
            <Typography
              fontSize={14}
              lineHeight={'145%'}
              letterSpacing={'0.15px'}
            >
              {suggestion.text}
            </Typography>
          </div>
        ))}
      {filteredSuggestions.length > defaultVisibleSuggestionsCount && (
        <Typography
          onClick={toggleShowAll}
          fontSize={12}
          fontWeight={700}
          data-testid="suggestionsList-showMoreButton"
          color="primary"
          lineHeight={'137%'}
          sx={{
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' }
          }}
        >
          {showAllList ? 'Show Less' : 'Show More'}
        </Typography>
      )}
    </>
  );
};

export default memo(SuggestionsList);
