import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface BibliographyCustomItemProps {
  fileName?: string;
  url?: string;
  sx?: any;
}

export default function BibliographyCustomItem({
  fileName,
  url,
  sx
}: BibliographyCustomItemProps) {
  return (
    <>
      {fileName && (
        <Typography variant="body2" my="12px" sx={sx}>
          {fileName}
        </Typography>
      )}
      {url && (
        <Link to={url} target="_blank">
          <Typography
            variant="body2"
            my="12px"
            sx={{ textDecorationLine: 'underline', ...sx }}
          >
            {url}
          </Typography>
        </Link>
      )}
    </>
  );
}
