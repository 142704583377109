import React from 'react';

interface IConcept {
  end: number;
  entity: number;
  label: string;
  start: number;
  text: string;
  text_source: string;
  type: string;
}

interface IHighlightEntities {
  document_id: number;
  concepts: IConcept[];
}

interface IHighlightedTextProps {
  text: string;
  highlight_entities: IHighlightEntities;
  type: string;
  customStyles: string | undefined;
}

type IHighlightMap = { [_key in string]: number };

const HighlightedText = (props: IHighlightedTextProps) => {
  const { text, highlight_entities, type } = props;
  const { concepts = [] } = highlight_entities;

  const highlightSource: IConcept[] =
    concepts
      ?.filter((item: IConcept) => item.text_source === type)
      .sort((a: IConcept, b: IConcept) => a.start - b.start) || [];

  const splitIndexArr: number[] = [];
  const highlightMap: IHighlightMap = {};

  highlightSource?.forEach((item: IConcept) => {
    splitIndexArr.push(item.start, item.end);
    highlightMap[item.start + '_' + item.end] = item.entity;
  });

  const html: any = text;
  if (concepts?.length && splitIndexArr.length) {
    let splitStart = 0;
    let splitEnd = 0;
    let i = 0;
    do {
      splitEnd = splitIndexArr[i];
      splitStart = splitEnd;
      i++;
    } while (splitStart <= text.length && i <= splitIndexArr.length);
  }

  return <div data-testid="highlight-text">{html}</div>;
};

export default HighlightedText;
