import { pathnamesArrayAllowedForMobileVersion } from 'types/constants';

export function saveToLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key)!);
}

//remove duplicates from an array of objects by a key
export function removeDuplicates(array, key) {
  return array.filter(
    (obj, index, self) =>
      self.findIndex((item) => item[key] === obj[key]) === index
  );
}

export function removeDuplicatesByPmid(array) {
  if (array.length <= 1) return array;
  const newArray = array.filter((item) => item.id);
  return newArray.filter(
    (obj, index, self) =>
      self.findIndex(
        (item) =>
          item.metadata?.pubmed_id === obj.metadata?.pubmed_id &&
          item.metadata?.pubmed_id
      ) === index
  );
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getUTCDate();
  const month = date.toLocaleString('default', { month: 'short' });
  return `${day} ${month}`;
}

export function isExpiredDate(dateString) {
  const expirationDate = new Date(dateString);
  const currentDate = new Date();
  return currentDate > expirationDate;
}

export const checkMobileReminderVisibilityByPathname = (pathname: string) => {
  return !pathnamesArrayAllowedForMobileVersion.includes(pathname);
};

export const isBeforeCheckData = (createdAt: Date, checkDate: Date) => {
  return createdAt < checkDate;
};

export const convertToKey = (string) => {
  return string.replace(/\s/g, '').toLowerCase();
};

export const formatDateWithNewRule = (dateString: string = ''): string => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const now = new Date();
  const currentYear = now.getFullYear();

  // Calculate the difference in days between the input date and the current date
  const dateWithoutTime = new Date(date.toDateString());
  const currentWithoutTime = new Date(now.toDateString());
  const timeDiff = dateWithoutTime.getTime() - currentWithoutTime.getTime();

  const HoursInDay = 24;
  const SecondsInMinute = 60;
  const MinutesInHour = 60;
  const MillisecondsInSecond = 1000;
  const dayDiff =
    timeDiff /
    (MillisecondsInSecond * SecondsInMinute * MinutesInHour * HoursInDay);

  if (dayDiff === 0) {
    return 'Today';
  } else if (dayDiff === -1) {
    return 'Yesterday';
  }

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' }); // E.g., "Oct"

  if (date.getFullYear() === currentYear) {
    return `${day} ${month}`;
  } else {
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }
};

export const downloadSvg = (svg: string, documentTitle: string): void => {
  // Create a temporary container to parse the SVG string
  const svgElement = document.createElement('div');
  svgElement.innerHTML = svg;
  const svgNode = svgElement.firstChild as SVGElement | null;

  if (svgNode) {
    // Create a Blob from the SVG content
    const svgBlob = new Blob([svgNode.outerHTML], { type: 'image/svg+xml' });
    const svgUrl = URL.createObjectURL(svgBlob);

    // Create a temporary anchor element to trigger the download
    const tempLink = document.createElement('a');
    tempLink.href = svgUrl;
    tempLink.download = `Visual_summary_for_${documentTitle.replace(/ /g, '_')}.svg`;

    // Append the anchor to the document, trigger the click, and remove it
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);

    // Release the Blob URL
    URL.revokeObjectURL(svgUrl);
  }
};
