import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 30,
    height: 'calc(100% - 30px)',
    borderRadius: 24,
    backgroundColor: theme.palette.common.white,
    padding: 24,
    boxSizing: 'border-box',
    '& .MuiButton-textSizeMedium:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  blockTitle: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '30px'
  },
  content: {
    backgroundColor: theme.palette.common.white,
    flex: 1,
    borderRadius: 16,
    overflow: 'auto',
    marginTop: 14
  },
  contentDragAccept: {
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
    '& $listItem': {
      '&:hover': {
        backgroundColor: 'unset'
      }
    }
  },
  filterBar: {
    height: 36,
    margin: '14px 0',
    justifyContent: 'flex-end'
  },
  listWrapper: {
    height: '100%',
    overflow: 'auto',
    borderRadius: 16
  },

  listItem: {
    padding: '14px 16px',
    boxSizing: 'border-box',
    borderBottom: `1px solid #F2F2F2`,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 400,
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    }
  },
  listItemGrey: {
    color: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: 'unset',
      cursor: 'default'
    }
  },
  colName: {
    flex: 1,
    width: '100%',
    paddingRight: 40,
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  colUploaded: {
    width: 160,
    marginRight: 40,
    color: '#666'
  },
  colActions: {
    width: 24,
    marginRight: 24
  },
  moreActionsWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: '8px 0',
    borderRadius: 8,
    width: 184,
    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.08)'
  },
  moreActionItem: {
    padding: '0 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
    height: 40,
    cursor: 'pointer',
    '& .MuiSvgIcon-fontSizeSmall': {
      color: theme.palette.grey[600]
    },
    '&:hover': {
      backgroundColor: '#F2F2F2',
      '& .MuiSvgIcon-fontSizeSmall': {
        color: '#E31B0C'
      }
    }
  },
  name: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  dragInfo: {
    width: 304,
    padding: '12px 16px 16px',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 16,
    position: 'fixed',
    bottom: 48,
    textAlign: 'center',
    left: '50%',
    transform: 'translateX(-50%)',
    '& p': {
      color: theme.palette.common.white,
      fontSize: 14,
      lineHeight: '20px',
      margin: 0
    }
  }
}));

export default useStyles;
