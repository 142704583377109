import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    mode: 'light',
    common: {
      black: '#212121',
      white: '#FFFFFF'
    },
    primary: {
      main: '#21965F',
      light: '#E7F6EE',
      dark: '#1C8554'
    },
    secondary: {
      main: '#29A96D',
      dark: '#FF2D26',
      light: '#F9A09A'
    },
    success: {
      main: '#00AB61',
      dark: '#009152',
      light: '#65CFA4'
    },
    info: {
      main: '#919191'
    },
    warning: {
      main: '#8A4908',
      dark: '#FFA000',
      light: '#FFD54F'
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
      light: '#F88078'
    },
    text: {
      primary: '#212121',
      secondary: '#666666',
      disabled: '#BDBDBD'
    },
    grey: {
      50: '#F5F5F5',
      100: '#F8F8F8',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#666666',
      800: '#424242',
      900: '#212121'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: 8,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          },
          //disable style for primary button
          '&.MuiButton-containedPrimary': {
            '&.Mui-disabled': {
              color: '#BDBDBD',
              backgroundColor: '#E0E0E0'
            }
          }
        },
        outlined: {
          ':disabled': {
            borderColor: '#BDBDBD'
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
          alignItems: 'center',
          borderRadius: 12
        },
        standardSuccess: {
          backgroundColor: '#E7F6EE',
          color: '#1C8554'
        },
        standardError: {
          backgroundColor: '#FEECEB',
          color: '#621B16'
        },
        standardWarning: {
          backgroundColor: '#FFEBC2',
          color: '#8A4908'
        },
        standardInfo: {
          backgroundColor: '#F2F2F2',
          color: '#767676'
        },
        action: {
          padding: 0,
          marginRight: 0,
          button: {
            padding: '5px 16px',
            minWidth: 'max-content',
            color: 'inherit',
            pointerEvents: 'auto',
            '&.MuiIconButton-root': {
              padding: 3
            }
          }
        },
        icon: {
          '& .MuiSvgIcon-root': {
            fontSize: 22
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 500,
          padding: '5px 8px',
          textAlign: 'center',
          backgroundColor: '#616161',
          color: '#FFFFFF',
          lineHeight: '137%',
          fontSize: 12,
          borderRadius: 8,
          whiteSpace: 'pre-line',
          '& MuiTooltip-arrow': {
            color: '#616161'
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#F8F8F8'
        },
        track: {
          backgroundColor: '#9E9E9E',
          opacity: 1
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 8,
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#212121'
            }
          },
          '& .MuiInputLabel-root': {
            '&.Mui-focused': {
              color: '#212121'
            }
          },
          textarea: {
            scrollbarWidth: 'thin',
            scrollbarColor: '#BDBDBD #f8f8f8'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#212121'
            }
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#F2F2F2'
        }
      }
    }
  }
});
