import { IPublicationsViewModel } from 'types/reportAbstractsChunk';

export const publicationsViewController = ({
  title,
  citationsCount,
  journal,
  pubType,
  pmid,
  abstractText,
  year,
  authors
}: IPublicationsViewModel) => {
  return {
    pm_id: pmid,
    year,
    authors,
    title,
    abstract_text: abstractText,
    jrnl_title: journal,

    pub_type: pubType,
    web_address: `http://www.ncbi.nlm.nih.gov/pubmed/${pmid}`,
    citations_total: citationsCount
  };
};
