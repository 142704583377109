import React, { FC, Suspense, lazy, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { store } from 'contexts/PageContextStore';
import PageSkeleton from 'components/PageSkeleton';

const UserGuide = lazy(() => import('./components/UserGuide'));
const CreatePaperView = lazy(() => import('./components/CreatePaperView'));
const Editor = lazy(() => import('./components/EditorView'));

const useStyles = makeStyles(() => ({
  editorPanelWrapper: {
    position: 'relative',
    width: '52%',
    height: '100%',
    padding: '0 8px',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    scrollbarGutter: 'stable',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}));

const ActivedComponents = [UserGuide, CreatePaperView, Editor];

interface EditorPanelProps {}

const EditorPanel: FC<EditorPanelProps> = () => {
  const classes = useStyles();

  const {
    storeState: { activedEditorPanelStep = 1, isPaperLoading = true } = {}
  } = useContext(store);

  const ActivedComponent = ActivedComponents[activedEditorPanelStep];

  return (
    <div className={classes.editorPanelWrapper}>
      <Suspense>
        {isPaperLoading ? <PageSkeleton /> : <ActivedComponent />}
      </Suspense>
    </div>
  );
};

export default EditorPanel;
