import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Citations from '../Citations';
import { IBibliographyPublicationItemProps } from 'types/reportAbstractsChunk';
import { chunksAuthorFormatter } from 'utils/chunksAuthorFormatter';
import classNames from 'classnames';
import { store } from 'contexts/PageContextStore';

const useStyles = makeStyles((theme) => ({
  bibliographyPublicationItem_wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    '&.isNew': {
      backgroundColor: '#c4e9d5',
      WebkitAnimation: 'bgAnimation 3s forwards',
      animation: 'bgAnimation 3s forwards'
    }
  },
  bibliographyPublicationItem_author: {
    color: '#666666',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '145%',
    letterSpacing: '0.15px'
  },
  bibliographyPublicationItem_title: {
    margin: '4px 0',
    color: '#212121',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '145%'
  },
  bibliographyPublicationItem_pmid: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '145%',
    letterSpacing: '0.15px',
    color: '#006F35',
    cursor: 'pointer'
  },
  bibliographyPublicationItem_limited: {
    filter: 'blur(4px)'
  },
  bibliographyPublicationItem_description_more: {
    position: 'absolute',
    display: 'none',
    width: 72,
    height: 19,
    right: 0,
    bottom: 1,
    background: '#fff',
    color: theme.palette.primary.main,
    fontSize: 14,
    lineHeight: '145%;',
    letterSpacing: '0.15px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline;'
    }
  },
  bibliographyPublicationItem_description: {
    position: 'relative',
    color: '#212121',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '0.15px',
    marginBottom: 8,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    '&.showMore': {
      display: 'block'
    },
    '&:hover': {
      '& $bibliographyPublicationItem_description_more': {
        display: 'block'
      }
    }
  }
}));

export default function BibliographyPublicationItem({
  title,
  citationsCount,
  journal,
  pubType,
  pmid,
  abstractText,
  abstractChunks = [],
  year,
  authors,
  isNew = false
}: IBibliographyPublicationItemProps) {
  const classes = useStyles();

  const {
    storeState: {
      paper: { publication_settings = {} } = {},
      userInfo: { plan = {}, is_internal = false } = {}
    }
  } = useContext(store);

  const isLimited =
    !is_internal && ['canceled', 'past_due'].includes(plan?.status);

  const data = {
    pm_id: pmid,
    year,
    title,
    abstract_text: abstractText,
    jrnl_title: journal,
    authors,
    pub_type: pubType,
    web_address: `http://www.ncbi.nlm.nih.gov/pubmed/${pmid}`,
    citations_total: citationsCount
  };
  const [showMore, setShowMore] = useState(false);

  const defaultPubType = publication_settings.article_types.length
    ? pubType.filter((type) =>
        publication_settings.article_types.includes(type)
      )?.[0] || pubType[0]
    : pubType[0];

  const CHUNK_ITEM_LIMIT = 120;

  return (
    <div
      className={classNames(
        classes.bibliographyPublicationItem_wrapper,
        isNew && 'isNew'
      )}
      id={`bibliographyPublicationItem_${pmid}`}
    >
      <span className={classes.bibliographyPublicationItem_author}>
        {chunksAuthorFormatter({ authors, pub_year: year })} | {journal} |{' '}
        {defaultPubType}
      </span>
      <span className={classes.bibliographyPublicationItem_title}>{title}</span>
      {abstractChunks?.map((chunk: any, index) => (
        <span
          className={classNames(
            classes.bibliographyPublicationItem_description,
            isLimited && classes.bibliographyPublicationItem_limited,
            showMore && 'showMore'
          )}
          key={index}
        >
          "{chunk}"
          {!showMore && chunk?.length > CHUNK_ITEM_LIMIT && (
            <span
              className={classNames(
                classes.bibliographyPublicationItem_description_more
              )}
              onClick={() => setShowMore(!showMore)}
            >
              Show more
            </span>
          )}
        </span>
      ))}
      <Citations {...data} enableTypeFilter={true} />
    </div>
  );
}
