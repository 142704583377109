import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Citations from '../Citations';
import { publicationsViewController } from 'utils/publicationsViewController';
import { ITextEvidencesChunkItemProps } from 'types/reportAbstractsChunk';
import { chunksAuthorFormatter } from 'utils/chunksAuthorFormatter';
import classNames from 'classnames';
import { Tooltip, Typography } from '@mui/material';
import { store } from 'contexts/PageContextStore';
import BibliographyCustomItem from '../BibliographyCustomItem';

const useStyles = makeStyles((theme) => ({
  textEvidencesChunkItem_wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1)
  },
  textEvidencesChunkItem_author: {
    color: '#666666',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '145%',
    letterSpacing: '0.15px'
  },
  textEvidencesChunkItem_title: {
    margin: '4px 0',
    color: '#212121',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '145%'
  },
  textEvidencesChunkItem_description_more: {
    position: 'absolute',
    display: 'none',
    width: 72,
    height: 19,
    right: 0,
    bottom: 1,
    background: '#fff',
    color: theme.palette.primary.main,
    fontSize: 14,
    lineHeight: '145%;',
    letterSpacing: '0.15px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline;'
    }
  },
  textEvidenceslimited: {
    filter: 'blur(4px)',
    userSelect: 'none'
  },
  textEvidencesChunkItem_description: {
    position: 'relative',
    display: 'block',
    marginBottom: 4,
    overflow: 'hidden',
    '&.enableShowMore': {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      '&.hiddenItem': {
        display: 'none'
      },
      '&.showMore': {
        display: 'block'
      },
      '&:hover': {
        '& $textEvidencesChunkItem_description_more': {
          display: 'block'
        }
      }
    },

    '&.showMore': {
      display: 'block'
    }
  }
}));

const limitedMockText = `Protein arginine methyltransferases (PRMTs) catalyze the methylation of arginine residues on both histones and non-histone proteins. PRMT5, a member of the PRMT family, is overexpressed in a wide variety of cancers and its activity is associated with cell transformation.`;

export default function TextEvidencesChunkItem({
  title,
  chunks,
  citationsCount,
  journal,
  pubType,
  pmid,
  abstractText,
  year,
  authors,
  showWithLink,
  fileName = '',
  url = '',
  isCustomLink = false
}: ITextEvidencesChunkItemProps) {
  const classes = useStyles();

  const data = publicationsViewController({
    title,
    citationsCount,
    journal,
    pubType,
    pmid,
    abstractText,
    year,
    authors
  });

  const {
    storeState: { userInfo: { plan = {}, is_internal = false } = {} }
  } = useContext(store);

  const isLimited =
    !is_internal && ['canceled', 'past_due'].includes(plan?.status);

  const [showMore, setShowMore] = useState(false);

  const CHUNK_ITEM_LIMIT = 140;

  return (
    <div className={classes.textEvidencesChunkItem_wrapper}>
      <BibliographyCustomItem
        fileName={fileName}
        url={url}
        sx={{ fontWeight: 500, mb: 0.5 }}
      />
      {!isCustomLink && (
        <>
          <span className={classes.textEvidencesChunkItem_author}>
            {chunksAuthorFormatter({ authors, pub_year: year })} | {journal} |{' '}
            {pubType[0]}
          </span>
          <span className={classes.textEvidencesChunkItem_title}>{title}</span>
        </>
      )}
      <Typography
        variant="body2"
        lineHeight="145%"
        classes={{
          root: classNames(isLimited && classes.textEvidenceslimited)
        }}
      >
        {Object.values(chunks).map((chunk: any, index) => (
          <span
            className={classNames(
              classes.textEvidencesChunkItem_description,
              !showWithLink && index && 'hiddenItem',
              !showWithLink && 'enableShowMore',
              showMore && 'showMore'
            )}
            key={index}
          >
            {isLimited ? (
              <Tooltip
                title="Text unavailable due to expired plan"
                followCursor
              >
                <p>{limitedMockText}</p>
              </Tooltip>
            ) : (
              <>
                "{chunk}"
                {!showMore &&
                  (chunk?.length > CHUNK_ITEM_LIMIT || chunks.length > 1) && (
                    <span
                      className={classNames(
                        classes.textEvidencesChunkItem_description_more
                      )}
                      onClick={() => setShowMore(!showMore)}
                    >
                      Show more
                    </span>
                  )}
              </>
            )}
          </span>
        ))}
      </Typography>
      {!isCustomLink && <Citations {...data} enableTypeFilter={showWithLink} />}
    </div>
  );
}
