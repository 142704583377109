import React, { FC, memo, useState } from 'react';
import { Stack, Typography, TextField, Button } from '@mui/material';
import { createSuggestions } from 'services/templates';
import { debounce } from 'lodash';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      padding: '8px 1px 8px 16px'
    },
    '& textarea': {
      fontSize: 14,
      lineHeight: '145%',
      letterSpacing: 0.15
    },
    '& fieldset': {
      borderColor: '#C4C4C4'
    },
    '& ::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 1
    }
  },
  sendButton: {
    fontSize: 12,
    fontWeight: 700,
    width: 52,
    height: 24,
    minWidth: 52,
    textTransform: 'none',
    borderRadius: 8,
    lineHeight: '137%'
  }
}));

interface SuggestedSourceProps {
  isReadOnly?: boolean;
}

const SuggestedSource: FC<SuggestedSourceProps> = ({ isReadOnly = false }) => {
  const classes = useStyles();
  const [isSending, setIsSending] = useState(false);
  const [description, setDescription] = useState<string>('');

  const handleSend = async () => {
    setIsSending(true);
    await createSuggestions({
      suggest_type: 'source',
      description
    });
    setIsSending(false);
  };

  return (
    <Stack
      gap={1}
      bgcolor="#F8F8F8"
      borderRadius={4}
      p="12px 16px"
      m="12px -16px 0"
    >
      <Typography variant="body2" lineHeight="145%">
        Would you prefer a specific source? Request it, and we’ll review it for
        possible addition.
      </Typography>
      <TextField
        multiline
        rows={3}
        variant="outlined"
        placeholder={`https://example.com\nhttps://another-source.com\n…`}
        value={description}
        fullWidth
        inputProps={{ 'data-testid': 'suggested-source' }}
        classes={{ root: classes.textField }}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Button
        data-testid="send-button"
        variant="outlined"
        size="small"
        disabled={isReadOnly || isSending || !description.trim()}
        className={classes.sendButton}
        onClick={debounce(handleSend, 800)}
      >
        Send
      </Button>
    </Stack>
  );
};
export default memo(SuggestedSource);
